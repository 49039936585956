import _ from 'lodash'
import moment from 'moment'

const monthToPeriod = (month, period) => {
	switch (period) {
		case 'year':
			return month.split('-')[0]
		case 'quarter':
			const [y, m] = month.split('-')
			return y + '-Q' + Math.ceil(parseInt(m) / 3)
		case 'month':
			return month
		default:
			console.error('Unhandled period')
			return month
	}
}

export function splitPeriod(startDatetime, endDatetime, periodType) {
	if (!(startDatetime && endDatetime && periodType)) throw new Error(`Missing arguments`)
	const subPeriods = []
	let date = moment(startDatetime).startOf(periodType)
	endDatetime = moment(endDatetime).endOf(periodType)
	while (date.isBefore(endDatetime)) {
		subPeriods.push(monthToPeriod(date.format('YYYY-MM'), periodType))
		date.add(1, periodType)
	}
	return subPeriods
}

export const aggregateData = (
	sortedData,
	periodType: 'month' | 'quarter' | 'year',
	period = null
) => {
	if ((!sortedData || !sortedData.length) && !period) return sortedData
	let periods = []
	if (period) {
		periods = splitPeriod(period[0], period[1], periodType)
	}

	const acc = {}
	;(sortedData || []).forEach(d => {
		const p = monthToPeriod(d.mese, periodType)
		if (!periods.includes(p)) periods.push(p)
		if (!acc[p])
			acc[p] = {
				corrispettivo: 0,
				energia: 0,
			}
		acc[p].corrispettivo += d.corrispettivo
		acc[p].energia += d.energia
	})
	return periods.sort().map(period => ({
		period,
		corrispettivo: acc[period] ? acc[period].corrispettivo : 0,
		energia: acc[period] ? acc[period].energia : 0,
	}))
}
