import { notification, Modal } from 'antd';

export function openNotificationWithIcon({ type = 'info', title, message }) {
	notification[type]({
		message: title,
		description: String(message),
	});
}

export function showDeleteConfirm({ onOk }) {
	showConfirm({
		content: `Sei sicuro di voler eliminare questo elemento?`,
		onOk,
	});
}

export function showUpdateConfirm({ onOk }) {
	showConfirm({
		content: `Sei sicuro di voler modificare questo elemento?`,
		onOk,
	});
}

export function showConfirm({ title = 'Sei sicuro?', content, onOk, okType = 'danger' }) {
	Modal.confirm({
		title,
		content,
		okText: 'Si',
		okType,
		cancelText: 'No',
		onOk,
	});
}
