export const primaryKey = 'id_linea'
export const defaultSortOn = 'linea_cod'

export const columns = {
	filter: [
		{
			key: 'linea_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
	],
	selection: [
		{
			key: 'linea_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'posa_linea',
			name: 'Posa Linea',
		},
		{
			key: 'mono_bi_trifase',
			name: 'Formazione',
		},
		{
			key: 'sigla_cavo',
			name: 'Sigla Cavo',
		},
	],
}
