import React, {useReducer, useContext, useMemo} from 'react'
import {Button, Modal, Table, Spin, notification, Input} from 'antd'
import _ from 'lodash'
import axios from 'axios'
import {defaultStateReducer, noop, dump} from '../utils'
import logoLighting from '../img/LIGHTING_RGB.png'
import {Upload} from 'antd'
import Icon from '@ant-design/icons'
import uuid from 'uuid'
import {Machine, assign} from 'xstate'
import {useMachine} from '@xstate/react'
import Text from 'antd/lib/typography/Text'
import styled from 'styled-components'

const API_BASE_URL = `/api/v2.0`

const machine = Machine({
	id: 'itemMachine',
	initial: 'listing',
	context: {
		data: null,
		currentItem: null,
		currentId: null,
	},
	states: {
		listing: {
			initial: 'loading',
			entry: assign(() => ({currentItem: null, currentId: null})),
			states: {
				loading: {
					invoke: {
						src: 'loadBuildingData',
						onDone: {
							target: '#itemMachine.listing.loaded',
							actions: assign((ctx, evt) => ({data: evt.data.data})),
						},
						onError: '#itemMachine.listing.error',
					},
				},
				loaded: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								VIEW: {
									target: '#itemMachine.viewing',
									actions: assign((ctx, evt) => ({currentId: evt.id})),
								},
								EDIT: {
									target: '#itemMachine.editing',
									actions: assign((ctx, evt) => ({currentId: evt.id})),
								},
								DELETE: {
									target: 'confirmDelete',
									actions: assign((ctx, evt) => ({currentId: evt.id})),
								},
							},
						},
						confirmDelete: {
							invoke: {
								src: 'confirmDelete',
								onDone: 'deleting',
								onError: 'idle',
							},
						},
						deleting: {
							invoke: {
								src: 'deleteIntervento',
								onDone: {
									target: '#itemMachine.listing.loading',
									actions: ['notifyDeleteSuccess', assign(ctx => ({currentId: null}))],
								},
								onError: {
									target: 'idle',
									actions: 'notifyDeleteError',
								},
							},
						},
					},
				},
				error: {},
			},
			on: {
				ADD: {
					target: '#itemMachine.adding',
					actions: assign(() => ({currentItem: {impianti: []}})),
				},
			},
		},
		viewing: {
			initial: 'loading',
			states: {
				loading: {
					invoke: {
						src: 'loadItem',
						onDone: {
							target: '#itemMachine.viewing.loaded',
							actions: assign((ctx, evt) => ({currentItem: evt.data.data})),
						},
						onError: {
							target: '#itemMachine.viewing.error',
							actions: assign((ctx, evt) => ({error: evt.error})),
						},
					},
				},
				loaded: {},
				error: {},
			},
			on: {
				EDIT: '#itemMachine.editing',
				BACK: '#itemMachine.listing',
				FILE_REMOVED: {actions: 'removeFile'},
				FILE_ADDED: assign((ctx, evt) => ({
					currentItem: {
						...ctx.currentItem,
						files: [...ctx.currentItem.files, evt.file],
					},
				})),
			},
		},
		editing: {
			initial: 'loading',
			states: {
				loading: {
					invoke: {
						src: 'loadItem',
						onDone: {
							target: '#itemMachine.editing.loaded',
							actions: assign((ctx, evt) => ({currentItem: evt.data.data})),
						},
						onError: {
							target: '#itemMachine.editing.error',
							actions: assign((ctx, evt) => ({error: evt.data})),
						},
					},
				},
				loaded: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								CHANGE: {actions: assign((ctx, evt) => ({currentItem: evt.data}))},
								SAVE: 'confirmingSave',
								FILE_REMOVED: {actions: 'removeFile'},
								FILE_ADDED: assign((ctx, evt) => ({
									currentItem: {
										...ctx.currentItem,
										files: [...ctx.currentItem.files, evt.file],
									},
								})),
							},
						},
						confirmingSave: {
							invoke: {
								src: 'confirmUpdate',
								onDone: 'saving',
								onError: 'idle',
							},
						},
						saving: {
							invoke: {
								src: 'updateIntervento',
								onDone: {target: '#itemMachine.viewing', actions: 'notifyUpdateSuccess'},
								onError: {
									target: 'idle',
									actions: 'notifyUpdateError',
								},
							},
						},
					},
				},
				error: {},
			},
			on: {
				BACK: '#itemMachine.listing',
				CANCEL: '#itemMachine.viewing',
			},
		},
		adding: {
			initial: 'idle',
			states: {
				idle: {
					on: {
						CHANGE: {actions: assign((ctx, evt) => ({currentItem: evt.data}))},
						SAVE: 'saving',
						BACK: '#itemMachine.listing',
						CANCEL: '#itemMachine.listing',
					},
				},
				saving: {
					invoke: {
						src: 'insertIntervento',
						onDone: {
							target: '#itemMachine.viewing',
							actions: ['notifyInsertSuccess', 'assignNewIdToContext'],
						},
						onError: {
							target: '#itemMachine.adding.idle',
							actions: 'notifyInsertError',
						},
					},
				},
			},
		},
	},
})

const StyledTable = styled.table`
	th {
		font-weight: 500;
		text-align: left;
	}
`

const calcData = data =>
	data.map(x => {
		let potenza_effettiva = null
		let potenza_totale = null
		let energia_consumata_kWh = null
		if (!_.isNil(x.potenza_nominale_W) && !_.isNil(x.rendimento)) {
			potenza_effettiva = x.potenza_nominale_W / x.rendimento
			if (!_.isNil(x.numero_apparecchi)) {
				potenza_totale = x.numero_apparecchi * potenza_effettiva

				if (!_.isNil(x.ore_funzionamento)) {
					energia_consumata_kWh = (x.ore_funzionamento * potenza_totale) / 1000
				}
			}
		}
		return {
			...x,
			potenza_effettiva,
			potenza_totale,
			energia_consumata_kWh,
		}
	})

const InterventoTable = ({data, editing = false, onChange = noop, onAdd = noop, onDelete = noop}) => {
	const columns = [
		{label: 'Locale', dataIndex: 'locale', type: 'text'},
		{label: 'Piano', dataIndex: 'piano', type: 'text'},
		{label: 'N° appar.', dataIndex: 'numero_apparecchi', type: 'number'},
		{label: 'Marca', dataIndex: 'marca', type: 'text'},
		{label: 'Modello', dataIndex: 'modello', type: 'text'},
		{label: 'Pot. nom. [W]', dataIndex: 'potenza_nominale_W', type: 'number'},
		{label: 'Rendimento', dataIndex: 'rendimento', type: 'number'},
		{label: 'Pot. eff.', dataIndex: 'potenza_effettiva', type: 'number', calculated: true},
		{label: 'Pot. tot.', dataIndex: 'potenza_totale', type: 'number', calculated: true},
		{label: 'Ore funz.', dataIndex: 'ore_funzionamento', type: 'number'},
		{
			label: 'Energia consumata [kWh]',
			dataIndex: 'energia_consumata_kWh',
			type: 'number',
			calculated: true,
		},
	]
	const prepared = useMemo(() => {
		const calulated = calcData(data)
		const tot = calulated.reduce((acc, next) => acc + (parseFloat(next.energia_consumata_kWh) || 0), 0)
		return {
			data: calulated,
			tot,
		}
	}, [data])
	return (
		<div
			style={{
				overflow: 'auto',
				border: '1px solid #eee',
			}}
		>
			{/* {dump(prepared)} */}
			<StyledTable style={{minWidth: '100%'}} cellPadding={5}>
				<thead>
					<tr style={{backgroundColor: '#fafafa'}}>
						{columns.map((col, idx) => (
							<th key={idx} style={{textAlign: idx > 5 ? 'right' : undefined}}>
								{col.label}
							</th>
						))}
						<th />
					</tr>
				</thead>
				<tbody>
					{prepared.data.map((row, rowIdx) => (
						<tr key={`${rowIdx}`}>
							{columns.map((col, idx) => {
								return (
									<td
										key={`${rowIdx}-${idx}`}
										style={{textAlign: idx > 5 ? 'right' : undefined}}
									>
										{col.calculated ? (
											(Number(row[col.dataIndex]) || 0).toFixed(2)
										) : editing ? (
											<input
												value={row[col.dataIndex] || ''}
												onChange={e =>
													onChange(row.id, col.dataIndex, e.target.value)
												}
												// type={col.type}
											/>
										) : col.type === 'number' ? (
											(Number(row[col.dataIndex]) || 0).toFixed(2)
										) : (
											row[col.dataIndex]
										)}
									</td>
								)
							})}
							{editing ? (
								<td>
									<div style={{width: 33}}>
										<Button
											size="small"
											icon="delete"
											type="danger"
											onClick={() => onDelete(row.id)}
										/>
									</div>
								</td>
							) : null}
						</tr>
					))}
					<tr>
						<td colSpan={columns.length - 1}></td>
						<td style={{textAlign: 'right'}}>
							<strong>Tot: {prepared.tot.toFixed(2)}</strong>
						</td>
						{editing ? (
							<td>
								<Button
									size="small"
									icon="plus-circle-o"
									type="primary"
									onClick={() => onAdd()}
								/>
							</td>
						) : null}
					</tr>
				</tbody>
			</StyledTable>
		</div>
	)
}

const FileHandler = ({edificioId, interventoId, stateOfDesign = false}) => {
	const {
		machine: [current],
	} = useContext(InterventiContext)

	const [state, setState] = useReducer(defaultStateReducer, {
		fileList: _.get(current.context, 'currentItem.files', [])
			.filter(file => file.state_of_fact_or_design === (stateOfDesign ? 1 : 0))
			.map(file => ({
				_id: file.id,
				uid: file.id,
				name: file.name,
				status: 'done',
				url: `${API_BASE_URL}/file-illuminazione-interni/${file.id}`,
			})),
	})

	const onChange = info => {
		console.log('FILE onChange', info.file, info.fileList)
		let fileList = [...info.fileList]
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = `${API_BASE_URL}/file-illuminazione-interni/${file.response.id}`
				file._id = file.response.id
			}
			return file
		})
		setState({fileList})
	}

	const props = {
		action: `${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni/${interventoId}/${
			stateOfDesign ? `state-of-design-files` : `state-of-fact-files`
		}`,
		onChange,
		multiple: false,
		onRemove: file => {
			console.log('try remove')
			axios
				.delete(`${API_BASE_URL}/file-illuminazione-interni/${file._id}`)
				.then(() => {
					notification.success({message: `File rimosso con successo`})
				})
				.catch(() => {
					notification.error({message: `Errore nella rimozione del file`})
					file.status = 'done'
					setState({fileList: state.fileList})
				})
		},
	}
	const disabledButtons = current.matches('adding')
	return (
		<div style={{marginBottom: 10}}>
			<Upload {...props} fileList={state.fileList}>
				<Button disabled={disabledButtons}>
					<Icon type="upload" /> Carica
				</Button>
			</Upload>
			{disabledButtons ? (
				<>
					{' '}
					<Text disabled style={{fontStyle: 'italic'}}>
						È necessario salvare l'intervento prima di poter caricare file
					</Text>
				</>
			) : null}
		</div>
	)
}

const InterventoIlluminazioneInterniView = ({
	edificioId,
	interventoId,
	data = {impianti: []},
	onChange = noop,
	editing,
}) => {
	const impianti_di_fatto = data.impianti.filter(d => d.stato_di_fatto_o_di_progetto === 0)
	const impianti_di_progetto = data.impianti.filter(d => d.stato_di_fatto_o_di_progetto === 1)
	const consumo_di_fatto = calcData(impianti_di_fatto).reduce(
		(acc, next) => acc + (parseFloat(next.energia_consumata_kWh) || 0),
		0
	)
	const consumo_di_progetto = calcData(impianti_di_progetto).reduce(
		(acc, next) => acc + (parseFloat(next.energia_consumata_kWh) || 0),
		0
	)
	const risparmio = (consumo_di_fatto - consumo_di_progetto) / consumo_di_fatto
	return (
		<div>
			<div style={{marginBottom: 15}}>
				{editing ? (
					<Input
						placeholder="Descrizione"
						onChange={e => onChange({...data, description: e.target.value})}
						value={_.get(data, 'description')}
					/>
				) : (
					<>
						<strong>Descrizione:</strong> {_.get(data, 'description')}
					</>
				)}
			</div>

			<h4>Stato di fatto</h4>
			<div>
				<FileHandler edificioId={edificioId} interventoId={interventoId} />
			</div>
			<InterventoTable
				onAdd={() => {
					onChange({
						...data,
						impianti: [
							...data.impianti,
							{
								id: uuid(),
								stato_di_fatto_o_di_progetto: 0,
							},
						],
					})
				}}
				onChange={(id, col, value) => {
					const idx = data.impianti.findIndex(x => x.id === id)
					if (idx >= 0) {
						onChange({
							...data,
							impianti: [
								...data.impianti.slice(0, idx),
								{...data.impianti[idx], [col]: value},
								...data.impianti.slice(idx + 1),
							],
						})
					}
				}}
				onDelete={id => {
					onChange({
						...data,
						impianti: data.impianti.filter(x => x.id !== id),
					})
				}}
				data={impianti_di_fatto}
				editing={editing}
			/>

			<br />
			<h4>Stato di progetto</h4>
			<div>
				<FileHandler edificioId={edificioId} interventoId={interventoId} stateOfDesign />
			</div>
			<InterventoTable
				onAdd={() =>
					onChange({
						...data,
						impianti: [
							...data.impianti,
							{
								id: uuid(),
								stato_di_fatto_o_di_progetto: 1,
							},
						],
					})
				}
				onChange={(id, col, value) => {
					const idx = data.impianti.findIndex(x => x.id === id)
					if (idx >= 0) {
						onChange({
							...data,
							impianti: [
								...data.impianti.slice(0, idx),
								{...data.impianti[idx], [col]: value},
								...data.impianti.slice(idx + 1),
							],
						})
					}
				}}
				onDelete={id => {
					onChange({
						...data,
						impianti: data.impianti.filter(x => x.id !== id),
					})
				}}
				data={impianti_di_progetto}
				editing={editing}
			/>
			<br />
			<div
				style={{
					textAlign: 'right',
					fontWeight: 'bolder',
				}}
			>
				{!isNaN(risparmio) ? `Risparmio: ${(100 * risparmio).toFixed(2)}%` : null}
			</div>
		</div>
	)
}

const InterventoIlluminazioneInterni = () => {
	const {
		machine: [current, send],
		edificioId,
	} = useContext(InterventiContext)
	return ['editing.loading', 'viewing.loading'].some(current.matches) ? (
		<Spin />
	) : current.matches('error') ? (
		<span>Si è verificato un errore: {current.context.error}</span>
	) : (
		<div>
			{['editing.loaded'].some(current.matches) ? (
				<div>
					<InterventoIlluminazioneInterniView
						key="editing-intervento"
						edificioId={edificioId}
						interventoId={current.context.currentId}
						editing={true}
						onCancel={() => send('CANCEL')}
						data={current.context.currentItem}
						onChange={data => send('CHANGE', {data})}
					/>
					<div style={{textAlign: 'right', marginTop: 10}}>
						<Button icon="left" onClick={() => send('CANCEL')}>
							Annulla
						</Button>
						<Button
							icon="save"
							style={{marginLeft: 8}}
							onClick={() => send('SAVE', {data: current.context.currentItem})}
						>
							Salva
						</Button>
					</div>
				</div>
			) : ['viewing.loaded'].some(current.matches) ? (
				<div>
					<InterventoIlluminazioneInterniView
						key="viewing-intervento"
						edificioId={edificioId}
						interventoId={current.context.currentId}
						editing={false}
						onCancel={() => send('BACK')}
						data={current.context.currentItem}
					/>
					<div style={{textAlign: 'right', marginTop: 10}}>
						<Button icon="left" onClick={() => send('BACK')}>
							Indietro
						</Button>
						<Button icon="edit" style={{marginLeft: 8}} onClick={() => send('EDIT')}>
							Modifica
						</Button>
						<a
							href={`/api/v2.0/edifici/${edificioId}/illuminazione-interni/${current.context.currentId}/export`}
							target="_blank"
						>
							<Button icon="download" style={{marginLeft: 8}}>
								Esporta
							</Button>
						</a>
					</div>
				</div>
			) : ['adding'].some(current.matches) ? (
				<div>
					<InterventoIlluminazioneInterniView
						key="adding-intervento"
						edificioId={edificioId}
						editing={true}
						onCancel={() => send('BACK')}
						data={current.context.currentItem}
						onChange={data => send('CHANGE', {data})}
					/>
					<div style={{textAlign: 'right', marginTop: 10}}>
						<Button icon="left" onClick={() => send('BACK')}>
							Annulla
						</Button>
						<Button
							icon="save"
							style={{marginLeft: 8}}
							onClick={() => send('SAVE', {data: current.context.currentItem})}
						>
							Salva
						</Button>
					</div>
				</div>
			) : null}
		</div>
	)
}

const InterventiList = () => {
	const {
		machine: [current, send],
	} = useContext(InterventiContext)
	return current.matches('loading') ? (
		<Spin />
	) : current.matches('error') ? (
		<span>Si è verificato un errore: {current.context.error}</span>
	) : (
		<>
			<Table
				pagination={false}
				dataSource={_.get(current.context, 'data.interventi')}
				rowKey="id"
				columns={[
					{
						key: 'description',
						dataIndex: 'description',
						title: 'Descrizone',
					},
					{
						key: 'actions',
						render: (_v, x) => (
							<div>
								<Button icon="eye" type="primary" onClick={() => send('VIEW', {id: x.id})} />
								<Button icon="edit" type="default" onClick={() => send('EDIT', {id: x.id})} />
								<Button
									icon="delete"
									type="danger"
									onClick={() => send('DELETE', {id: x.id})}
								/>
							</div>
						),
						width: 128,
					},
				]}
			/>
			<div style={{padding: 16, textAlign: 'right'}}>
				<Button icon="plus-circle-o" type="primary" onClick={() => send('ADD')} />
			</div>
		</>
	)
}

const InterventiContext = React.createContext()

const InterventiIlluminazioneInterniModalContent = ({edificioId}) => {
	const interventiMachine = useMachine(machine, {
		services: {
			loadBuildingData: (ctx, evt) =>
				axios.get(`${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni`),
			loadItem: (ctx, evt) =>
				axios.get(`${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni/${ctx.currentId}`),
			insertIntervento: ctx =>
				axios.post(`${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni`, ctx.currentItem),
			confirmUpdate: () =>
				new Promise((res, rej) => {
					Modal.confirm({
						title: `Sei sicuro?`,
						content: `Sicuro di voler aggiornare questo intervento?`,
						onOk: () => res(),
						onCancel: () => rej(),
					})
				}),
			updateIntervento: ctx =>
				axios.put(
					`${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni/${ctx.currentId}`,
					ctx.currentItem
				),
			confirmDelete: () =>
				new Promise((res, rej) => {
					Modal.confirm({
						title: `Sei sicuro?`,
						content: `Sicuro di voler eliminare questo intervento?`,
						onOk: () => res(),
						onCancel: () => rej(),
					})
				}),
			deleteIntervento: ctx =>
				axios.delete(`${API_BASE_URL}/edifici/${edificioId}/illuminazione-interni/${ctx.currentId}`),
		},
		actions: {
			removeFile: assign((ctx, evt) => {
				console.log('removing file', ctx, evt)
				return {
					currentItem: {
						...ctx.currentItem,
						files: ctx.currentItem.files.filter(id => id !== evt.uid),
					},
				}
			}),
			notifyInsertSuccess: () => notification.success({message: `Intervento inserito!`}),
			notifyInsertError: (ctx, evt) => {
				console.error(evt)
				notification.error({message: `Errore durante l'inserimento!`})
			},
			assignNewIdToContext: assign((ctx, evt) => ({currentId: evt.data.data.id})),
			notifyUpdateSuccess: () => notification.success({message: `Intervento aggiornato!`}),
			notifyUpdateError: (ctx, evt) => {
				console.error(evt)
				notification.error({message: `Errore nel salvataggio!`})
			},
			notifyDeleteSuccess: () => notification.success({message: `Intervento eliminato!`}),
			notifyDeleteError: (ctx, evt) => {
				console.error(evt)
				notification.error({message: `Errore durante l'eliminazione!`})
			},
		},
	})
	const [current] = interventiMachine
	return (
		<InterventiContext.Provider value={{edificioId, machine: interventiMachine}}>
			<div style={{marginBottom: 20}}>
				<img src={logoLighting} style={{width: '7cm'}} />
			</div>
			<div style={{marginBottom: 15}}>
				<strong>Edificio:</strong> {_.get(current.context, 'data.descrizione')}
			</div>
			{current.matches('listing') ? (
				<InterventiList />
			) : current.matches('viewing') || current.matches('editing') || current.matches('adding') ? (
				<InterventoIlluminazioneInterni />
			) : null}
		</InterventiContext.Provider>
	)
}

export const InterventiIlluminazioneInterniModal = ({edificioId, onCancel}) => {
	const visible = !_.isNil(edificioId)
	return (
		<Modal
			visible={visible}
			width="90%"
			title={`Interventi di illuminazione d'interni`}
			destroyOnClose={true}
			onCancel={onCancel}
			footer={null}
		>
			<InterventiIlluminazioneInterniModalContent edificioId={edificioId} />
		</Modal>
	)
}
