import { Style, Stroke, Fill } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import color from 'color';

const createPointStyle = ({ strokeColor, strokeWidth, fillColor, radius }) =>
	new Style({
		image: new CircleStyle({
			stroke: new Stroke({
				color: strokeColor,
				width: strokeWidth,
			}),
			fill: new Fill({
				color: fillColor,
			}),
			radius,
		}),
	});

const createLineStyle = ({ color, width }) =>
	new Style({
		stroke: new Stroke({
			color,
			width,
		}),
	});

const baseStyles = {
	mapSelection: {
		// strokeColor: '#f00',
		// fillColor: 'rgba(255,0,0,0.1)',
		point: createPointStyle({
			strokeColor: '#00f',
			strokeWidth: 2,
			fillColor: 'rgba(0, 0, 200, .3)',
			radius: 9,
		}),
		line: createLineStyle({
			color: 'red',
			width: 4,
		}),
	},
	externalSelection: {
		// strokeColor: '#f00',
		// fillColor: 'rgba(255,0,0,0.1)',
		point: createPointStyle({
			strokeColor: '#52c41a',
			strokeWidth: 3,
			fillColor: 'rgba(183, 235, 143, .6)',
			radius: 7,
		}),
		line: createLineStyle({
			color: color('lightgreen').string(),
			width: 8,
		}),
	},
	highlightedSelection: {
		point: createPointStyle({
			strokeColor: color('red').string(),
			strokeWidth: 3,
			fillColor: color('red')
				.alpha(0.4)
				.string(),
			radius: 7,
		}),
		line: createLineStyle({
			color: color('red').string(),
			width: 8,
		}),
	},
	modifyFeature: {
		point: createPointStyle({
			strokeColor: color('#e21e85').string(),
			strokeWidth: 3,
			fillColor: color('#e21e85')
				.alpha(0.4)
				.string(),
			radius: 6,
		}),
		line: createLineStyle({
			color: color('#e21e85').string(),
			width: 4,
		}),
	},
	modifyFeatureHover: {
		point: createPointStyle({
			strokeColor: color('#e21e85').string(),
			strokeWidth: 3,
			fillColor: color('#e21e85').string(),
			radius: 6,
		}),
		line: createLineStyle({
			color: color('#e21e85')
				.darken(0.5)
				.string(),
			width: 4,
		}),
	},
};

const isLineType = type => type === 'MultiLineString' || type === 'LineString';

export const mapSelectionStyle = feat =>
	isLineType(feat.getGeometry().getType())
		? baseStyles.mapSelection.line
		: baseStyles.mapSelection.point;

export const externalSelectionStyle = feat =>
	isLineType(feat.getGeometry().getType())
		? baseStyles.externalSelection.line
		: baseStyles.externalSelection.point;

export const highlightedSelectionStyle = feat =>
	isLineType(feat.getGeometry().getType())
		? baseStyles.highlightedSelection.line
		: baseStyles.highlightedSelection.point;

export const modifyFeatureStyle = feat =>
	isLineType(feat.getGeometry().getType())
		? baseStyles.modifyFeature.line
		: baseStyles.modifyFeature.point;

export const modifyFeatureHoverStyle = feat =>
	isLineType(feat.getGeometry().getType())
		? baseStyles.modifyFeatureHover.line
		: baseStyles.modifyFeatureHover.point;
