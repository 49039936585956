import {loadFeatures} from './data'
import {TIPOLOGIE} from '../configs/grids/impianti'

let cache = null

const tipologieMap = TIPOLOGIE.reduce((acc, {label, value}) => ({...acc, [value]: {codice: value, tipo: label}}), {})

const load = async () => {
	const [impianti, centraline] = await Promise.all([
		loadFeatures('impianti'),
		loadFeatures('centraline'),
	])
	return [...impianti, ...centraline]
		.map(feat => feat.properties)
		.map(item => ({...item, tipo_impianto: tipologieMap[item.imp_tipo]}))
}

export const get = async () => {
	return cache
		? cache
		: load().then(data => {
				cache = data
				return data
		  })
}
