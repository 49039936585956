import React, { PureComponent } from 'react'
import * as d3 from 'd3'

export const ConsumiGraphRenderer = ({f1=0, f2=0, f3=0, f0=0, tot=0, min=0, max=0, style}) => {
	const x = d3.scaleLinear().range([0,100]).domain([min, max])
	return (
		<div style={style}>
			<svg height="100%" width="100%">
				{/* style={{}}> */}
				<rect data-id="tot" height="100%" fill="#FC006D" width={tot ? x(tot) + '%' : 0} />
				<rect data-id="f1" height="70%" y="30%" fill="#2749FA" width={f1 ? x(f1) + '%' : 0} />
				<rect data-id="f2" height="70%" y="30%" fill="#82CA3F" x={x(f1) + '%'} width={f2 ? x(f2) + '%' : 0} />
				<rect data-id="f3" height="70%" y="30%" fill="#FEDD23" x={x(f1) + x(f2) + '%'} width={f3 ? x(f3) + '%' : 0} />
				<rect data-id="f0" height="70%" y="30%" fill="#000000" x={x(f1) + x(f2) + x(f3) + '%'} width={f0 ? x(f0) + '%' : 0} />
			</svg>
		</div>
	)
}

export const ImportiGraphRenderer = ({importo=0, min=0, max=0, style}) => {
	const x = d3.scaleLinear().range([0,100]).domain([min, max])
	return (
		<div style={style}>
			<svg height="100%" width="100%">
				<rect data-id="tot" height="100%" fill="#1BA5F4" width={importo ? x(importo) + '%' : 0} />
			</svg>
		</div>
	)
}

export class ScaleRenderer extends PureComponent {
		componentDidMount() {
			this.svg = d3.select(this.el)

			this.xAxisCon = this.svg.append('g').attr('class','x axis')

			this.draw()
		}
		componentDidUpdate() {
			this.draw()
		}
		draw() {
	  	const {min=0, max=0} = this.props
	  	const domain = [min, max]
			if (!domain) return;

			const w = this.el.clientWidth
			const h = this.el.clientHeight
			const width = w - 1
			const height = h

			const { svg, xAxisCon } = this

			svg.attr('width', w).attr('height', h);

			const xScale = d3.scaleLinear()
			xScale.range([0, width]).domain(domain);

			const xAxisFn = d3.axisTop(xScale)
			const minDistance = 20

			xAxisCon.attr('transform', 'translate(0,' + (height - 1) + ')')
				.call(xAxisFn.ticks(Math.floor(width / minDistance)).tickFormat(d3.format(".2s")));
				// .call(xAxisFn.tickFormat(d3.format("s")));
			xAxisCon.selectAll('line').style('stroke', '#B3B3B3');
			xAxisCon.selectAll('path').style('stroke', '#B3B3B3');

			const texts = xAxisCon.selectAll("text").style('fill', '#878d92');
			texts.style("text-anchor", "start").attr("transform", "rotate(-65) translate(6,9)")
			const textNodes = texts.nodes()
			// d3.select(textNodes[0]).style('visibility', 'hidden')
			// d3.select(textNodes[textNodes.length - 1]).style('visibility', 'hidden')

			d3.select(textNodes[0]).attr("transform", "rotate(-65) translate(7,13)")
			// d3.select(textNodes[textNodes.length - 1]).attr("transform", "rotate(-65) translate(5,5)")

			// d3.select(textNodes[textNodes.length - 1]).attr("transform", "rotate(-65) translate(5,5)")
			// d3.select(textNodes[textNodes.length - 1]).attr("transform", "rotate(-65) translate(0,0)")
		}
		render() {
			return <svg style={{ fontWeight: 'normal', fontSize: '10px', ...this.props.style}} ref={el => this.el = el}></svg>
		}
}
