let _cache = {};

const create = (key, loadFunction) => {
	_cache[key] = { loadFunction, maps: {} };
};

const get = key => _cache[key];

const destroy = key => (_cache[key] = undefined);

const load = async key => {
	_cache[key].maps = {};
	const promise = _cache[key].loadFunction();
	_cache[key].promise = promise;
	return promise;
};

const read = async key => {
	return _cache[key].promise || load(key);
};

const readMap = async (featureType, key) => {
	if (!_cache[featureType]) {
		return load(featureType).then(() => readMap(featureType, key));
	} else if (!_cache[featureType].maps[key]) {
		const features = await read(featureType);
		_cache[featureType].maps[key] = features.reduce((acc, next) => {
			acc[next.properties[key]] = next;
			return acc;
		}, {});
	}
	return _cache[featureType].maps[key];
};

const reload = key => {
	clear(key);
	load(key);
};

const clear = key => (_cache[key].promise = undefined);

export default {
	create,
	get,
	destroy,
	load,
	read,
	readMap,
	reload,
	clear,
};
