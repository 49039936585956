import React from 'react';
import { Col, Row, CentralinaSelect, createInsertForm } from '../utils';

export default createInsertForm({
	featureType: 'quadri',
	form: ({ formItem, bind }) => (
		<>
			<Row>
				<Col>{formItem({ label: 'Codice', name: 'centr_sez_cod', required: true })}</Col>
				<Col>
					{formItem({
						label: 'Centralina',
						name: 'id_imp',
						required: true,
						Component: CentralinaSelect,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Descrizione', name: 'descrizione', required: true })}</Col>
			</Row>
		</>
	),
});
