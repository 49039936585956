import React, {useEffect, useMemo} from 'react'
import {assign, Machine} from 'xstate'
import {Input, Space, Spin, Table, Collapse, Tag} from 'antd'
import {useMachine} from '@xstate/react'
import Button from 'antd/es/button'
import Axios from 'axios'
import _ from 'lodash'

import {
	Aggregation,
	AGGREGAZIONI,
	ENERGIA_RILEVANTE_PER_CONVENZIONE,
	FormItem,
	PANEL_HEIGHT,
	Period,
	TIPI_CONVENZIONI,
} from '../common'
import {ContractDetailsStateless} from './detail'
import {exportXLSX} from '../../../../utils/exports'
import {PADDING} from '../../../../styles'
import Box from 'ui-box'
import {aggregateData} from '../utils'

type PodsResponse = {
	pod: string
	tipoImpianto: 'fotovoltaico' | 'eolico' | 'idroelettrico'
	contratti: {
		codice: string
		convenzione: typeof TIPI_CONVENZIONI[number]
		tipologia_SSA: null | string
	}[]
}[]

type PodDataResponse = {
	codice: string
	convenzione: typeof TIPI_CONVENZIONI[number]
	tipologia_SSA: null | string
	altriPod: string[]
	energiaDiRiferimento: string
	corrispettivi: {
		mese: string
		corrispettivo: number
		energia: number
	}[]
}[]

async function loadPods(): Promise<PodsResponse> {
	return new Promise(res => {
		Axios.get('/api/produzione-energetica/pod').then(({data}) => {
			res(data)
		})
	})
}

const loadPodData = async ({period, selectedPod}): Promise<PodDataResponse> =>
	Axios.get(
		`/api/produzione-energetica/pod/${selectedPod}/contratti?da=${period[0].format(
			'YYYY-MM'
		)}&a=${period[1].format('YYYY-MM')}`
	).then(({data}) => data)

const analysisByPodMachine = Machine<any>(
	{
		id: 'analysisByPod',
		context: {
			period: null,
			pod_search: null,
			selectedPod: null,
			selectedPodData: null as PodDataResponse,
			pods: [] as PodsResponse,
		},
		initial: 'loading',
		states: {
			loading: {
				invoke: {
					src: 'loadPods',
					onDone: {
						target: 'loaded',
						actions: ['assignPodsToCtx'],
					},
					onError: {actions: ['displayLoadError']},
				},
			},
			loaded: {
				always: {
					target: 'podSelected',
					cond: ({selectedPod}) => !!selectedPod,
				},
				on: {
					SEARCH_CHANGE: {
						actions: assign((_ctx, evt: {search: string}) => ({
							pod_search: evt.search,
						})),
					},
					SELECT_POD: {
						target: 'podSelected',
						actions: assign((_ctx, evt: {pod: string}) => ({selectedPod: evt.pod})),
						cond: (_ctx, evt) => !!evt.pod,
					},
				},
			},
			podSelected: {
				states: {
					loadingPodData: {
						entry: assign(() => ({selectedPodData: null})),
						invoke: {
							src: 'loadPodData',
							onDone: {
								target: 'podDataLoaded',
								actions: ['assignPodData'],
							},
							onError: 'podDataLoadError',
						},
					},
					podDataLoaded: {},
					podDataLoadError: {},
				},
				initial: 'loadingPodData',
				on: {
					SELECT_POD: {
						target: '.',
						actions: assign((_ctx, evt: {pod: string}) => ({selectedPod: evt.pod})),
						cond: (_ctx, evt) => !!evt.pod,
					},
					PERIOD_CHANGED: {
						target: '.',
						actions: assign((_ctx, evt: any) => ({period: evt.period})),
					},
					BACK: {
						target: 'loaded',
						actions: assign((_ctx, _evt) => ({selectedPod: null})),
					},
				},
			},
		},
	},
	{
		services: {
			loadPods,
			loadPodData,
		},
		actions: {
			displayLoadError: () => null,
			assignPodsToCtx: assign((_ctx, evt) => ({pods: (evt as any).data})),
			assignPodData: assign((_ctx, evt) => ({selectedPodData: (evt as any).data})),
		},
	}
)

const AnalysisByPodUI = ({
	aggregation,
	period,
	initialState = {},
}: {
	aggregation?: Aggregation
	period?: Period
	initialState: {selectedPod?: string}
}) => {
	const [state, send] = useMachine(analysisByPodMachine, {
		context: {selectedPod: initialState.selectedPod, period},
	})
	useEffect(() => {
		send('PERIOD_CHANGED', {period})
	}, [period])
	const aggregatedPodData = useMemo(() => {
		return state.context.selectedPodData
			? _.orderBy(state.context.selectedPodData, [
					contratto => TIPI_CONVENZIONI.indexOf(contratto.convenzione),
			  ]).map(contratto => ({
					...contratto,
					corrispettivi: aggregateData(
						contratto.corrispettivi,
						aggregation,
						state.context.period
					),
			  }))
			: null
	}, [state.context.selectedPodData, aggregation])
	function renderSelectedPlant() {
		const plant = state.context.pods.find(({pod}) => pod === state.context.selectedPod)
		return (
			<Space direction="vertical" style={{width: '100%'}}>
				<Button size="small" onClick={() => send('BACK')}>
					Torna all'elenco impianti
				</Button>
				<div>
					Impianto selezionato:{' '}
					<strong>
						{plant.pod} - {plant.tipoImpianto}
					</strong>
				</div>
				<Spin spinning={!state.matches('podSelected.podDataLoaded')}>
					{state.context.selectedPodData ? (
						<>
							<Collapse
								className="contratti-collapse"
								defaultActiveKey={aggregatedPodData.map(c => c.codice)}
							>
								{aggregatedPodData.map(contratto => (
									<Collapse.Panel
										key={contratto.codice}
										header={
											<strong>
												{contratto.convenzione} - {contratto.codice}
											</strong>
										}
									>
										<ContractDetailsStateless
											aggregatedData={contratto.corrispettivi}
											aggregation={aggregation}
											convention={contratto.convenzione}
											otherPodsInvolved={contratto.altriPod}
											onViewOtherPod={pod => send('SELECT_POD', {pod})}
										/>
									</Collapse.Panel>
								))}
							</Collapse>
						</>
					) : null}
				</Spin>

				<Box display="flex" justifyContent="flex-end">
					<Button
						style={{marginLeft: PADDING}}
						onClick={() => {
							exportXLSX({
								title: 'Produzione per POD',
								sheets: aggregatedPodData.map(contratto => ({
									title: `${contratto.convenzione} - ${contratto.codice}`,
									data: contratto.corrispettivi.map(d => ({
										[AGGREGAZIONI[aggregation]]: d.period,
										Corrispettivo: d.corrispettivo,
										[ENERGIA_RILEVANTE_PER_CONVENZIONE[contratto.convenzione]]:
											d.energia,
									})),
								})),
								filename: 'export.xlsx',
							})
						}}
					>
						Esporta Dati
					</Button>
				</Box>
			</Space>
		)
	}
	return (
		<>
			{['loading', 'loaded'].some(state.matches) && (
				<Spin spinning={state.matches('loading')}>
					<div style={{display: 'flex'}}>
						<FormItem label="Filtra" labelStyle={{width: 'auto'}}>
							<Input
								value={state.context.pod_search}
								onChange={e => send('SEARCH_CHANGE', {search: e.target.value})}
								placeholder="POD"
								allowClear
							/>
						</FormItem>
					</div>
					<div style={{display: 'flex', height: PANEL_HEIGHT - 24 * 2}}>
						<Table
							rowKey="pod"
							style={{width: '100%'}}
							scroll={{y: PANEL_HEIGHT - 24 * 2 - 39}}
							size="small"
							bordered
							pagination={false}
							columns={[
								{
									title: 'POD',
									dataIndex: 'pod',
									width: 300,
								},
								{
									title: 'Tipo di impianto',
									dataIndex: 'tipoImpianto',
									width: 300,
								},
								{
									title: 'Convenzioni',
									dataIndex: 'contratti',
									render: contratti => (
										<span>
											{_.orderBy(contratti, [
												contratto =>
													TIPI_CONVENZIONI.indexOf(contratto.convenzione),
											]).map(contratto => (
												<Tag key={contratto.codice}>
													{contratto.convenzione} ({contratto.codice})
												</Tag>
											))}
										</span>
									),
								},
								{
									title: '',
									key: 'pod',
									render: pod => (
										<Button
											size="small"
											onClick={() => send('SELECT_POD', {pod: pod.pod})}
										>
											Apri
										</Button>
									),
									width: 58,
								},
							]}
							dataSource={state.context.pods.filter(pod =>
								state.context.pod_search
									? new RegExp(state.context.pod_search, 'i').test(pod.pod)
									: true
							)}
						/>
					</div>
				</Spin>
			)}
			{state.matches('podSelected') ? renderSelectedPlant() : null}
		</>
	)
}

export default AnalysisByPodUI
