import React, {useMemo} from 'react'
import {Select} from 'antd'
import _ from 'lodash'

const TipiImpiantiSelect = ({
	impianti = [],
	value,
	onChange,
	style,
	allowSelectAll = true,
	...props
}) => {
	const tipiImpianti = useMemo(() => {
		const tipi = _.uniqBy(impianti.map(imp => imp.tipo_impianto).filter(Boolean), 'codice').map(
			({tipo, codice}) => ({key: codice, value: codice, label: tipo})
		)
		if (allowSelectAll) tipi.unshift({label: 'Tutti', key: '*', value: null})
		return tipi
	}, [impianti])
	return (
		<Select value={value} onChange={onChange} style={{...style}} {...props}>
			{tipiImpianti.map(({key, value, label}) => (
				<Select.Option key={key} value={value}>
					{label}
				</Select.Option>
			))}
		</Select>
	)
}

export default TipiImpiantiSelect
