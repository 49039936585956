import React from 'react'
import {Input} from 'antd'
import {Col, Row} from '../utils'

export default ({formItem}) => ({
	edificio: (
		<>
			<Row>
				<Col span={24}>
					{formItem({
						name: 'edif_descr',
						label: 'Denominazione Ufficiale',
						required: true,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'indirizzo', label: 'Indirizzo', required: true})}</Col>
				<Col>{formItem({name: 'anno_costr', label: 'Anno Costruzione'})}</Col>
			</Row>
			<Row>
				<Col span={24}>
					{formItem({
						name: 'altra_denominazione',
						label: 'Altra Denominazione',
						Component: Input.TextArea,
					})}
				</Col>
			</Row>
		</>
	),
	dati_tecnici: (
		<>
			<Row>
				<Col>{formItem({name: 'appartenenza', label: 'Appartenenza'})}</Col>
				<Col>{formItem({name: 'classe_energ', label: 'Classe Energetica'})}</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'nr_utenti', label: 'N° Utenti', type: 'number'})}</Col>
				<Col>
					{formItem({
						name: 'utilizzo_h_anno',
						label: 'Utilizzo Ore Anno',
						type: 'number',
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'sup_mq', label: 'Superficie (mq)', type: 'number'})}</Col>
				<Col>{formItem({name: 'vol_mc', label: 'Volume (mc)', type: 'number'})}</Col>
			</Row>
			<Row>
				<Col span={24}>
					{formItem({name: 'note', label: 'Note', Component: Input.TextArea})}
				</Col>
			</Row>
		</>
	),
})
