import { CellEditor, CellRenderer } from './cell-renderers';

export const primaryKey = 'id_q_sez';
export const defaultSortOn = 'centr_sez_cod';

export const columnGroups = [
	{
		name: 'quadro_sezionamento',
		label: 'Quadro Sezionamento',
		keys: ['centr_sez_cod', 'descrizione', 'corr_nom_amp', 'protez_tipo', 'toponimo'],
	},
	{
		name: 'dati_supplementari',
		label: 'Dati Supplementari',
		keys: ['centr_sez_cod', 'id_imp', 'data_attvaz', 'note'],
	},
];

export const columns = {
	'*': [
		{
			key: 'centr_sez_cod',
			name: 'Codice',
			width: 65,
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
			width: 70,
		},
		{
			key: 'descrizione',
			name: 'Descrizione',
		},
		{
			key: 'corr_nom_amp',
			name: 'Corrente Nominale A',
		},
		{
			key: 'protez_tipo',
			name: 'Protezioni',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'data_attvaz',
			name: 'Data Attivazione',
		},
		{
			key: 'note',
			name: 'Note',
		},
	],
	insert: [
		{
			key: 'centr_sez_cod',
			name: 'Codice',
			width: 65,
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
			width: 70,
		},
		{
			key: 'descrizione',
			name: 'Descrizione',
			editable: true,
		},
	],
	'complete-inserts': [
		{
			key: 'centr_sez_cod',
			name: 'Codice',
			width: 65,
		},
		{
			key: 'id_imp',
			name: 'Centralina',
			formatter: CellRenderer.Centralina,
			editableCellTemplate: CellEditor.Centralina,
			editable: true,
		},
		{
			key: 'descrizione',
			name: 'Descrizione',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'corr_nom_amp',
			name: 'Corrente Nominale A',
			editable: true,
			editableCellTemplate: CellEditor.Number,
		},
		{
			key: 'protez_tipo',
			name: 'Protezioni',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'data_attvaz',
			name: 'Data Attivazione',
			editable: true,
			editableCellTemplate: CellEditor.Date,
		},
		{
			key: 'note',
			name: 'Note',
			editable: true,
			editableCellTemplate: CellEditor.Text,
		},
	],
};
