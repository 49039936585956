import React from 'react';
import { Col, Row, CentralinaSelect, createInsertForm } from '../utils';

export default createInsertForm({
	featureType: 'linee',
	form: ({ formItem, bind }) => (
		<>
			<Row>
				<Col>{formItem({ label: 'Codice', name: 'linea_cod', required: true })}</Col>
				<Col>
					{formItem({
						label: 'Centralina',
						name: 'id_imp',
						required: true,
						Component: CentralinaSelect,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Posa Linea', name: 'posa_linea', required: true })}</Col>
				<Col>{formItem({ label: 'Formazione', name: 'mono_bi_trifase' })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Sigla Cavo', name: 'sigla_cavo' })}</Col>
			</Row>
		</>
	),
});
