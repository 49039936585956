import {Style, Circle, Stroke, Fill} from 'ol/style'

const colorsMap = {
	0: '#ffffff',
	1: '#93ff97',
	4: '#ffd1aa',
	2: '#ea8bb4',
	7: '#fdff87',
	6: '#d9a9ff',
	5: '#5df8ec',
	3: '#1ea3ba',
	9: '#76eca2',
	8: '#82d4ff',
}

export default feature => {
	const props = feature.getProperties()
	return new Style({
		image: new Circle({
			stroke: new Stroke({
				color: 'black',
				width: 1,
			}),
			fill: new Fill({
				color: colorsMap[props.modulo],
			}),
			radius: 4,
		}),
	})
}
