import _ from 'lodash'
import moment from 'moment'

export function aggregateMonthsToYears(months) {
	const mesiMapByAnno = _.groupBy(months, 'anno')
	return _.keys(mesiMapByAnno)
		.sort((a, b) => (a < b ? 1 : -1))
		.map(anno => {
			const months = mesiMapByAnno[anno]
			return {
				anno: parseInt(anno),
				...months.reduce(
					(acc, next) => ({
						f1: (acc.f1 || 0) + next.f1,
						f2: (acc.f2 || 0) + next.f2,
						f3: (acc.f3 || 0) + next.f3,
						f0: (acc.f0 || 0) + next.f0,
						consumo: (acc.consumo || 0) + next.consumo,
						importo: (acc.importo || 0) + next.importo,
					}),
					{}
				),
			}
		})
}

export function aggregateMonthsToQuarters(months) {
	const mesiMapByAnno = _.groupBy(months, 'anno')
	return _.keys(mesiMapByAnno)
		.sort((a, b) => (a < b ? 1 : -1))
		.reduce((acc, anno) => {
			const mesiAnno = mesiMapByAnno[anno]
			const trimestriMap = _.groupBy(mesiAnno, x => Math.floor((x.mese - 1) / 3) + 1)
			const trimestri = _.keys(trimestriMap)
				.sort((a, b) => (a < b ? 1 : -1))
				.map(trimestre => {
					const mesiTrimestre = trimestriMap[trimestre]
					return {
						anno: parseInt(anno),
						trimestre: parseInt(trimestre),
						...mesiTrimestre.reduce(
							(acc, next) => ({
								f1: (acc.f1 || 0) + next.f1,
								f2: (acc.f2 || 0) + next.f2,
								f3: (acc.f3 || 0) + next.f3,
								f0: (acc.f0 || 0) + next.f0,
								consumo: (acc.consumo || 0) + next.consumo,
								importo: (acc.importo || 0) + next.importo,
							}),
							{}
						),
					}
				})
			return [...acc, ...trimestri]
		}, [])
}

// export const bucketTimeseries = ({data, annoInizio, annoFine, periodo}) => {
// 	const filtered = data.filter(c => c.anno >= annoInizio && c.anno <= annoFine)
// 	switch (periodo) {
// 		case 'anno':
// 			return aggregateMonthsToYears(filtered)
// 		case 'trimestre':
// 			return aggregateMonthsToQuarters(filtered)
// 		case 'mese':
// 		default:
// 			return filtered
// 	}
// }

export const getStartingDate = (dp, period) => {
	switch (period) {
		case 'P1M':
			return moment(dp.anno + '-' + dp.mese, 'YYYY-M').startOf('month')
		case 'P3M':
			return moment(dp.anno + '-' + ((dp.trimestre - 1) * 3 + 1), 'YYYY-M').startOf('quarter')
		case 'P1Y':
			return moment(dp.anno, 'YYYY').startOf('year')
	}
}

const _bucketTimeseries = ({data, annoInizio, annoFine, periodo}) => {
	const filtered = data.filter(c => c.anno >= annoInizio && c.anno <= annoFine)
	switch (periodo) {
		case 'P1Y':
			return aggregateMonthsToYears(filtered).map(d => ({
				...d,
				startDate: getStartingDate(d, periodo),
			}))
		case 'P3M':
			return aggregateMonthsToQuarters(filtered).map(d => ({
				...d,
				startDate: getStartingDate(d, periodo),
			}))
		case 'P1M':
		default:
			return filtered.map(d => ({...d, startDate: getStartingDate(d, periodo)}))
	}
}

export const bucketTimeseries = props => _.sortBy(_bucketTimeseries(props), x => x.startDate.unix())
