import React, {useEffect} from 'react'
import {useMachine} from '@xstate/react'
import {assign, Machine} from 'xstate'
import ReactECharts from 'echarts-for-react'
import {Select, Checkbox, Spin, Table, Button} from 'antd'
import Axios from 'axios'
import Box from 'ui-box'

import {
	Aggregation,
	AGGREGAZIONI,
	CONVENZIONI,
	ENERGIA_RILEVANTE_PER_CONVENZIONE,
	FormItem,
	momentPeriodToApiPeriod,
	PANEL_HEIGHT,
	Period,
} from '../common'
import {GRID_BACKGROUND_COLOR, PADDING} from '../../../../styles'
import {aggregateData} from '../utils'
import {exportXLSX} from '../../../../utils/exports'

async function loadData({convention, plantTypes, period, aggregation}) {
	const apiPeriod = momentPeriodToApiPeriod(period, aggregation)
	return Axios.get(
		`/api/produzione-energetica?tipi=${plantTypes.join(',')}&da=${apiPeriod[0]}&a=${
			apiPeriod[1]
		}&convenzione=${convention}`
	).then(r => r.data)
}

const createDefaultContext = ({aggregation, period}) => ({
	aggregation,
	period,
	plantTypes: [],
	convention: 'FTV-SR',
	data: [],
})

const analysisByConventionMachine = Machine<
	{
		plantTypes?: string[]
		convention?: string
		aggregation?: Aggregation
		period?: Period
		pods?: any[]
		data?: any[]
	},
	// |{type: 'assignPodsToCtx'; pods: any[]}
	// | {type: 'storeData'; data: any[]}
	| {type: 'AGGREGATION_CHANGED'; aggregation: Aggregation}
	| {type: 'PERIOD_CHANGED'; period: Period}
	| {type: 'SELECT_CONVENTION'; convention: string}
	| {type: 'TOGGLE_PLANT_TYPE'; plantType: string}
>(
	{
		id: 'analysisByConvention',
		context: {},
		initial: 'checkingIfReady',
		states: {
			checkingIfReady: {
				always: [
					{
						target: 'loading',
						cond: ctx =>
							!!ctx.plantTypes.length &&
							!!ctx.convention &&
							!!ctx.aggregation &&
							!!ctx.period,
					},
					{
						target: 'notReady',
					},
				],
			},
			notReady: {},
			loading: {
				invoke: {
					src: 'loadData',
					onDone: {
						target: 'loaded',
						actions: ['storeData'],
					},
					onError: {actions: ['displayLoadError']},
				},
			},
			loaded: {},
		},
		on: {
			AGGREGATION_CHANGED: {
				target: '.',
				actions: assign((_ctx, {aggregation}) => ({aggregation})),
			},
			PERIOD_CHANGED: {target: '.', actions: assign((_ctx, {period}) => ({period}))},
			SELECT_CONVENTION: {
				target: '.',
				actions: assign((_ctx, evt) => ({convention: evt.convention})),
			},
			TOGGLE_PLANT_TYPE: {
				target: '.',
				actions: [
					assign((ctx, evt) => ({
						plantTypes: ctx.plantTypes.includes(evt.plantType)
							? ctx.plantTypes.filter(type => type !== evt.plantType)
							: [...ctx.plantTypes, evt.plantType],
					})),
				],
				cond: (_ctx, evt) => !!evt.plantType,
			},
		},
	},
	{
		services: {
			loadData,
		},
		actions: {
			displayLoadError: () => null,
			assignPodsToCtx: assign((_ctx, evt: any) => ({pods: evt.pods})),
			storeData: assign((_ctx, evt: any) => ({data: evt.data})),
		},
	}
)

const TIPI_IMPIANTI = {
	fotovoltaici: 'Fotovoltaici',
	eolici: 'Eolici',
	idroelettrici: 'Idroelettrici',
}

const AnalysisByConventionUI = ({aggregation, period, initialState}) => {
	const [state, send] = useMachine(analysisByConventionMachine, {
		context: createDefaultContext({aggregation, period}),
	})
	useEffect(() => {
		send('PERIOD_CHANGED', {period})
	}, [period])
	useEffect(() => {
		send('AGGREGATION_CHANGED', {aggregation})
	}, [aggregation])
	const renderData = () => {
		const chartsDefaultOptions = {
			grid: {
				left: 70,
				top: 20,
				right: 20,
				bottom: 30,
			},
		}
		const aggregatedData = aggregateData(state.context.data, aggregation, period)
		return (
			<Spin spinning={state.matches('loading')}>
				{state.context.data && (
					<>
						<Box display="flex" height={PANEL_HEIGHT - 24 * 2}>
							<Table
								rowKey="period"
								style={{
									width: '50%',
									backgroundColor: GRID_BACKGROUND_COLOR,
								}}
								scroll={{y: PANEL_HEIGHT - 24 * 2 - 39}}
								size="small"
								bordered
								pagination={false}
								columns={[
									{
										title: AGGREGAZIONI[state.context.aggregation],
										dataIndex: 'period',
										key: 'period',
									},
									{
										title: 'Corrispettivo',
										dataIndex: 'corrispettivo',
										key: 'corrispettivo',
									},
									{
										title: ENERGIA_RILEVANTE_PER_CONVENZIONE[
											state.context.convention
										],
										dataIndex: 'energia',
										key: 'energia',
									},
								]}
								dataSource={aggregatedData}
							/>
							<Box height="100%" width="50%" backgroundColor="aliceblue">
								<ReactECharts
									style={{
										height: '50%',
										width: '100%',
									}}
									option={{
										...chartsDefaultOptions,
										yAxis: {
											type: 'value',
											axisLabel: {
												formatter: '{value} kWh',
											},
										},
										xAxis: {
											type: 'category',
											data: aggregatedData.map(d => d.period),
										},
										series: [
											{
												data: aggregatedData.map(d => d.energia),
												type: 'bar',
												color: '#E8346E',
											},
										],
									}}
								/>
								<ReactECharts
									style={{
										height: '50%',
										width: '100%',
									}}
									option={{
										...chartsDefaultOptions,
										yAxis: {
											type: 'value',
											axisLabel: {
												formatter: '{value} €',
											},
										},
										xAxis: {
											type: 'category',
											data: aggregatedData.map(d => d.period),
										},
										series: [
											{
												data: aggregatedData.map(d => d.corrispettivo),
												type: 'bar',
												color: '#4BA4ED',
											},
										],
									}}
								/>
							</Box>
						</Box>
						<Box display="flex" justifyContent="flex-end" paddingTop={PADDING}>
							<Button
								style={{marginLeft: PADDING}}
								onClick={() => {
									exportXLSX({
										title: 'Produzione',
										sheets: [
											{
												title: 'Produzione',
												data: aggregatedData.map(d => ({
													[AGGREGAZIONI[state.context.aggregation]]:
														d.period,
													Corrispettivo: d.corrispettivo,
													[ENERGIA_RILEVANTE_PER_CONVENZIONE[
														state.context.convention
													]]: d.energia,
												})),
											},
										],
										filename: 'export.xlsx',
									})
								}}
							>
								Esporta Dati
							</Button>
						</Box>
					</>
				)}
			</Spin>
		)
	}
	return (
		<>
			<div style={{display: 'flex'}}>
				<FormItem label="Convenzione">
					<Select
						value={state.context.convention}
						style={{width: 250}}
						onChange={convention => {
							send('SELECT_CONVENTION', {convention})
						}}
					>
						{Object.keys(CONVENZIONI).map(k => (
							<Select.Option key={k} value={k}>
								{CONVENZIONI[k]}
							</Select.Option>
						))}
					</Select>
				</FormItem>
				<FormItem labelStyle={{width: 'auto', marginLeft: 20}} label="Tipi di impianti">
					{Object.keys(TIPI_IMPIANTI).map(k => (
						<Checkbox
							key={k}
							checked={state.context.plantTypes.includes(k)}
							onChange={() => send('TOGGLE_PLANT_TYPE', {plantType: k})}
						>
							{TIPI_IMPIANTI[k]}
						</Checkbox>
					))}
				</FormItem>
			</div>

			{['loading', 'loaded'].some(state.matches) && renderData()}
		</>
	)
}

export default AnalysisByConventionUI
