import React from 'react';
import { Col, Row, createInsertForm } from '../utils';

export default createInsertForm({
	featureType: 'centraline',
	form: ({ formItem, bind }) => (
		<>
			<Row>
				<Col>{formItem({ label: 'Codice', name: 'uten', required: true })}</Col>
				<Col>{formItem({ label: 'Indirizzo', name: 'indir', required: true })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'POD', name: 'pod_pdr_cont' })}</Col>
				<Col>{formItem({ label: 'Altro Identificativo', name: 'presa_al' })}</Col>
			</Row>
			<Row>
				<Col>
					{formItem({
						label: 'Regolatore (marca, modello, tipologia)',
						name: 'd4_reg',
					})}
				</Col>
				<Col>{formItem({ label: 'kVA Regolatore', name: 'n3_kva' })}</Col>
			</Row>
		</>
	),
});
