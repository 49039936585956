import React from 'react';
import { Input } from 'antd';
import { Col, Row, createInsertForm } from '../utils';

export default createInsertForm({
	featureType: 'edifici',
	form: ({ formItem, bind }) => (
		<>
			<Row>
				<Col span={24}>
					{formItem({ label: 'Denominazione Ufficiale', name: 'edif_descr', required: true })}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Indirizzo', name: 'indirizzo', required: true })}</Col>
				<Col>{formItem({ label: 'Anno di Costruzione', name: 'anno_costr' })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Superficie (mq)', name: 'sup_mq' })}</Col>
			</Row>
			<Row>
				<Col span={24}>
					{formItem({
						label: 'Altra Denominazione',
						name: 'altra_denominazione',
						Component: Input.TextArea,
					})}
				</Col>
			</Row>
		</>
	),
});
