import { CellEditor, CellRenderer } from './cell-renderers';

export const primaryKey = 'id_linea';
export const defaultSortOn = 'linea_cod';

export const columnGroups = [
	{
		name: 'linea',
		label: 'Linea',
		keys: ['linea_cod', 'posa_linea', 'lunghezza', 'toponimo'],
	},
	{
		name: 'altri_dati',
		label: 'Altri Dati',
		keys: [
			'linea_cod',
			'id_imp',
			'mono_bi_trifase',
			'sigla_cavo',
			'sez_fase_mmq',
			'sez_compless_mmq',
			'note',
		],
	},
];

export const columns = {
	'*': [
		{
			key: 'linea_cod',
			name: 'Codice',
			width: 65,
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
			width: 70,
		},
		{
			key: 'posa_linea',
			name: 'Posa',
		},
		{
			key: 'lunghezza',
			name: 'Lunghezza',
		},
		{
			key: 'data_attivaz',
			name: 'Data Attivazione',
		},
		{
			key: 'mono_bi_trifase',
			name: 'Formazione',
		},
		{
			key: 'sez_fase_mmq',
			name: 'Sezione Fase (mmq)',
		},
		{
			key: 'sez_compless_mmq',
			name: 'Sezione Complessiva (mmq)',
		},
		{
			key: 'sigla_cavo',
			name: 'Sigla Cavo',
		},
		{
			key: 'localita',
			name: 'Località',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'note',
			name: 'Note',
		},
	],
	insert: [
		{
			key: 'linea_cod',
			name: 'Codice',
			width: 65,
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
			width: 70,
		},
		{
			key: 'posa_linea',
			name: 'Posa',
			editable: true,
		},
		{
			key: 'mono_bi_trifase',
			name: 'Formazione',
			editable: true,
		},
		{
			key: 'sigla_cavo',
			name: 'Sigla Cavo',
			editable: true,
		},
	],
	'complete-inserts': [
		{
			key: 'linea_cod',
			name: 'Codice',
			width: 65,
		},
		{
			key: 'id_imp',
			name: 'Centralina',
			formatter: CellRenderer.Centralina,
			editableCellTemplate: CellEditor.Centralina,
			editable: true,
		},
		{
			key: 'posa_linea',
			name: 'Posa',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'lunghezza',
			name: 'Lunghezza',
			editable: true,
			editableCellTemplate: CellEditor.Number,
		},
		{
			key: 'data_attivaz',
			name: 'Data Attivazione',
			editable: true,
			editableCellTemplate: CellEditor.Date,
		},
		{
			key: 'mono_bi_trifase',
			name: 'Formazione',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'sez_fase_mmq',
			name: 'Sezione Fase (mmq)',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'sez_compless_mmq',
			name: 'Sezione Complessiva (mmq)',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'sigla_cavo',
			name: 'Sigla Cavo',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'localita',
			name: 'Località',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
			editable: true,
			editableCellTemplate: CellEditor.Hinted,
		},
		{
			key: 'note',
			name: 'Note',
			editable: true,
			editableCellTemplate: CellEditor.Text,
		},
	],
};
