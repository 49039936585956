import React from 'react'
import {Wrapper} from './common'

export default () => (
	<Wrapper>
		<h1>Innovazioni apportate al Catasto Energetico Online:</h1>
		<ul>
			<li>05 / 2014: completato sviluppo area illuminazione pubblica </li>
			<li>05 / 2014: completato sviluppo area interrogazioni</li>
			<li>05 / 2014: disponibile account demo </li>
			<li>08 / 2014: completato sviluppo area impianti</li>
			<li>08 / 2014: completato sviluppo area edifici</li>
			<li>09 / 2014: completato sviluppo area di associazione edifici - impianti</li>
			<li>10 / 2014: completato sviluppo area bollettazione</li>
			<li>07 / 2020: completato sviluppo versione 2.0</li>
		</ul>
	</Wrapper>
)
