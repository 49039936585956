import React from 'react'
import {Col, Row, CentralinaSelect} from '../utils'

export default ({formItem}) => {
	const codiceField = formItem({name: 'pl_cod', label: 'Codice', required: true})
	return {
		punto_luce: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>{formItem({name: 'pl_tipo', label: 'Tipo Punto Luce'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'sostegno_tipo', label: 'Tipo Corpo'})}</Col>
					<Col>{formItem({name: 'toponimo', label: 'Indirizzo'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'lampot', label: 'Sigla Lampada'})}</Col>
				</Row>
			</>
		),
		corpo: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>{formItem({name: 'lamp_nr', label: 'Numero Lampade'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'corpi_nr', label: 'Numero Corpi'})}</Col>
					<Col>{formItem({name: 'corpo_tipo', label: 'Tipo Corpo'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'coppa_tipo', label: 'Tipo Coppa'})}</Col>
					<Col>{formItem({name: 'coppa_conserv', label: 'Conservazione Coppa'})}</Col>
				</Row>
			</>
		),
		alimentazione: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>{formItem({name: 'alim_linea_cod', label: 'Linea Alimentazione'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'alim_tipo', label: 'Posa Linea'})}</Col>
					<Col>{formItem({name: 'mors_pot_posiz', label: 'Posizione Morsettiera'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'isol_classe', label: 'Classe Isolamento'})}</Col>
					<Col>{formItem({name: 'alimentat_tipo', label: 'Alimentatore'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'telecontrollo', label: 'Telecontrollo'})}</Col>
					<Col>{formItem({name: 'regolaz_cod', label: 'Codice Riduzione'})}</Col>
				</Row>
			</>
		),
		sostegno: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>{formItem({name: 'sostegno_tipo', label: 'Sostegno Tipo'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'sost_materiale', label: 'Sostegno Materiale'})}</Col>
					<Col>{formItem({name: 'sost_conserv', label: 'Sostegno Conservazione'})}</Col>
				</Row>
				<Row>
					<Col>
						{formItem({name: 'altezza_pl', label: 'Altezza (m)', type: 'number'})}
					</Col>
					<Col>{formItem({name: 'sbraccio_lung', label: 'Sbraccio'})}</Col>
				</Row>
				<Row>
					<Col span={12} push={12}>
						{formItem({
							name: 'corpo_inclin',
							label: 'Inclinato',
							type: 'checkbox',
						})}
					</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'interdistanza', label: 'Interdistanza'})}</Col>
					<Col>{formItem({name: 'arretram_ciglio', label: 'Ciglio'})}</Col>
				</Row>
			</>
		),
		supplemento_gestione: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>
						{formItem({
							name: 'id_imp',
							label: 'Centralina',
							required: true,
							Component: CentralinaSelect,
						})}
					</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'data_impianto', label: 'Data Impianto'})}</Col>
					<Col>{formItem({name: 'riferimenti', label: 'Riferimenti'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({name: 'conforme_leggi', label: 'Conforme Leggi'})}</Col>
					<Col>{formItem({name: 'localita', label: 'Località'})}</Col>
				</Row>
				<Row>
					<Col span={12} push={12}>
						{formItem({name: 'corpo_inclin', label: 'Inclinato'})}
					</Col>
				</Row>
			</>
		),
		supplemento_tecnico: (
			<>
				<Row>
					<Col>{codiceField}</Col>
					<Col>{formItem({name: 'lamp_tipo', label: 'Tipo Lampada'})}</Col>
				</Row>
				<Row>
					<Col>
						{formItem({name: 'lamp_pot', label: 'Potenza Lampada', type: 'number'})}
					</Col>
					<Col>
						{formItem({
							name: 'lamp_pot_ass',
							label: 'Potenza Punto Luce',
							type: 'number',
						})}
					</Col>
				</Row>
				<Row>
					<Col>
						{formItem({
							name: 'eff_illumin',
							label: 'Efficienza Illuminotecnica',
							type: 'number',
						})}
					</Col>
				</Row>
				<Row>
					<Col span={24}>{formItem({name: 'note', label: 'Note'})}</Col>
				</Row>
			</>
		),
	}
}
