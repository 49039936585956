import React from 'react'
import {Divider} from 'antd'
import {Link} from 'react-router-dom'

import {useAuth} from '../lib/auth'

const Dashboard = () => {
	const {userCan} = useAuth()
	return (
		<div style={{backgroundColor: 'white', height: '100%', padding: 24, overflow: 'auto'}}>
			<h3>Illuminazione Pubblica</h3>
			<p>In quest'area è possibile gestire l'infrastruttura dell'illuminazione pubblica.</p>
			<ul>
				<li>
					<Link to="/struttura/pl">Punti Luce</Link>
				</li>
				<li>
					<Link to="/struttura/centraline">Centraline</Link>
				</li>
				<li>
					<Link to="/struttura/quadri">Quadri di Sezionamento</Link>
				</li>
				<li>
					<Link to="/struttura/linee">Linee di Alimentazione</Link>
				</li>
			</ul>
			<Divider />
			<h3>Edifici e Impianti</h3>
			<p>
				In quest'area è possibile gestire Edifici, Impianti e le associazioni tra i due,
				agganciando una serie di Impianti a uno o più Edifici e specificando il coefficiente
				di impiego.
			</p>
			<ul>
				<li>
					<Link to="/edifici-e-impianti/edifici">Edifici</Link>
				</li>
				<li>
					<Link to="/edifici-e-impianti/impianti">Impianti</Link>
				</li>
			</ul>
			{userCan('MANUTENTORE') ? (
				<>
					<Divider />
					<h3>Manutenzioni</h3>
					<p>In quest'area è possibile gestire le manutenzioni.</p>
					<ul>
						<li>
							<Link to="/manutenzioni/pl">Punti Luce</Link>
						</li>
						<li>
							<Link to="/manutenzioni/centraline">Centraline</Link>
						</li>
						<li>
							<Link to="/manutenzioni/quadri">Quadri di Sezionamento</Link>
						</li>
						<li>
							<Link to="/manutenzioni/linee">Linee di Alimentazione</Link>
						</li>
						<li>
							<Link to="/manutenzioni/edifici">Edifici</Link>
						</li>
						<li>
							<Link to="/manutenzioni/impianti">Impianti</Link>
						</li>
						{userCan('SUPERVISORE') ? (
							<li>
								<Link to="/verifica-manutenzioni">
									Verifica manutenzioni in sospeso
								</Link>
							</li>
						) : null}
					</ul>
				</>
			) : null}
			<Divider />
			<h3>Interrogazioni</h3>
			<p>In quest'area è possibile interrogare i dati.</p>
			<ul>
				<li>
					<Link to="/interrogazioni/pl">Punti Luce</Link>
				</li>
				<li>
					<Link to="/interrogazioni/manutenzioni">Manutenzioni</Link>
				</li>
			</ul>
			{userCan('AMMINISTRATIVO') ? (
				<>
					<Divider />
					<h3>Bollettazione e Consumi</h3>
					<p>In quest'area è possibile inserire le bollette e verificare i consumi.</p>
					<ul>
						<li>
							<Link to="/contabilita-energetica/inserimento-bollette">Inserimento Nuove Bollette</Link>
						</li>
						<li>
							<Link to="/contabilita-energetica/analisi-consumi">Analisi Consumi</Link>
						</li>
						<li>
							<Link to="/contabilita-energetica/fotovoltaico">Fotovoltaico</Link>
						</li>
					</ul>
				</>
			) : null}
		</div>
	)
}

export default Dashboard
