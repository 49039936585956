import React from 'react';
import { Col, Row, CentralinaSelect, createInsertForm } from '../utils';
import { TipoImpiantoSelect } from './common';

export default createInsertForm({
	featureType: 'impianti',
	form: ({ formItem, bind }) => (
		<>
			<Row>
				<Col>
					{formItem({
						name: 'imp_tipo',
						label: 'Tipo Impianto',
						required: true,
						render: input => <TipoImpiantoSelect {...input} />,
					})}
				</Col>
				<Col>{formItem({ label: 'Utenza', name: 'uten', required: true })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Indirizzo', name: 'indir', required: true })}</Col>
				<Col>{formItem({ label: 'POD/PDR', name: 'pod_pdr_cont' })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Altro Identificativo', name: 'presa_al' })}</Col>
			</Row>
		</>
	),
});
