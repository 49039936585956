import { CellEditor, CellRenderer } from './cell-renderers';

export const primaryKey = 'id_imp';
export const defaultSortOn = 'uten';

export const columnGroups = [
	{
		name: 'centralina',
		label: 'Centralina',
	  keys: ['uten', 'indir', 'loc', 'pod_pdr_cont', 'presa_al', 'd4_reg', 'n3_kva'],
	},
	{
		name: 'dati_tecnici',
		label: 'Dati Tecnici',
	  keys: ['uten', 'vett', 'p_frig', 'p_ele', 'c_anno', 'n1_inom', 'desc6'],
	},
	{
		name: 'ausiliari',
		label: 'Ausiliari',
	  keys: ['uten', 'd1_flu', 'p_anno', 'd2_pom', 'd3_crep', 'p_ut'],
	},
	{
		name: 'altri_dati',
		label: 'Altri Dati',
	  keys: ['uten', 'd5_mo', 'anno', 'note'],
	},
];

export const columns = {
	'*': [
		{
		  key: 'uten',
			name: 'Codice',
			width: 65,
		},
		{
		  key: 'indir',
			name: 'Indirizzo',
		},
		{
		  key: 'loc',
			name: 'Località',
		},
		{
		  key: 'pod_pdr_cont',
			name: 'POD',
		},
		{
		  key: 'presa_al',
			name: 'Altro Identificativo',
		},
		{
		  key: 'd4_reg',
			name: 'Regolatore',
		},
		{
		  key: 'n3_kva',
			name: 'kVA Regolatore',
		},
		{
		  key: 'vett',
			name: 'Alimentazione',
		},
		{
		  key: 'p_frig',
			name: 'Numero Vani',
		},
		{
		  key: 'p_ele',
			name: 'Potenza Installata kW',
		},
		{
		  key: 'c_anno',
			name: 'Consumo (kWh/anno)',
		},
		{
		  key: 'n1_inom',
			name: 'Corrente Nominale A',
		},
		{
		  key: 'desc6',
			name: 'Protezione Generale e Scarichi',
		},
		{
		  key: 'd1_flu',
			name: 'Differenziale',
		},
		{
		  key: 'p_anno',
			name: 'Differenziale mA',
		},
		{
		  key: 'd2_pom',
			name: 'Timer',
		},
		{
		  key: 'd3_crep',
			name: 'Crepuscolare',
		},
		{
		  key: 'p_ut',
			name: 'Telecontrollo',
		},
		{
		  key: 'd5_mo',
			name: 'Costruttore Centralina',
		},
		{
		  key: 'anno',
			name: 'Anno',
		},
		{
		  key: 'note',
			name: 'Note',
		},
	],
	insert: [
		{
		  key: 'uten',
			name: 'Codice',
			width: 65,
		},
		{
		  key: 'indir',
			name: 'Indirizzo',
			editable: true,
		},
		{
		  key: 'pod_pdr_cont',
			name: 'POD',
			editable: true,
		},
		{
		  key: 'presa_al',
			name: 'Altro Identificativo',
			editable: true,
		},
		{
		  key: 'd4_reg',
			name: 'Regolatore',
			editable: true,
		},
		{
		  key: 'n3_kva',
			name: 'kVA Regolatore',
			editable: true,
			editableCellTemplate: CellEditor.Number,
		},
	],
	'complete-inserts': [
		{
		  key: 'uten',
			name: 'Codice',
			width: 65,
		},
		{
		  key: 'indir',
			name: 'Indirizzo',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'loc',
			name: 'Località',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'pod_pdr_cont',
			name: 'POD',
			editable: true,
		},
		{
		  key: 'presa_al',
			name: 'Altro Identificativo',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'd4_reg',
			name: 'Regolatore',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'n3_kva',
			name: 'kVA Regolatore',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'vett',
			name: 'Alimentazione',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'p_frig',
			name: 'Numero Vani',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'p_ele',
			name: 'Potenza Installata kW',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'c_anno',
			name: 'Consumo (kWh/anno)',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'n1_inom',
			name: 'Corrente Nominale A',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'desc6',
			name: 'Protezione Generale e Scarichi',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'd1_flu',
			name: 'Differenziale',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'p_anno',
			name: 'Differenziale mA',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'd2_pom',
			name: 'Timer',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'd3_crep',
			name: 'Crepuscolare',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'p_ut',
			name: 'Telecontrollo',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
		  key: 'd5_mo',
			name: 'Costruttore Centralina',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'anno',
			name: 'Anno',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
		  key: 'note',
			name: 'Note',
			editableCellTemplate: CellEditor.Text,
			editable: true,
		},
	],
};
