import React, {createContext} from 'react'
import {useSmartState} from '../../utils'
import moment from 'moment'

const PanelContext = createContext({})

const PanelContextProvider = ({children}) => {
	const now = moment()
	const dateRange = [
		now
			.clone()
			.subtract(5, 'years')
			.year(),
		now.year(),
	]
	const [state, setState] = useSmartState({dateRange, periodo: 'mese'})
	return <PanelContext.Provider value={{state, setState}}>{children}</PanelContext.Provider>
}

export {PanelContext, PanelContextProvider}
