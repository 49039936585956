import React, {useState, useReducer} from 'react'
import {Radio, Checkbox} from 'antd'

import './controls.scss'
import {requestFullScreen, exitFullScreen} from './utils'

const layersIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="15.529px" height="15.529px" viewBox="0 0 15.529 15.529" enable-background="new 0 0 15.529 15.529" xml:space="preserve">
<path fill="#FFFFFF" d="M0.287,4.459l7.065,3.204c0.206,0.095,0.439,0.095,0.645,0l7.066-3.204c0.502-0.229,0.502-0.987,0-1.215
	L7.997,0.039c-0.205-0.094-0.44-0.094-0.646,0L0.287,3.244C-0.216,3.472-0.216,4.23,0.287,4.459z M15.062,7.137l-1.762-0.8
	L8.397,8.559C8.168,8.664,7.925,8.717,7.674,8.717c-0.25,0-0.494-0.053-0.723-0.158L2.048,6.337l-1.761,0.8
	c-0.502,0.227-0.502,0.984,0,1.212l7.065,3.202c0.206,0.094,0.439,0.094,0.645,0l7.066-3.202
	C15.564,8.121,15.564,7.363,15.062,7.137z M15.062,11.012l-1.756-0.795l-4.909,2.225c-0.229,0.104-0.473,0.157-0.724,0.157
	c-0.25,0-0.494-0.053-0.723-0.157l-4.909-2.225l-1.755,0.795c-0.502,0.228-0.502,0.986,0,1.213l7.065,3.203
	c0.206,0.094,0.439,0.094,0.645,0l7.066-3.203C15.564,11.998,15.564,11.239,15.062,11.012z"/>
</svg>
`

const handIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="15.529px" height="15.529px" viewBox="0 0 15.529 15.529" enable-background="new 0 0 15.529 15.529" xml:space="preserve">
<path fill="#FFFFFF" d="M12.325,3.431V3.104c0-1.32-1.224-2.319-2.51-1.982C9.04-0.375,6.972-0.312,6.249,1.145
	C5,0.825,3.757,1.777,3.757,3.108v3.813C3.154,6.698,2.448,6.769,1.881,7.189C0.994,7.849,0.799,9.105,1.44,10.007l3.628,5.091
	c0.181,0.256,0.475,0.406,0.788,0.406h6.739c0.45,0,0.84-0.311,0.941-0.75l0.914-3.959c0.104-0.452,0.156-0.916,0.156-1.381V5.438
	C14.606,4.208,13.533,3.261,12.325,3.431z M13.155,9.414c0,0.354-0.041,0.709-0.12,1.055l-0.825,3.585H6.105l-3.482-4.89
	C2.186,8.552,3.077,7.871,3.511,8.48l0.819,1.152c0.272,0.381,0.878,0.19,0.878-0.282V3.108c0-0.774,1.105-0.75,1.105,0.021v4.634
	c0,0.268,0.217,0.484,0.485,0.484h0.208c0.266,0,0.482-0.217,0.482-0.484V2.051c0-0.775,1.105-0.75,1.105,0.021v5.691
	c0,0.268,0.217,0.484,0.483,0.484h0.208c0.267,0,0.483-0.217,0.483-0.484V3.082c0-0.776,1.105-0.75,1.105,0.022v4.659
	c0,0.268,0.217,0.484,0.484,0.484h0.207c0.268,0,0.484-0.217,0.484-0.484V5.458c0-0.794,1.104-0.775,1.104-0.021V9.414L13.155,9.414
	z"/>
</svg>
`

const fullscreenIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="15.529px" height="15.529px" viewBox="0 0 15.529 15.529" enable-background="new 0 0 15.529 15.529" xml:space="preserve">
<path fill="#FFFFFF" d="M15.324,10.528v3.779c0,0.448-0.361,0.81-0.811,0.81h-3.779c-0.722,0-1.082-0.873-0.573-1.384l1.222-1.222
	L7.762,8.896l-3.617,3.62l1.221,1.218c0.51,0.511,0.149,1.384-0.572,1.384H1.014c-0.449,0-0.81-0.361-0.81-0.81v-3.779
	c0-0.723,0.874-1.083,1.383-0.574l1.222,1.222l3.62-3.617l-3.62-3.62L1.587,5.163C1.078,5.672,0.204,5.311,0.204,4.59V0.811
	C0.204,0.361,0.564,0,1.014,0h3.779c0.722,0,1.082,0.874,0.572,1.384L4.145,2.605l3.617,3.617l3.621-3.621l-1.222-1.218
	C9.652,0.874,10.013,0,10.734,0h3.779c0.449,0,0.811,0.361,0.811,0.811V4.59c0,0.721-0.875,1.082-1.384,0.573l-1.222-1.222
	L9.098,7.559l3.621,3.62l1.222-1.221C14.449,9.445,15.324,9.806,15.324,10.528L15.324,10.528z"/>
</svg>
`

const selectionIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="15.529px" height="15.529px" viewBox="0 0 15.529 15.529" enable-background="new 0 0 15.529 15.529" xml:space="preserve">
<rect x="1.029" y="1.781" fill="none" stroke="#FFFFFF" stroke-linejoin="round" stroke-miterlimit="3" stroke-dasharray="4,2" width="14" height="12"/>
</svg>
`

const byBoxIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="15.529px" height="15.529px" viewBox="0 0 15.529 15.529" enable-background="new 0 0 15.529 15.529" xml:space="preserve">
<rect x="1.029" y="5.406" opacity="0.7" fill="none" stroke="#FFFFFF" stroke-miterlimit="10" width="9" height="9"/>
<text transform="matrix(1 0 0 1 4.7686 10.2617)" fill="#FFFFFF" font-family="'OpenSans-Bold'" font-size="13.6674">Q</text>
</svg>
`

const SVGIcon = ({svg}) => <span dangerouslySetInnerHTML={{__html: svg}} />

export const MapToggleControlStateless = ({onClick, active, render}) => {
	const className = ['ol-button']
		.concat(active && 'ol-active')
		.filter(Boolean)
		.join(' ')
	return (
		<div className={className}>
			<button onClick={onClick}>{render}</button>
		</div>
	)
}

export const MapToggleControl = ({onChange, defaultActive = false, render}) => {
	const [active, setActive] = useState(defaultActive)
	return (
		<MapToggleControlStateless
			onClick={_e => {
				const newVal = !active
				setActive(newVal)
				onChange && onChange(newVal)
			}}
			active={active}
			render={render}
		/>
	)
}

export const MapSwitchControlStateless = ({current, onChange, items = []} = {}) => {
	const itemProps = index => ({
		onClick: _e => onChange(index),
		isCurrent: index === current,
	})
	return (
		<div className="ol-switch-control">
			{items.map((item, index) => (
				<MapSwitchControl.Item key={index} {...itemProps(index)}>
					{item}
				</MapSwitchControl.Item>
			))}
		</div>
	)
}

export const MapSwitchControl = ({items, initial = 0, onChange} = {}) => {
	const [current, setCurrent] = useState(initial)
	return (
		<MapSwitchControlStateless
			current={current}
			onChange={index => {
				setCurrent(index)
				onChange && onChange(index)
			}}
			items={items}
		/>
	)
}

MapSwitchControl.Item = ({isCurrent, onClick, children}) => {
	const className = ['ol-button']
		.concat(isCurrent && 'ol-active')
		.filter(Boolean)
		.join(' ')
	return (
		<div className={className}>
			<button onClick={onClick}>{children}</button>
		</div>
	)
}

export const MapControlBar = ({children}) => {
	return (
		<div
			className="ol-control ol-control-bar"
			style={{position: 'absolute', top: '.5em', right: '.5em'}}
		>
			{children}
		</div>
	)
}

export const ViewByElectricalBoxControl = ({onChange}) => (
	<MapToggleControl onChange={onChange} render={<SVGIcon svg={byBoxIcon} />} />
)

export const FullScreenControl = ({container}) => {
	function setFullscreen(active) {
		active ? requestFullScreen(container.current) : exitFullScreen()
	}
	return (
		<MapToggleControl
			render={<SVGIcon svg={fullscreenIcon} />}
			onChange={active => setFullscreen(active)}
		/>
	)
}

export const LayerSwitcher = ({
	baseLayers,
	overlays,
	defaultActiveOverlays = [],
	onBaseLayerChange,
	onOverlaysChange,
}) => {
	const [active, setActive] = useState(false)
	const [activeLayers, setActiveLayers] = useReducer(
		(oldState, newState) => ({...oldState, ...newState}),
		{
			baseLayer: 0,
			overlays: defaultActiveOverlays,
		}
	)
	const toggleLayer = index => {
		const overlays = activeLayers.overlays.includes(index)
			? activeLayers.overlays.filter(x => x !== index)
			: [...activeLayers.overlays, index]
		setActiveLayers({
			overlays,
		})
		onOverlaysChange && onOverlaysChange(overlays)
	}
	const className = ['ol-layer-switcher', 'ol-button']
		.concat(active && 'ol-active')
		.filter(Boolean)
		.join(' ')
	const layerGroupTitle = title => (
		<span style={{fontSize: '90%', fontWeight: 'bold', color: 'rgba(0,0,0,.7)'}}>{title}</span>
	)
	function selectBaseLayer(index) {
		setActiveLayers({baseLayer: index})
		onBaseLayerChange && onBaseLayerChange(index)
	}
	return (
		<div className={className}>
			<button onClick={_e => setActive(!active)}>
				<SVGIcon svg={layersIcon} />
			</button>
			{active && (
				<div className="ol-control ol-layer-switcher-popup">
					{layerGroupTitle('Base Layer')}
					<Radio.Group
						size="small"
						value={activeLayers.baseLayer}
						onChange={e => selectBaseLayer(e.target.value)}
					>
						<ul style={{listStyleType: 'none', padding: 0}}>
							{baseLayers.map(({name}, index) => (
								<li key={index} style={{margin: 0}}>
									<Radio value={index}>
										<span style={{fontSize: '90%', margin: 0}}>{name}</span>
									</Radio>
								</li>
							))}
						</ul>
					</Radio.Group>

					{layerGroupTitle('Overlays')}
					<ul style={{listStyleType: 'none', padding: 0, marginBottom: 0}}>
						{overlays.map(({name}, index) => (
							<li key={index}>
								<Checkbox
									value={index}
									style={{fontSize: '90%'}}
									checked={activeLayers.overlays.includes(index)}
									onChange={_e => toggleLayer(index)}
								>
									{name}
								</Checkbox>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)
}

export const MoveSelectToggle = ({dragBox}) => (
	<MapSwitchControl
		onChange={index => dragBox.setActive(index === 1)}
		items={[<SVGIcon svg={handIcon} />, <SVGIcon svg={selectionIcon} />]}
	/>
)
