import React from 'react';
import { Col, Row, CentralinaSelect } from '../utils';

export default ({ formItem }) => ({
	quadro_sezionamento: (
		<>
			<Row>
				<Col>{formItem({ name: 'centr_sez_cod', label: 'Codice', required: true })}</Col>
				<Col>{formItem({ name: 'descrizione', label: 'Descrizione', required: true })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ name: 'corr_nom_amp', label: 'Corrente Nominale A' })}</Col>
				<Col>{formItem({ name: 'protez_tipo', label: 'Protezioni' })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ name: 'toponimo', label: 'Indirizzo' })}</Col>
			</Row>
		</>
	),
	dati_supplementari: (
		<>
			<Row>
				<Col>{formItem({ name: 'centr_sez_cod', label: 'Codice', required: true })}</Col>
				<Col>
					{formItem({
						name: 'id_imp',
						label: 'Centralina',
						required: true,
						Component: CentralinaSelect,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({ name: 'data_attvaz', label: 'Data Attivazione' })}</Col>
			</Row>
			<Row>
				<Col span={24}>{formItem({ name: 'note', label: 'Note' })}</Col>
			</Row>
		</>
	),
});
