import React from 'react'
import moment, {Moment} from 'moment'

export type Aggregation = 'year' | 'month' | 'quarter'
export type Period = [Moment, Moment]

export const PANEL_HEIGHT = 400
export const AGGREGAZIONI = {
	month: 'Mese',
	quarter: 'Trimestre',
	year: 'Anno',
}

export const TIPI_CONVENZIONI = ['FTV-SR', 'FER', 'SSP', 'SSA', 'RID'] as const

export const CONVENZIONI = {
	'FTV-SR': 'Conto Energia',
	FER: 'Fonti Energetiche Rinnovabili',
	SSP: 'Scambio Sul Posto',
	SSA: 'Scambio Sul Posto Altrove',
	RID: 'Ritiro Dedicato',
}

export const ENERGIA_RILEVANTE_PER_CONVENZIONE = {
	'FTV-SR': 'Energia prodotta',
	FER: 'Energia prodotta',
	SSP: 'Energia scambiata',
	SSA: 'Energia scambiata',
	RID: 'Energia immessa/ceduta',
}

export const FormItem = ({
	children,
	label,
	style,
	labelStyle,
}: {
	children
	label?
	style?
	labelStyle?
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginBottom: 20,
				...style,
			}}
		>
			<div
				style={{
					width: 120,
					textAlign: 'right',
					marginRight: 8,
					...labelStyle,
				}}
			>
				{label}:
			</div>
			<div>{children}</div>
		</div>
	)
}

export const adaptMomentRangeToPeriod = (range, periodType) =>
	!range ? range : [moment(range[0]).startOf(periodType), moment(range[1]).endOf(periodType)]

export const momentPeriodToApiPeriod = (period, periodType) =>
	adaptMomentRangeToPeriod(period, periodType).map(m => m.format('YYYY-MM'))
