import React, {useState} from 'react'
import cookie from 'react-cookies'
import {Modal} from 'antd'
import moment from 'moment'

const V2_NOTICE_COOKIE = 'v2NoticeAlreadySeen'
let v2NoticeAlreadySeen = cookie.load(V2_NOTICE_COOKIE)

export const NewVersionNotice = () => {
	const [visible, setVisible] = useState(v2NoticeAlreadySeen !== 'true')
	return (
		<Modal
			visible={visible}
			footer={null}
			onCancel={() => {
				setVisible(false)
				cookie.save(V2_NOTICE_COOKIE, 'true', {expires: moment().add(1, 'year').toDate()})
			}}
			title="Versione 2.0"
		>
			Benvenuto nella versione 2.0 del Catasto Energetico FVG. Questa versione rappresenta una completa
			riscrittura del precedente Catasto, realizzata per migliorare la grafica e le prestazioni. Le
			funzionalità a disposizione sono le stesse presenti nella versione precedente. <br />
			Buon lavoro!
		</Modal>
	)
}
