import { Style, RegularShape, Stroke, Fill, Text } from 'ol/style';

const getText = (feature, resolution) => feature.getProperties().uten

const createTextStyle = (feature, resolution, {
	fontFamily,
	fontSize,
	fontWeight,
	textAlign,
	textBaseline,
	offsetX,
	offsetY,
	placement,
	maxangle,
	overflow,
	rotation,
	fillColor,
	haloColor,
	haloWidth,
} = {}) => {
	const text = getText(feature, resolution)
	return new Text({
		text,
		font: `${fontWeight} ${fontSize}px ${fontFamily}`,
		textAlign,
		textBaseline,
		placement,
		fill: new Fill({
			color: fillColor,
		}),
		stroke: new Stroke({
			color: haloColor,
			width: parseInt(haloWidth, 10),
		}),
		offsetX: parseInt(offsetX, 10),
		offsetY: parseInt(offsetY, 10),
		maxAngle: maxangle ? parseFloat(maxangle) : undefined,
		overflow: overflow ? (overflow == 'true') : undefined,
		rotation: parseFloat(rotation || 0),
	})
}

export default (feature, resolution) => {
	const text = createTextStyle(feature, resolution, {
		fillColor: 'black',
		haloColor: 'white',
		haloWidth: 2,
		offsetX: 0,
		offsetY: 0,
		fontFamily: 'Tahoma',
		// fontFamily: 'Arial',
		fontWeight: 'bold',
		fontStyle: 'normal',
		fontSize: 15,
		textAlign: 'left',
		textBaseline: 'bottom', // 'top', 'hanging', 'center', 'alphabetic|ideographic', 'bottom'
	})
	const image = new RegularShape({
		points: 4,
		radius: 22,
		rotation: 45 * Math.PI / 180,
		stroke: new Stroke({
			color: '#FEB93A',
			width: 0.5,
		}),
		fill: new Fill({
			color: 'rgba(252, 230, 64, 0.7)',
			// color: 'rgba(252, 230, 64, 0.5)',
			// color: 'rgba(252, 230, 64, 0.34)',
			// color: '#FCE640',
		}),
	})
	return new Style({
		image,
		text,
		// conflictResolution: true // NOT AVAILABLE ON THE CLIENT!
		// spaceAround: 10
	})
}