export const primaryKey = 'plid'
export const defaultSortOn = 'pl_cod'

export const columns = {
	filter: [
		{
			key: 'pl_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
	],
	selection: [
		{
			key: 'pl_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'pl_tipo',
			name: 'Tipo Punto Luce',
		},
		{
			key: 'corpo_tipo',
			name: 'Tipo Corpo',
		},
		{
			key: 'lampot',
			name: 'Sigla Lampada',
		},
	],
}
