import QuickGuide from './quick-guide'
import Contacts from './contacts'
import Credits from './credits'
import Updates from './updates'

export default {
	QuickGuide,
	Contacts,
	Credits,
	Updates,
}
