import {Alert, Button, Card, Col, Form, Input, Row, Select, Spin} from 'antd'
import React, {useEffect, useMemo, useReducer, useRef} from 'react'
import _ from 'lodash'

import Grid from '../../components/bollettazione/impianti-grid'
import {APPARTENENZE} from '../../configs/grids/impianti'
import {googleLikeFilter, pluralize, useDebounce, useSmartState} from '../../utils'
import AnalyzeView from './analyze-view'
import TipiImpiantiSelect from '../../components/bollettazione/tipi-impianti-select'

import {get as getImpianti} from '../../services/impianti'
import {formItemLayout} from '../../components/forms/common'
import {InfoImpianto} from '../../components/bollettazione/info-impianto'
import {PanelContextProvider} from './panel-context'
import {Spacer} from '../../utils/ui'
import Box from 'ui-box'
import {CloseOutlined} from '@ant-design/icons'

const FormItem = Form.Item
const Option = Select.Option

class Steps {
	static SELECT = 'select'
	static ANALYZE = 'analyze'
}

const Provider = ({children, loaderFunc}) => {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		data: null,
		error: null,
		loading: true,
	})

	useEffect(() => {
		;(async () => {
			try {
				const data = await loaderFunc()
				setState({data, loading: false})
			} catch (err) {
				setState({error: err.message, loading: false})
			}
		})()
	}, [])

	return children(state)
}

const Panel = ({impianti = []}) => {
	const [state, setState] = useSmartState({
		tipo: 'centr_',
		appartenenza: [],
		filter: '',
		currentStep: Steps.SELECT,
		// currentStep: Steps.ANALYZE,
		gridSelection: [],
	})

	const debouncedFilter = useDebounce(state.filter, 200)

	const gridRef = useRef()

	const impiantiMapById = useMemo(
		() => impianti.reduce((acc, next) => ({...acc, [next['id_imp']]: next}), {}),
		[impianti]
	)

	function bind(name, getter = v => v) {
		return {
			onChange: val => {
				setState({[name]: getter(val)})
			},
			value: state[name] || null,
		}
	}

	const {impiantiFiltrati, impiantiFiltratiByTipo} = useMemo(() => {
		const {tipo, appartenenza} = state
		const impiantiFiltratiByTipo = impianti.filter(i => (tipo ? i.imp_tipo === tipo : true))
		const impiantiFiltrati = impiantiFiltratiByTipo
			.filter(i => {
				if (appartenenza.length === 0) return true
				return appartenenza.includes(i.appart)
			})
			.filter(i => googleLikeFilter(debouncedFilter)(i))

		return {impiantiFiltrati, impiantiFiltratiByTipo}
	}, [impianti, state.tipo, state.appartenenza, debouncedFilter])

	const [selectedImpianti, selectedTipiImpianti] = useMemo(() => {
		const selectedImpianti = _.compact(state.gridSelection.map(id => impiantiMapById[id]))
		const selectedTipiImpianti = _.uniq(_.compact(selectedImpianti.map(i => i.imp_tipo)))
		return [selectedImpianti, selectedTipiImpianti]
	}, [state.gridSelection])

	const appartenenze = useMemo(() => {
		const appartenenzeImpianti = _.uniq(impiantiFiltratiByTipo.map(imp => imp.appart))
		console.log('appartenenzeImpianti', appartenenzeImpianti)
		return APPARTENENZE.filter(item => appartenenzeImpianti.includes(item.value))
	}, [impiantiFiltratiByTipo])

	const content =
		state.currentStep === Steps.SELECT ? (
			<Row style={{height: '100%'}}>
				<Col span={8}>
					<Form>
						<FormItem
							{...formItemLayout}
							label="Tipo Impianto"
							hasFeedback
							required={true}
						>
							<TipiImpiantiSelect
								impianti={impianti}
								value={state.tipo}
								onChange={tipo => setState({tipo, appartenenza: []})}
								placeholder="Seleziona..."
								allowClear
							/>
						</FormItem>
						<FormItem
							{...formItemLayout}
							label="Appartenenza"
							hasFeedback
							required={true}
						>
							<Select
								mode="multiple"
								placeholder="Qualsiasi appartenenza..."
								{...bind('appartenenza')}
								allowClear
							>
								{appartenenze.map(({label, value}, i) => (
									<Option key={i} value={value}>
										{label}
									</Option>
								))}
							</Select>
						</FormItem>
						<FormItem {...formItemLayout} label="Ricerca libera" hasFeedback>
							<Input {...bind('filter', e => e.target.value)} allowClear />
						</FormItem>
					</Form>
					<div style={{lineHeight: 0.5, fontSize: '80%'}}>
						{pluralize(
							selectedImpianti.length,
							null,
							<InfoImpianto impianto={selectedImpianti[0]} />,
							<>
								<p>
									<strong>Impianti selezionati</strong>
								</p>
								<p>
									{selectedImpianti.length} impianti selezionati
									{selectedTipiImpianti.length > 1 ? ' (di tipo diverso)' : null}
								</p>
							</>
						)}
					</div>
				</Col>
				<Col span={16} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
					<Box flex={1}>
						<Grid
							style={{border: '1px solid #dddddd'}}
							ref={gridRef}
							data={impiantiFiltrati}
							onSelectionChange={gridSelection => setState({gridSelection})}
							selection={state.gridSelection}
						/>
					</Box>
					<Spacer style={{marginTop: 10}}>
						<Button onClick={e => gridRef.current && gridRef.current.selectAll()}>
							Seleziona Tutti
						</Button>
						<Button
							onClick={e => setState({gridSelection: []})}
							disabled={state.gridSelection.length === 0}
						>
							Deseleziona Tutti
						</Button>
						<Button
							onClick={e =>
								setState({
									currentStep: Steps.ANALYZE,
								})
							}
							disabled={
								state.gridSelection.length === 0 || selectedTipiImpianti.length > 1
							}
						>
							Conferma
						</Button>
					</Spacer>
				</Col>
			</Row>
		) : (
			<AnalyzeView
				impianti={selectedImpianti}
				goBack={() => setState({currentStep: 'select'})}
			/>
		)

	return <Box height={500}>{content}</Box>
}

export default ({remove}) => {
	useEffect(() => {
		console.log('ADDED')
	}, [])
	return (
		<PanelContextProvider>
			<Card style={{width: '100%', paddingTop: 16}}>
				<CloseOutlined
					role="button"
					style={{
						position: 'absolute',
						right: 15,
						top: 15,
						lineHeight: 1,
						color: 'inherit',
					}}
					onClick={remove}
				/>
				<Provider loaderFunc={getImpianti}>
					{({data, error, loading}) =>
						loading ? (
							<Spin />
						) : error ? (
							<Alert type="error" message={`Error: ${error}`} />
						) : (
							<Panel impianti={data} />
						)
					}
				</Provider>
			</Card>
		</PanelContextProvider>
	)
}
