import React, {useState, useCallback} from 'react'
import {Button} from 'antd'
import {v4 as uuid} from 'uuid'
import _ from 'lodash'
import {Space} from '../../../components/layout'
import {PADDING} from '../../../styles'
import {Panel} from './panel'

const createPanel = () => ({key: uuid()})

// const userSelections = [
// 	{
// 		type: 'convention',
// 		convention: 'conto energia',
// 		plantTypes: ['fotovoltaici'],
// 		aggegation: 'month',
// 		from: '2021-05',
// 		to: '2021-10',
// 	},
// 	{
// 		type: 'pod',
// 		selectedPod: 'IT001E34158471_PV',
// 		conventions: [
// 			{
// 				aggegation: 'month',
// 				from: '2021-05',
// 				to: '2021-10',
// 			},
// 			{
// 				aggegation: 'month',
// 				from: '2021-05',
// 				to: '2021-10',
// 			},
// 		],
// 	},
// ]

function ContabilitaProduzioneEnergetica() {
	const [panels, setPanels] = useState([
		// 	{
		// 		key: uuid(),
		// 		// type: 'convention',
		// 		// convention: 'conto energia',
		// 		// plantTypes: ['fotovoltaici'],
		// 		// aggregation: 'month',
		// 		// // period: ['2021-05', '2021-10'],
		// 	},
		// 	{
		// 		key: uuid(),
		// 		// type: 'pod',
		// 		// selectedPod: 'IT001E34158471_PV',
		// 		// conventions: [
		// 		// 	{<
		// 		// 		aggregation: 'month',
		// 		// 		// period: ['2021-05', '2021-10'],
		// 		// 	},
		// 		// 	{
		// 		// 		aggregation: 'year',
		// 		// 		// period: ['2020', '2021'],
		// 		// 	},
		// 		// ],
		// 	},
		createPanel(),
	])
	const addPanel = useCallback(() => setPanels([...panels, createPanel()]), [panels])
	const removePanel = useCallback(key => setPanels(panels.filter(p => p.key !== key)), [panels])
	const removeAllPanels = useCallback(() => setPanels([]), [panels])
	return (
		<div style={{height: '100%', padding: PADDING, overflow: 'auto'}}>
			<div style={{display: 'flex', alignItems: 'center'}}>
				<h1 style={{marginBottom: 2}}>Analisi Produzione Energetica</h1>
				<Button style={{marginLeft: 10}} onClick={() => addPanel()}>
					Nuovo pannello
				</Button>
				<Button style={{marginLeft: 10}} onClick={() => removeAllPanels()} danger>
					Chiudi tutti
				</Button>
			</div>
			<Space direction="vertical" style={{width: '100%', marginTop: 10}}>
				{panels.map(({key, ...initialState}, i) => (
					<Panel key={key} onClose={() => removePanel(key)} />
				))}
			</Space>
		</div>
	)
}

export default ContabilitaProduzioneEnergetica
