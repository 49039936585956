import { Style, RegularShape, Stroke, Fill } from 'ol/style';

export default new Style({
	image: new RegularShape({
		points: 4,
		radius: 5,
		rotation: 45 * Math.PI / 180,
		stroke: new Stroke({
			color: 'rgba(114, 53, 193, 1)',
			// width: 0.5,
			width: 1,
		}),
		fill: new Fill({
			// color: 'rgba(114, 53, 193, 0.36)',
			color: 'rgba(114, 53, 193, 0.56)',
		}),
	}),
})