import React, {useState, useEffect, useMemo, useContext} from 'react'
import {Button, Row, Col, DatePicker, Radio, message} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import Box from 'ui-box'
import axios from 'axios'
import {saveAs} from 'file-saver'

import {APPARTENENZE, PLANT_TYPES_LOOKUP} from '../../../configs/grids/impianti'
import {fetchConsumiImpianti} from '../../../services/bollette'
import Grid from '../../../components/Grid'
import {bucketTimeseries} from './utils'
import {ConsumiChart, ImportiChart} from './charts'
import {PanelContext} from '../panel-context'
import {PADDING} from '../../../styles'

const YearPicker = ({value, onChange, ...props}) => {
	const [open, setOpen] = useState(false)

	const handleChange = value => {
		setOpen(false)
		onChange(value ? value.year() : null)
	}
	return (
		<DatePicker
			mode="year"
			format="YYYY"
			placeholder="Select year"
			value={value && moment(value, 'YYYY')}
			open={open}
			onOpenChange={open => {
				setOpen(open)
			}}
			onPanelChange={handleChange}
			onChange={handleChange}
			allowClear={false}
			{...props}
		/>
	)
}

const SelectionInfo = ({impianti}) => {
	const tipo = useMemo(
		() => (impianti.length ? PLANT_TYPES_LOOKUP[impianti[0].imp_tipo] : null),
		[impianti]
	)
	const renderInfo = () => {
		if (impianti.length > 1) {
			return (
				<>
					<p>{impianti.length} impianti selezionati</p>
					<p>
						Tipo di impianti: <strong>{tipo}</strong>
					</p>
					<p>
						Appartenenza:{' '}
						<strong>
							{_.uniq(
								_.compact(
									impianti.map(i =>
										_.get(_.find(APPARTENENZE, {value: i.appart}), 'label')
									)
								)
							).join(', ')}
						</strong>
					</p>
				</>
			)
		} else {
			let impianto = impianti[0]
			return (
				<>
					<p>{[impianto.pod_pdr_cont, impianto.presa_al].filter(Boolean).join(' | ')}</p>
					<p>{impianto.uten}</p>
					<p>{[impianto.indir, impianto.loc].filter(Boolean).join(' ')}</p>
					<p>
						{[impianto.um, impianto.vett, impianto.cons_prod].filter(Boolean).join(' ')}
					</p>
					<p>{impianto.note}</p>
				</>
			)
		}
	}
	return <div style={{lineHeight: 0.5, fontSize: '80%'}}>{renderInfo()}</div>
}

const defaultColumns = [
	{
		key: 'anno',
		name: 'Anno',
	},
	{
		key: 'importo',
		name: 'Corrispettivi',
	},
	{
		key: 'consumo',
		name: 'Consumo',
	},
	{
		key: 'f1',
		name: 'F1',
	},
	{
		key: 'f2',
		name: 'F2',
	},
	{
		key: 'f3',
		name: 'F3',
	},
	{
		key: 'f0',
		name: 'F0',
	},
]

const periodiISO = {
	anno: 'P1Y',
	trimestre: 'P3M',
	mese: 'P1M',
}

const exportData = async data =>
	axios
		.post('/api/utils/export', data, {
			responseType: 'blob',
		})
		.then(({data}) => saveAs(data, 'export.xls'))
		.catch(err => message.error(`Errore nell'esportazione: ${err.message}`))

const exportBills = ({plantIds, startYear, endYear}) => {
	console.log({plantIds, startYear, endYear})
	return axios
		.get(
			`/api/bollette/export?impianti=${plantIds.join(
				','
			)}&inizio=${startYear}&fine=${endYear}`,
			{
				responseType: 'blob',
			}
		)
		.then(({data}) => {
			console.log(data)
			return saveAs(data, 'bollette.xlsx')
		})
		.catch(err => message.error(`Errore nell'esportazione: ${err.message}`))
	// http://localhost/api/bollette/export?impianti=36232,36389,36398,36432&inizio=2010&fine=2014
	//http://catastoenergetico.it/consumi/analisi
}

export default ({impianti, goBack}) => {
	const {
		state: {dateRange, periodo},
		setState,
	} = useContext(PanelContext)
	const [consumi, setConsumi] = useState([])

	const impiantiIds = useMemo(() => impianti.map(i => i.id_imp), [impianti])
	const [annoInizio, annoFine] = useMemo(() => [dateRange[0], dateRange[1]], [dateRange])

	useEffect(() => {
		;(async () => {
			fetchConsumiImpianti(impianti.map(({id_imp}) => id_imp)).then(consumi => {
				// console.log(data)
				// const consumi = byMonth
				setConsumi(consumi)
			})
		})()
	}, [impiantiIds])

	const aggregatedConsumi = useMemo(
		() => bucketTimeseries({data: consumi, annoInizio, annoFine, periodo: periodiISO[periodo]}),
		[consumi, periodo, annoInizio, annoFine]
	)

	const series = useMemo(() => {
		const anni = _.range(annoFine, annoInizio - 1)
		switch (periodo) {
			case 'anno':
				return anni.map(anno => _.find(aggregatedConsumi, {anno}) || {anno})
			case 'trimestre':
				return _.flatten(
					anni.map(anno =>
						_.range(4, 0).map(
							trimestre =>
								_.find(aggregatedConsumi, {anno, trimestre}) || {anno, trimestre}
						)
					)
				)
			case 'mese':
			default:
				return _.flatten(
					anni.map(anno =>
						_.range(12, 0).map(
							mese => _.find(aggregatedConsumi, {anno, mese}) || {anno, mese}
						)
					)
				)
		}
	}, [aggregatedConsumi])

	const columns = useMemo(() => {
		switch (periodo) {
			case 'mese':
				return [
					defaultColumns[0],
					{
						key: 'mese',
						name: 'Mese',
					},
					...defaultColumns.slice(1),
				]
			case 'trimestre':
				return [
					defaultColumns[0],
					{
						key: 'trimestre',
						name: 'Trimestre',
					},
					...defaultColumns.slice(1),
				]
			default:
				return defaultColumns
		}
	}, [periodo])
	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Row>
				<Col span={12}>
					<SelectionInfo impianti={impianti} />
				</Col>
				<Col span={12}>
					<Row gutter={PADDING}>
						<Col span={6}>
							<YearPicker
								placeholder="Anno iniziale"
								value={dateRange[0]}
								onChange={value => {
									setState({dateRange: [value, Math.max(value, dateRange[1])]})
								}}
							/>
						</Col>
						<Col span={6}>
							<YearPicker
								placeholder="Anno finale"
								value={dateRange[1]}
								onChange={value =>
									setState({dateRange: [Math.min(value, dateRange[0]), value]})
								}
							/>
						</Col>
						<Col span={12}>
							<Radio.Group
								value={periodo}
								buttonStyle="solid"
								onChange={e => setState({periodo: e.target.value})}
							>
								<Radio.Button value="mese">Mese</Radio.Button>
								<Radio.Button value="trimestre">Trimestre</Radio.Button>
								<Radio.Button value="anno">Anno</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{flex: 1}}>
				<Col span={12} style={{height: '100%'}}>
					<Grid
						data={series}
						columns={columns}
						// rowKey="id_imp"
						style={{width: '100%', height: '100%', border: '1px solid #dddddd'}}
					/>
				</Col>
				<Col span={12} style={{height: '100%'}}>
					<ConsumiChart
						data={aggregatedConsumi}
						annoInizio={dateRange[0]}
						annoFine={dateRange[1]}
						periodo={periodiISO[periodo]}
						style={{
							width: '100%',
							height: '50%',
							backgroundColor: '#e5e8eb',
						}}
					/>
					<ImportiChart
						data={aggregatedConsumi}
						annoInizio={dateRange[0]}
						annoFine={dateRange[1]}
						periodo={periodiISO[periodo]}
						style={{
							width: '100%',
							height: '50%',
							backgroundColor: '#e5e8eb',
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col push={12} span={12}>
					<Box display="flex" marginTop={PADDING}>
						<Button onClick={e => goBack()} style={{marginRight: PADDING}}>
							Modifica Selezione Impianti
						</Button>
						<Button
							style={{marginRight: PADDING}}
							onClick={() =>
								exportData(
									aggregatedConsumi.map(row =>
										_.pick(row, [
											'anno',
											'mese',
											'trimestre',
											'consumo',
											'importo',
											'f1',
											'f2',
											'f3',
											'f0',
										])
									)
								)
							}
						>
							Esporta Dati
						</Button>
						<Button
							style={{marginRight: PADDING}}
							onClick={() =>
								exportBills({
									plantIds: impianti.map(({id_imp}) => id_imp),
									startYear: annoInizio,
									endYear: annoFine,
								})
							}
						>
							Esporta Dettaglio Bollette
						</Button>
					</Box>
				</Col>
			</Row>
		</Box>
	)
}
