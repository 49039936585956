import React from 'react'
import {Card, DatePicker, Radio, Tabs} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import {CloseOutlined} from '@ant-design/icons'
import _ from 'lodash'
import moment, {Moment} from 'moment'
import {assign, Machine} from 'xstate'
import {useMachine} from '@xstate/react'

import AnalysisByConventionUI from './by-convention'
import AnalysisByPodUI from './by-pod'
import {AGGREGAZIONI, FormItem} from './common'

const {RangePicker} = DatePicker

const analysisPanelMachine = Machine<{
	tab?: string
	aggregation?: 'year' | 'month' | 'quarter'
	period?: [Moment, Moment]
}>({
	// context: {
	// 	aggregation: 'month',
	// 	period: [moment().startOf('month').subtract(5, 'month'), moment().endOf('month')],
	// 	tab: 'convention',
	// },
	context: {},
	states: {
		idle: {},
	},
	initial: 'idle',
	on: {
		SELECT_AGGREGATION: {actions: assign((_ctx, {aggregation}) => ({aggregation}))},
		SELECT_PERIOD: {actions: assign((_ctx, {period}) => ({period}))},
		SELECT_TAB: {actions: assign((_ctx, {tab}) => ({tab}))},
	},
})

export const Panel = ({onClose, initialState = {}}) => {
	const [state, send] = useMachine(analysisPanelMachine, {
		context: {
			aggregation: 'month',
			period: [moment().startOf('month').subtract(5, 'month'), moment().endOf('month')],
			tab: 'convention',
		},
	})
	return (
		<Card style={{width: '100%'}}>
			<CloseOutlined
				role="button"
				style={{
					position: 'absolute',
					right: 15,
					top: 15,
					lineHeight: 1,
					color: 'inherit',
				}}
				onClick={() => onClose()}
			/>
			<div style={{display: 'flex'}}>
				<FormItem label="Aggregazione">
					<Radio.Group
						value={state.context.aggregation}
						buttonStyle="solid"
						onChange={evt =>
							send('SELECT_AGGREGATION', {aggregation: evt.target.value})
						}
					>
						{Object.keys(AGGREGAZIONI).map(k => (
							<Radio.Button key={k} value={k}>
								{AGGREGAZIONI[k]}
							</Radio.Button>
						))}
					</Radio.Group>
				</FormItem>
				{state.context.aggregation && (
					<FormItem labelStyle={{width: 'auto', marginLeft: 20}} label="Periodo">
						<RangePicker
							locale={locale}
							picker={state.context.aggregation}
							value={state.context.period}
							onChange={period => send('SELECT_PERIOD', {period})}
						/>
					</FormItem>
				)}
			</div>
			<Tabs
				activeKey={state.context.tab}
				onChange={tab => {
					console.log(tab)
					send('SELECT_TAB', {tab})
				}}
			>
				<Tabs.TabPane key="convention" tab="Per Convenzione">
					<AnalysisByConventionUI
						aggregation={state.context.aggregation}
						period={state.context.period}
						initialState={initialState}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane key="pod" tab="Per POD">
					<AnalysisByPodUI
						aggregation={state.context.aggregation}
						period={state.context.period}
						initialState={initialState}
					/>
				</Tabs.TabPane>
			</Tabs>
		</Card>
	)
}
