import React, {useState, useEffect} from 'react'
import {Menu, Layout} from 'antd'
import AntdIcon from '@ant-design/icons'
import {Link, withRouter} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faIndustry,
	faCity,
	faWrench,
	faClipboardCheck,
	faSearch,
	faChartLine,
	faTh,
	faSun
} from '@fortawesome/free-solid-svg-icons'

import {faLightbulb} from '@fortawesome/fontawesome-free-regular'

import {dispatchWindowResize} from '../utils'
import {SIDEBAR_WIDTH} from '../styles'
import {useAuth} from '../lib/auth'

const {SubMenu, Item} = Menu
const {Sider} = Layout

const getKeysFromPathname = pathname => {
	const keys = pathname.split('/').slice(1)
	return (keys[0] === 'edifici-e-impianti' ? keys.slice(1) : keys).reduce(
		(acc, next, i) => [...acc, acc[acc.length - 1] ? acc[acc.length - 1] + '/' + next : next],
		[]
	)
}

const menuItems = [
	{icon: faTh, link: '/dashboard', key: 'dashboard', label: 'Dashboard'},
	{
		icon: faLightbulb,
		link: '/struttura/pl',
		key: 'struttura',
		label: 'Illuminazione Pubblica',
		subitems: [
			{icon: 'mail', link: '/struttura/pl', key: 'struttura/pl', label: 'Punti Luce'},
			{
				icon: 'mail',
				link: '/struttura/centraline',
				key: 'struttura/centraline',
				label: 'Centraline',
			},
			{
				icon: 'mail',
				link: '/struttura/quadri',
				key: 'struttura/quadri',
				label: 'Quadri di Sezionamento',
			},
			{
				icon: 'mail',
				link: '/struttura/linee',
				key: 'struttura/linee',
				label: 'Linee di Alimentazione',
			},
		],
	},
	{icon: faCity, link: '/edifici-e-impianti/edifici', key: 'edifici', label: 'Edifici'},
	{icon: faIndustry, link: '/edifici-e-impianti/impianti', key: 'impianti', label: 'Impianti'},
	{
		icon: faWrench,
		link: '/manutenzioni/pl',
		key: 'manutenzioni',
		label: 'Manutenzioni',
		requiresCapability: 'MANUTENTORE',
		subitems: [
			{icon: 'mail', link: '/manutenzioni/pl', key: 'manutenzioni/pl', label: 'Punti Luce'},
			{
				icon: 'mail',
				link: '/manutenzioni/centraline',
				key: 'manutenzioni/centraline',
				label: 'Centraline',
			},
			{
				icon: 'mail',
				link: '/manutenzioni/quadri',
				key: 'manutenzioni/quadri',
				label: 'Quadri di Sezionamento',
			},
			{
				icon: 'mail',
				link: '/manutenzioni/linee',
				key: 'manutenzioni/linee',
				label: 'Linee di Alimentazione',
			},
			{
				icon: 'mail',
				link: '/manutenzioni/edifici',
				key: 'manutenzioni/edifici',
				label: 'Edifici',
			},
			{
				icon: 'mail',
				link: '/manutenzioni/impianti',
				key: 'manutenzioni/impianti',
				label: 'Impianti',
			},
		],
	},
	{
		icon: faClipboardCheck,
		link: '/verifica-manutenzioni',
		key: 'verifica-manutenzioni',
		label: 'Conferma Manutenzioni',
		requiresCapability: 'SUPERVISORE',
	},
	{
		icon: faSearch,
		link: '/interrogazioni/pl',
		key: 'interrogazioni',
		label: 'Interrogazioni',
		subitems: [
			{
				icon: 'mail',
				link: '/interrogazioni/pl',
				key: 'interrogazioni/pl',
				label: 'Punti Luce',
			},
			{
				icon: 'mail',
				link: '/interrogazioni/manutenzioni',
				key: 'interrogazioni/manutenzioni',
				label: 'Manutenzioni',
			},
		],
	},
	// {
	// 	icon: faChartLine,
	// 	link: '/consumi/bollette',
	// 	key: 'consumi',
	// 	label: 'Bollettazione e Consumi',
	// 	requiresCapability: 'AMMINISTRATIVO',
	// 	subitems: [
	// 		{
	// 			icon: 'mail',
	// 			link: '/consumi/bollette',
	// 			key: 'consumi/bollette',
	// 			label: 'Inserimento Nuove Bollette',
	// 		},
	// 		{
	// 			icon: 'mail',
	// 			link: '/consumi/analisi',
	// 			key: 'consumi/analisi',
	// 			label: 'Analisi Consumi',
	// 		},
	// 	],
	// },
	{
		icon: faChartLine,
		link: '/contabilita-energetica/inserimento-bollette',
		key: 'contabilita-energetica',
		label: 'Contabilità Energetica',
		requiresCapability: 'AMMINISTRATIVO',
		subitems: [
			{
				link: '/contabilita-energetica/inserimento-bollette',
				key: 'contabilita-energetica/inserimento-bollette',
				label: 'Inserimento Nuove Bollette',
			},
			{
				link: '/contabilita-energetica/analisi-consumi',
				key: 'contabilita-energetica/analisi-consumi',
				label: 'Analisi Consumi',
			},
			{
				link: '/contabilita-energetica/produzione',
				key: 'contabilita-energetica/produzione',
				label: 'Analisi Produzione',
			},
		],
	},
	// {
	// 	icon: faSun,
	// 	link: '/fotovoltaico',
	// 	key: 'fotovoltaico',
	// 	label: 'Contabilità Fotovoltaico',
	// 	requiresCapability: 'SUPERVISORE',
	// },
]

const Icon = ({icon, provider = 'fa', ...props}) => {
	switch (provider) {
		case 'fa':
			return (
				<i className="anticon">
					<FontAwesomeIcon
						icon={icon}
						// size="sm"
						// fixedWidth
						{...props}
						style={{
							width: 14,
							verticalAlign: 'baseline',
							display: 'inline-block',
							lineHeight: 1,
							// font-style: normal,
							// text-align: center,
							// text-transform: none,
							// text-rendering: optimizeLegibility,
							// -webkit-font-smoothing: antialiased,
						}}
					/>
				</i>
			)
		case 'antd':
		default:
			return <AntdIcon type={icon} {...props} />
	}
}

export default withRouter(({location: {pathname}}) => {
	const {userCan, userCanAny} = useAuth()
	const [collapsed, setCollapsed] = useState(false)

	useEffect(() => {
		dispatchWindowResize({delay: 200})
	}, [collapsed])

	return (
		<Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} width={SIDEBAR_WIDTH}>
			<Menu style={{height: '100%'}} selectedKeys={getKeysFromPathname(pathname)}>
				{menuItems.map(
					(
						{provider, icon, link, key, label, subitems, requiresCapability = null},
						idx
					) => {
						if (requiresCapability && !userCan(requiresCapability)) return null
						return subitems && subitems.length ? (
							<SubMenu
								key={key}
								title={
									<span>
										<Icon icon={icon} provider={provider} />
										<span>{label}</span>
									</span>
								}
							>
								{subitems.map(({icon, link, key, label}, idx2) => (
									<Item key={key}>
										<Link to={link}>{label}</Link>
									</Item>
								))}
							</SubMenu>
						) : (
							<Item key={key}>
								<Link to={link}>
									<span>
										<Icon icon={icon} provider={provider} />
										<span>{label}</span>
									</span>
								</Link>
							</Item>
						)
					}
				)}
			</Menu>
		</Sider>
	)
})
