import {
  Style,
  Circle,
  Stroke,
  Fill,
  RegularShape,
} from 'ol/style'

const stylesMapRaw = {
  "Non Classificato": {
    shape: "circle",
    fill: "#000000",
    stroke: "#000000",
    size: 3.6
  },
  "ALO20": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#000000",
    size: 6.6
  },
  "ALO50": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.1
  },
  "ALO70": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.7
  },
  "ALO100": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#000000",
    size: 9.6
  },
  "FL8": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 5.1
  },
  "FL9": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 5.4
  },
  "FL10": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 5.55
  },
  "FL11": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 5.7
  },
  "FL14": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 6.0
  },
  "FL18": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 6.6
  },
  "FL20": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 7.2
  },
  "FL23": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 7.5
  },
  "FL24": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 7.8
  },
  "FL26": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.1
  },
  "FL36": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.4
  },
  "FL40": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.7
  },
  "FL42": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 8.85
  },
  "FL48": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 9.0
  },
  "FL55": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 9.3
  },
  "FL80": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 9.6
  },
  "FL125": {
    textSymbolizer: "ttf://STIXGeneral#U+25C6",
    fill: "#ffffff",
    stroke: "#000000",
    size: 10.2
  },
  "HG50": {
    shape: "circle",
    fill: "#8b7cff",
    stroke: "#5555ff",
    size: 6.0
  },
  "HG80": {
    shape: "circle",
    fill: "#9696e1",
    stroke: "#5555ff",
    size: 7.5
  },
  "HG125": {
    shape: "circle",
    fill: "#8282ff",
    stroke: "#5555ff",
    size: 9.0
  },
  "HG250": {
    shape: "circle",
    fill: "#6ebef3",
    stroke: "#5555ff",
    size: 10.2
  },
  "HGM160": {
    shape: "circle",
    fill: "#d8ffee",
    stroke: "#000000",
    size: 9.6
  },
  "INC20": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#0bfed6",
    size: 6.6
  },
  "INC40": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#0bfed6",
    size: 7.2
  },
  "INC45": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#0bfed6",
    size: 7.8
  },
  "INC60": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#0bfed6",
    size: 8.4
  },
  "INC100": {
    shape: "circle",
    fill: "#ffffff",
    stroke: "#0bfed6",
    size: 9.0
  },
  "LED0.4": {
    shape: "circle",
    fill: "#00ff6a",
    stroke: "#00aa7f",
    size: 3.0
  },
  "LED1": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 3.6
  },
  "LED2.5": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 4.5
  },
  "LED3": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 4.8
  },
  "LED6": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 5.1
  },
  "LED8": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 5.4
  },
  "LED12": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 5.55
  },
  "LED14": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 6.0
  },
  "LED16": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 6.6
  },
  "LED25": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 7.2
  },
  "LED32": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 7.8
  },
  "LED35": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 8.4
  },
  "LED40": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 9.0
  },
  "LED48": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 9.3
  },
  "LED55": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 9.9
  },
  "LED64": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 10.5
  },
  "LED72": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 11.4
  },
  "LED130": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 12.6
  },
  "LED246": {
    textSymbolizer: "ttf://STIXGeneral#U+2B1F",
    fill: "#66cd00",
    stroke: "#00aa00",
    size: 13.5
  },
  "MH20": {
    shape: "circle",
    fill: "#e6dcdc",
    stroke: "#ff55ff",
    size: 4.5
  },
  "MH35": {
    shape: "circle",
    fill: "#fdd6d6",
    stroke: "#ff55ff",
    size: 5.4
  },
  "MH45": {
    shape: "circle",
    fill: "#fde3e3",
    stroke: "#ff55ff",
    size: 6.0
  },
  "MH70": {
    shape: "circle",
    fill: "#ffe7e0",
    stroke: "#ff55ff",
    size: 7.5
  },
  "MH90": {
    shape: "circle",
    fill: "#ffe2d9",
    stroke: "#ff55ff",
    size: 7.95
  },
  "MH100": {
    shape: "circle",
    fill: "#ffe2d9",
    stroke: "#ff55ff",
    size: 8.4
  },
  "MH140": {
    shape: "circle",
    fill: "#ffdbd7",
    stroke: "#ff55ff",
    size: 9.0
  },
  "MH150": {
    shape: "circle",
    fill: "#f6e1e8",
    stroke: "#ff55ff",
    size: 8.4
  },
  "MH250": {
    shape: "circle",
    fill: "#f6e1e8",
    stroke: "#ff55ff",
    size: 10.5
  },
  "MH400": {
    shape: "circle",
    fill: "#f6e1e8",
    stroke: "#ff55ff",
    size: 11.4
  },
  "SAP40": {
    shape: "circle",
    fill: "#ff0000",
    stroke: "#aa0000",
    size: 5.4
  },
  "SAP50": {
    shape: "circle",
    fill: "#ff0000",
    stroke: "#aa0000",
    size: 6.0
  },
  "SAP64": {
    shape: "circle",
    fill: "#ff7300",
    stroke: "#aa5500",
    size: 7.2
  },
  "SAP70": {
    shape: "circle",
    fill: "#ff7300",
    stroke: "#aa5500",
    size: 7.5
  },
  "SAP100": {
    shape: "circle",
    fill: "#ffaa00",
    stroke: "#aa5500",
    size: 8.4
  },
  "SAP110": {
    shape: "circle",
    fill: "#ffaa00",
    stroke: "#aa5500",
    size: 8.7
  },
  "SAP150": {
    shape: "circle",
    fill: "#ffd500",
    stroke: "#ffaa00",
    size: 9.6
  },
  "SAP250": {
    shape: "circle",
    fill: "#eaff00",
    stroke: "#ffaa00",
    size: 10.5
  },
  "SAP400": {
    shape: "circle",
    fill: "#bbff00",
    stroke: "#ffaa00",
    size: 12.0
  },
  "SAP600": {
    shape: "circle",
    fill: "#9dff00",
    stroke: "#00d5ff",
    size: 15.0
  },
  "SAP1000": {
    shape: "circle",
    fill: "#00ff80",
    stroke: "#000000",
    size: 18.0
  },
  "SOX35": {
    shape: "circle",
    fill: "#f400a7",
    stroke: "#d7008c",
    size: 6.0
  },
  "SOX36": {
    shape: "circle",
    fill: "#f400a7",
    stroke: "#d7008c",
    size: 6.6
  },
  "SOX55": {
    shape: "circle",
    fill: "#f400a7",
    stroke: "#d7008c",
    size: 7.5
  },
  "SOX66": {
    shape: "circle",
    fill: "#f400a7",
    stroke: "#d7008c",
    size: 9.0
  },
  "SOX90": {
    shape: "circle",
    fill: "#f400a7",
    stroke: "#d7008c",
    size: 10.2
  }
}

const stylesMap = Object.keys(stylesMapRaw)
  .map(k => {
    const styleDef = stylesMapRaw[k]
    const stroke = new Stroke({
      color: styleDef.stroke,
      width: 1,
    })
    const fill = new Fill({
      color: styleDef.fill,
    })
    const radius = styleDef.size / 1.8
    let image
    switch (styleDef.textSymbolizer) {
      case "ttf://STIXGeneral#U+2B1F":
        image = new RegularShape({
          points: 5,
          stroke,
          fill,
          radius,
        })
        break
      case "ttf://STIXGeneral#U+25C6":
        image = new RegularShape({
          points: 4,
          stroke,
          fill,
          radius,
        })
        break
      default:
        image = new Circle({
          stroke,
          fill,
          radius,
        })
    }
    return {
      key: k,
      style: new Style({ image })
    }
  })
  .reduce((acc, next) => ({ ...acc, [next.key]: next.style }), {})

export default (feature) => {
  const props = feature.getProperties()
  return stylesMap[props.lampot] || stylesMap['Non Classificato']
}