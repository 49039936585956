import React from 'react'
import withAnimationFrame from 'react-animation-frame'
import styled from 'styled-components'
import { PADDING } from '../styles';

const SizeProvider = withAnimationFrame(
	class extends React.Component {
		state = {
			width: 100,
			height: 100,
		}
		onAnimationFrame() {
			if (!this.el) return
			if (this.state.width !== this.el.clientWidth || this.state.height !== this.el.clientHeight)
				this.setState({
					width: this.el.clientWidth,
					height: this.el.clientHeight,
				})
		}
		render() {
			//console.log("size provider render");
			return (
				<div style={this.props.style} ref={el => (this.el = el)}>
					{this.props.children(this.state)}
				</div>
			)
		}
	}
	//1000
)

const Spacer = styled.div`
	display: flex;
	> *:not(last-child) {
		margin-right: ${({gap}) => (gap || PADDING) + 'px'};
	}
`

export {SizeProvider, Spacer}
