import React, {useState, useEffect, useRef} from 'react'
import {Tabs, Button, Input, message} from 'antd'
import _ from 'lodash'
import Box from 'ui-box'
import axios from 'axios'
import {saveAs} from 'file-saver'

import gridConfigs from '../../../configs/grids'
import {loadFeatures} from '../../../services/data'
import Map from './map'
import {
	SplitPane,
	SplitPaneContent,
	googleLikeFilter,
	useData,
	useDebouncedFilter,
} from '../../../utils'
import Grid from '../../../components/Grid'
import {UIMode, modes} from '../utils'
import * as styles from '../../../styles'
import {useAuth} from '../../../lib/auth'
import {PROPS_LABELS, PROPS_WHITELISTS} from '../../../constants'

const {TabPane} = Tabs

const ColumnGroupTabs = ({columnGroups, activeIndex, onChange}) => (
	<Tabs
		onChange={onChange}
		type="card"
		activeKey={String(activeIndex)}
		style={{marginBottom: -17}}
	>
		{columnGroups.map(({label}, idx) => (
			<TabPane tab={label} key={idx} />
		))}
	</Tabs>
)

const getCurrentColumns = (featureType, mode, columnGroupIdx) => {
	const {columnGroups, columns} = gridConfigs[featureType]
	const currentColumnGroup = columnGroups[columnGroupIdx]
	return columns['*']
		.filter(
			col =>
				currentColumnGroup.keys.includes(col.key) ||
				currentColumnGroup.keys.includes(col._key)
		)
		.map(col => {
			if (!columns[mode]) return col
			const replacement = columns[mode].find(item => item.key === col.key)
			return replacement || col
		})
		.map(col => ({...col, resizable: true}))
}

const Grids = ({
	currentMode,
	filteredFeatures,
	keyProp,
	setGridSelectedItems,
	highlightedSelectionFeatures,
}) => {
	const [currentColumnGroupIdx, setCurrentColumnGroupIdx] = useState(0)
	const {columnGroups, defaultSortOn} = gridConfigs.pl
	const currentColumns = getCurrentColumns('pl', currentMode, currentColumnGroupIdx)
	const style = {flex: 1, position: 'relative', borderBottom: styles.BORDERS}
	return (
		<>
			<ColumnGroupTabs
				style={{height: 30}}
				columnGroups={columnGroups}
				onChange={setCurrentColumnGroupIdx}
				activeIndex={currentColumnGroupIdx}
			/>

			{currentMode === UIMode.SELECT ? (
				<Grid
					style={style}
					columns={currentColumns}
					data={filteredFeatures}
					minColumnWidth={120}
					defaultSortOn={defaultSortOn}
					rowKey={keyProp}
					onSelectionChange={setGridSelectedItems}
					selectionFrozen={currentMode === UIMode.MODIFY}
				/>
			) : (
				<Grid
					style={style}
					columns={currentColumns}
					data={highlightedSelectionFeatures}
					minColumnWidth={120}
					defaultSortOn={defaultSortOn}
					rowKey={keyProp}
					onSelectionChange={setGridSelectedItems}
					selectionFrozen={currentMode === UIMode.MODIFY}
					groupBy={['query']}
				/>
			)}
		</>
	)
}

const InterrogazioniPuntiLuce = () => {
	const keyProp = 'plid'
	const {user} = useAuth()
	const {codistat, center} = user
	const mapRef = useRef()

	const [currentMode, setCurrentMode] = useState(modes[0])

	const {data} = useData(() => loadFeatures('pl'), {unwrapper: res => res})
	const [gridSelectedItems, setGridSelectedItems] = useState([])
	const {filter, debouncedFilter, setFilter} = useDebouncedFilter()
	const [filteredFeatures, setFilteredFeatures] = useState([])
	const [selection, setSelection] = useState([])

	useEffect(() => {
		const filteredFeatures = (data || [])
			.map(f => f.properties)
			.filter(f => googleLikeFilter(debouncedFilter)(f))
		setFilteredFeatures(filteredFeatures)
	}, [debouncedFilter, data])

	function addCurrentFilterToSelection() {
		const currentFilter = {
			filter,
			filteredFeatures,
		}
		setSelection([...selection, currentFilter])
		setFilter('')
	}

	function clearSelection() {
		setSelection([])
	}

	function exportSelection() {
		const data = _.flatten(
			selection.map(({filter, filteredFeatures}) =>
				filteredFeatures.map(feature => {
					const desiredValues = _.pick(feature, PROPS_WHITELISTS.pl)
					const finalObject = {query: filter}
					_.keys(desiredValues).forEach(prop => {
						finalObject[PROPS_LABELS.pl[prop] || prop] = desiredValues[prop]
					})
					finalObject['Centralina'] = feature._centralina || 'NON TROVATA'
					return finalObject
				})
			)
		)
		axios
			.post('/api/utils/export', data, {responseType: 'blob'})
			.then(({data}) => {
				saveAs(data, 'export.xls', {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			})
			.catch(err => {
				message.error(`Errore nell'esportazione in Excel: ${err.message}`)
			})
	}

	const renderButtons = () => {
		const Wrapper = ({children}) => (
			<Box padding={styles.PADDING} paddingTop={0}>
				{children}
			</Box>
		)
		switch (currentMode) {
			case UIMode.SELECT:
				return (
					<Wrapper>
						<Button
							disabled={!filter}
							onClick={() => addCurrentFilterToSelection()}
							style={{marginRight: styles.PADDING}}
						>
							Aggiungi a Selezione
						</Button>
						<Button
							disabled={!selection.length}
							onClick={() => setCurrentMode(UIMode.VIEW_SELECTION)}
							style={{marginRight: styles.PADDING}}
						>
							Visualizza Selezione
						</Button>
						<Button disabled={!selection.length} onClick={() => clearSelection()}>
							Vuota Selezione
						</Button>
					</Wrapper>
				)
			case UIMode.VIEW_SELECTION:
				return (
					<Wrapper>
						<Button
							onClick={() => setCurrentMode(UIMode.SELECT)}
							style={{marginRight: styles.PADDING}}
						>
							Indietro
						</Button>
						<Button onClick={() => exportSelection()}>Esporta</Button>
					</Wrapper>
				)
			default:
				return null
		}
	}

	const highlightedSelectionFeatures = _.flatten(
		selection.map(group => group.filteredFeatures.map(feat => ({...feat, query: group.filter})))
	)

	const highlightedSelection = _.uniq(
		_.flatten(selection.map(group => group.filteredFeatures.map(feat => feat[keyProp])))
	)

	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flex: 1,
				position: 'relative', // THIS SEEMS REQUIRED
			}}
		>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent>
					<Map
						codistat={codistat}
						center={center}
						externalSelection={gridSelectedItems}
						highlightedSelection={highlightedSelection}
						displayHighlightedSelection={currentMode === UIMode.VIEW_SELECTION}
						ref={mapRef}
					/>
				</SplitPaneContent>
				<SplitPaneContent style={{display: 'flex', flexDirection: 'column'}}>
					{currentMode === UIMode.SELECT ? (
						<Box padding={styles.PADDING}>
							<Input
								placeholder="Filtro"
								onChange={e => setFilter(e.target.value)}
								value={filter}
								allowClear
							/>
						</Box>
					) : null}

					<Grids
						currentMode={currentMode}
						filteredFeatures={filteredFeatures}
						keyProp={keyProp}
						setGridSelectedItems={setGridSelectedItems}
						highlightedSelectionFeatures={highlightedSelectionFeatures}
					/>
					<Box padding={styles.PADDING}>
						{selection.length === 0
							? 'Non ci sono query nella Selezione'
							: selection.length === 1
							? `C'è 1 query nella Selezione`
							: `Ci sono ${selection.length} query nella Selezione`}
					</Box>
					<div>{renderButtons()}</div>
				</SplitPaneContent>
			</SplitPane>
		</div>
	)
}

export default InterrogazioniPuntiLuce
