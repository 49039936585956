export const enrichManutenzione = m => {
	const chi = m.operatore + ' (' + m.attrezzatura + ')'
	const descrizione_estesa = m.descrizione + ' (' + m.tipo_intervento + ')'
	let identificativo_elemento = ''
	switch (m.tabella_elemento) {
		case 'ip_pl':
			identificativo_elemento =
				m.elemento.corpo_tipo + ' ' + m.elemento.lamp_tipo + ' ' + m.elemento.pl_tipo
			break
		case 'ip_linee':
			identificativo_elemento =
				m.elemento.mono_bi_trifase + ' ' + m.elemento.sigla_cavo + ' ' + m.elemento.posa_linea
			break
		case 'imp':
			if (m.elemento.imp_tipo == 'centr_') {
				identificativo_elemento = [m.elemento.indir, m.elemento.p_ele, m.elemento.d4_reg]
					.filter(Boolean)
					.join(' ')
			} else {
				identificativo_elemento = [m.elemento.pod_pdr_cont, m.elemento.indir]
					.filter(Boolean)
					.join(' ')
			}
			break
		case 'ip_q_sez':
			identificativo_elemento = [m.elemento.topnonimo, m.elemento.descrizione]
				.filter(Boolean)
				.join(' ')
			break
		case 'edifici':
			break
	}
	return {
		chi,
		descrizione_estesa,
		identificativo_elemento,
	}
}
