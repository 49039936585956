export enum TIPI {
	punto_luce = 'Punto Luce',
	linea = 'Linea',
	quadro = 'Sottoquadro',
	centralina = 'Centralina',
	impianto = 'Impianto',
	edificio = 'Edificio',
}

export const PROPS_WHITELISTS = {
	pl: [
		// 'fid',
		' plid',
		'pl_cod',
		'id_imp',
		'pl_tipo',
		'alim_tipo',
		'alim_linea_cod',
		'lamp_tipo',
		'lamp_pot',
		'lamp_pot_ass',
		'corpo_effic_dlor',
		'lampot',
		'lamp_nr',
		'corpo_tipo',
		'eff_illumin',
		'corpi_nr',
		'corpo_inclin',
		'sbraccio_lung',
		'coppa_tipo',
		'coppa_conserv',
		'sostegno_tipo',
		'sost_materiale',
		'sost_conserv',
		'altezza_pl',
		'interdistanza',
		'arretram_ciglio',
		'mors_pot_posiz',
		'isol_classe',
		'alimentat_tipo',
		'telecontrollo',
		'regolaz_cod',
		'conforme_leggi',
		'data_impianto',
		'note',
		'riferimenti',
		'data_attvaz',
		'data_eliminaz',
		'codistat',
		'localita',
		'toponimo',
		'the_geom',
		'_id',
		'inserimento_completo',
		'user_id',
		'data_modifica',
	],
	centraline: [
		'id_imp',
		'pod_pdr_cont',
		'presa_al',
		'uten',
		'indir',
		'loc',
		'cons_prod',
		'vett',
		'um',
		'c_anno',
		'p_anno',
		'p_term',
		'p_ele',
		'p_ut',
		'p_frig',
		'd1_flu',
		'd2_pom',
		'd3_crep',
		'd4_reg',
		'd5_mo',
		'desc6',
		'n1_inom',
		'num2',
		'n3_kva',
		'appart',
		'note',
		'anno',
		'codistat',
		'the_geom',
		'd_attiv',
		'd_elim',
		'imp_tipo',
		'_id',
		'user_id',
		'inserimento_completo',
		'data_modifica',
	],
	quadri: [
		'id_q_sez',
		'id_imp',
		'centr_sez_cod',
		'descrizione',
		'corr_nom_amp',
		'protez_tipo',
		'note',
		'toponimo',
		'codistat',
		'the_geom',
		'data_attvaz',
		'data_eliminaz',
		'_id',
		'user_id',
		'inserimento_completo',
		'data_modifica',
	],
	linee: [
		'id_linea',
		'linea_cod',
		'id_imp',
		'posa_linea',
		'lunghezza',
		'mono_bi_trifase',
		'sez_fase_mmq',
		'sez_compless_mmq',
		'sigla_cavo',
		'codistat',
		'localita',
		'toponimo',
		'data_attivaz',
		'data_eliminaz',
		'note',
		'the_geom',
		'_id',
		'user_id',
		'data_modifica',
		'inserimento_completo',
	],
	impianti: [
		'id_imp',
		'pod_pdr_cont',
		'presa_al',
		'uten',
		'indir',
		'loc',
		'cons_prod',
		'vett',
		'um',
		'c_anno',
		'p_anno',
		'p_term',
		'p_ele',
		'p_ut',
		'p_frig',
		'd1_flu',
		'd2_pom',
		'd3_crep',
		'd4_reg',
		'd5_mo',
		'desc6',
		'n1_inom',
		'num2',
		'n3_kva',
		'appart',
		'note',
		'anno',
		'codistat',
		'the_geom',
		'd_attiv',
		'd_elim',
		'imp_tipo',
		'_id',
		'user_id',
		'inserimento_completo',
		'data_modifica',
	],
	edifici: [
		'idf',
		'f_pos',
		'edif_descr',
		'sup_mq',
		'vol_mc',
		'classe_energ',
		'anno_costr',
		'utilizzo_h_anno',
		'nr_utenti',
		'note',
		'data_attvaz',
		'data_eliminaz',
		'localita',
		'comune',
		'indirizzo',
		'codistat',
		'flag_1',
		'flag_2',
		'the_geom',
		'appartenenza',
		'_id',
		'user_id',
		'data_modifica',
	],
	manutenzioni: [
		'id',
		'id_elemento',
		'tabella_elemento',
		'id_utente',
		'operatore',
		'tipo_intervento',
		'data_fine',
		'descrizione',
		'attrezzatura',
		'note',
		'confermata',
		'created_at',
		'tipo_elemento',
		// 'deleted_by',
		// 'updated_at',
		// 'deleted_at'
	],
}

export const PROPS_LABELS = {
	pl: {
		pl_cod: 'Codice',
		pl_tipo: 'Tipo Punto Luce',
		id_imp: 'Centralina',
		alim_tipo: 'Tipo Alimentazione',
		alim_linea_cod: 'Codice Linea',
		lamp_tipo: 'Tipo Lampada',
		lamp_pot: 'Potenza Lampada',
		lamp_pot_ass: 'Potenza Punto Luce',
		eff_illumin: 'Efficienza Illuminotecnica',
		lampot: 'Sigla',
		lamp_nr: 'Numero Lampade',
		corpo_tipo: 'Tipo Corpo',
		corpi_nr: 'Numero Corpi',
		corpo_inclin: 'Inclinato',
		sbraccio_lung: 'Lunghezza Sbraccio',
		coppa_tipo: 'Tipo Coppa',
		coppa_conserv: 'Stato Conservazione Coppa',
		sostegno_tipo: 'Tipo Sostegno',
		sost_materiale: 'Materiale Sostegno',
		sost_conserv: 'Stato Conservazione Sostegno',
		altezza_pl: 'Altezza',
		interdistanza: 'Interdistanza',
		arretram_ciglio: 'Arretramento Ciglio',
		mors_pot_posiz: 'Posizione Morsettiera',
		isol_classe: 'Classe Isolamento',
		alimentat_tipo: 'Tipo Alimentatore',
		telecontrollo: 'Telecontrollo',
		conforme_leggi: 'Conforme Leggi',
		data_impianto: 'Data Impianto',
		note: 'Note',
		riferimenti: 'Riferimenti',
		localita: 'Località',
		toponimo: 'Luogo',
		regolaz_cod: 'Codice Riduzione',
	},
	manutenzioni: {
		query: 'query',

		tipo_elemento_label: 'Tipo Elemento',
		codice: 'Codice Elemento',
		// centralina: "Centralina",
		identificativo_elemento: 'Descrizione Elemento',

		descrizione: 'Descrizione Intervento',
		tipo_intervento: 'Tipo Intervento',
		operatore: 'Operatore',
		attrezzatura: 'Attrezzatura',
		data_fine: 'Data Conclusione',

		note: 'Note',

		data_registrazione: 'Data Registrazione',
		confermata: 'Confermata',
	},
}
