import React, {useState, useCallback, useEffect} from 'react'
import {Button} from 'antd'
import uuid from 'uuid'

import Panel from './Panel'
import {PADDING} from '../../styles'
import {Space} from '../../components/layout'

const createPanel = () => ({key: uuid()})

const AnalisiConsumi = () => {
	const [panels, setPanels] = useState([createPanel()])
	useEffect(() => {
		console.log('AnalisiConsumi ADD')
	}, [])
	const addPanel = useCallback(() => setPanels([...panels, createPanel()]), [panels])
	const removePanel = useCallback(
		key => {
			console.log('removePanel', key)
			setPanels(panels.filter(p => p.key !== key))
		},
		[panels]
	)
	const removeAllPanels = useCallback(() => setPanels([]), [panels])
	return (
		<div style={{height: '100%', padding: PADDING, overflow: 'auto'}}>
			<div style={{display: 'flex', alignItems: 'center'}}>
				<h1 style={{marginBottom: 2}}>Analisi Consumi</h1>
				<Button style={{marginLeft: 10}} onClick={() => addPanel()}>
					Nuovo pannello
				</Button>
				<Button style={{marginLeft: 10}} onClick={() => removeAllPanels()} danger>
					Chiudi tutti
				</Button>
			</div>
			<Space direction="vertical" style={{width: '100%', marginTop: 10}}>
				{panels.map((panel, i) => (
					<Panel key={panel.key} remove={() => removePanel(panel.key)} state={panel} />
				))}
			</Space>
		</div>
	)
}

export default AnalisiConsumi
