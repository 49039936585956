import React from 'react'
import {Col, Row} from '../utils'
import {Form as AntForm} from 'antd'

import {PartenzeModalButton} from './partenze'

export default ({formItem, values: {id_imp}}) => {
	return {
		centralina: (
			<>
				<Row>
					<Col>{formItem({label: 'Codice', name: 'uten', required: true})}</Col>
					<Col>{formItem({label: 'Indirizzo', name: 'indir', required: true})}</Col>
				</Row>

				<Row>
					<Col>{formItem({label: 'Località', name: 'loc'})}</Col>
					<Col>{formItem({label: 'POD', name: 'pod_pdr_cont'})}</Col>
				</Row>

				<Row>
					<Col>{formItem({label: 'Altro Identificativo', name: 'presa_al'})}</Col>
					<Col>{formItem({label: 'Regolatore', name: 'd4_reg'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({label: 'kVA', name: 'n3_kva'})}</Col>
					<Col>
						<AntForm.Item label="Partenze">
							<PartenzeModalButton id_imp={id_imp} />
						</AntForm.Item>
					</Col>
				</Row>
			</>
		),
		dati_tecnici: (
			<>
				<Row>
					<Col>{formItem({label: 'Codice', name: 'uten', required: true})}</Col>
					<Col>{formItem({label: 'Alimentazione', name: 'vett'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({label: 'Numero Vani', name: 'p_frig', type: 'number'})}</Col>
					<Col>
						{formItem({label: 'Potenza Installata kW', name: 'p_ele', type: 'number'})}
					</Col>
				</Row>
				<Row>
					<Col>
						{formItem({label: 'Consumo (kWh/anno)', name: 'c_anno', type: 'integer'})}
					</Col>
					<Col>
						{formItem({label: 'Corrente Nominale A', name: 'n1_inom', type: 'number'})}
					</Col>
				</Row>
				<Row>
					<Col>
						{formItem({label: 'Protezione Generale e Scaricatori', name: 'desc6'})}
					</Col>
				</Row>
			</>
		),
		ausiliari: (
			<>
				<Row>
					<Col>{formItem({label: 'Codice', name: 'uten', required: true})}</Col>
					<Col>{formItem({label: 'Differenziale', name: 'd1_flu'})}</Col>
				</Row>
				<Row>
					<Col>
						{formItem({label: 'Differenziale mA', name: 'p_anno', type: 'number'})}
					</Col>
					<Col>{formItem({label: 'Timer', name: 'd2_pom'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({label: 'Crepuscolare', name: 'd3_crep'})}</Col>
					<Col>{formItem({label: 'Telecontrollo', name: 'p_ut', type: 'number'})}</Col>
				</Row>
			</>
		),
		altri_dati: (
			<>
				<Row>
					<Col>{formItem({label: 'Codice', name: 'uten', required: true})}</Col>
					<Col>{formItem({label: 'Costruttore Centralina', name: 'd5_mo'})}</Col>
				</Row>
				<Row>
					<Col>{formItem({label: 'Anno', name: 'anno'})}</Col>
				</Row>
				<Row>
					<Col span={24}>{formItem({label: 'Note', name: 'note'})}</Col>
				</Row>
			</>
		),
	}
}
