import React from 'react';
import { Select } from 'antd';
import { TIPOLOGIE } from '../../../../configs/grids/impianti';
import { useVettoriEnergetici } from '../../../../services/data';

export const TipoImpiantoSelect = props => (
	<Select {...props}>
		{TIPOLOGIE.map(({ label, value }) => (
			<Select.Option key={value} value={value}>
				{label}
			</Select.Option>
		))}
	</Select>
);

export const VettoreSelect = props => {
	const { vettori, error } = useVettoriEnergetici()
	return vettori ? (
		<Select {...props}>
			{vettori.map(({ id, vettore, um }) => (
				<Select.Option key={id} value={id}>
					{vettore} ({um})
				</Select.Option>
			))}
		</Select>
	) : error ? (
		<span style={{ color: "red" }}>Errore nel caricamento</span>
	) : null
}
