import PLInsertForm from './pl/insert';
import PLModifyForm from './pl/modify';
import LineeInsertForm from './linee/insert';
import LineeModifyForm from './linee/modify';
import QuadriInsertForm from './quadri/insert';
import QuadriModifyForm from './quadri/modify';
import CentralineInsertForm from './centraline/insert';
import CentralineModifyForm from './centraline/modify';
import EdificiInsertForm from './edifici/insert';
import EdificiModifyForm from './edifici/modify';
import ImpiantiInsertForm from './impianti/insert';
import ImpiantiModifyForm from './impianti/modify';

export default {
	pl: {
		insert: PLInsertForm,
		modify: PLModifyForm,
	},
	linee: {
		insert: LineeInsertForm,
		modify: LineeModifyForm,
	},
	quadri: {
		insert: QuadriInsertForm,
		modify: QuadriModifyForm,
	},
	centraline: {
		insert: CentralineInsertForm,
		modify: CentralineModifyForm,
	},
	edifici: {
		insert: EdificiInsertForm,
		modify: EdificiModifyForm,
	},
	impianti: {
		insert: ImpiantiInsertForm,
		modify: ImpiantiModifyForm,
	},
};
