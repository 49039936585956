import {TIPOLOGIE} from '../../../configs/grids/impianti'

export const primaryKey = 'id_imp'
export const defaultSortOn = 'uten'

const tipologieMap = TIPOLOGIE.reduce((acc, next) => ({...acc, [next.value]: next.label}), {})
const tipoFormatter = ({value}) => tipologieMap[value]

export const columns = {
	filter: [
		{
			key: 'imp_tipo',
			name: 'Tipo',
			formatter: tipoFormatter,
		},
		{
			key: 'indir',
			name: 'Indirizzo',
		},
		{
			key: 'uten',
			name: 'Utenza',
		},
	],
	selection: [
		{
			key: 'imp_tipo',
			name: 'Tipo',
			formatter: tipoFormatter,
		},
		{
			key: 'indir',
			name: 'Indirizzo',
		},
		{
			key: 'uten',
			name: 'Utenza',
		},
		{
			key: 'pod_pdr_cont',
			name: 'POD/PDR',
		},
	],
}
