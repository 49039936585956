export const primaryKey = 'idf';
export const defaultSortOn = 'pl_cod';

export const columnGroups = [
	{
		name: 'edificio',
		label: 'Edificio',
		keys: ['edif_descr', 'indirizzo', 'anno_costr', 'altra_denominazione'],
	},
	{
		name: 'dati_tecnici',
		label: 'Dati Tecnici',
		keys: [
			'appartenenza',
			'classe_energ',
			'nr_utenti',
			'utilizzo_h_anno',
			'sup_mq',
			'vol_mc',
			'note',
		],
	},
];

export const columns = {
	'*': [
		{
			key: 'edif_descr',
			name: 'Denominazione Ufficiale',
		},
		{
			key: 'anno_costr',
			name: 'Anno Costruzione',
		},
		{
			key: 'localita',
			name: 'Località',
		},
		{
			key: 'indirizzo',
			name: 'Indirizzo',
		},
		{
			key: 'altra_denominazione',
			name: 'Altra Denominazione',
		},
		{
			key: 'appartenenza',
			name: 'Appartenenza',
		},
		{
			key: 'classe_energ',
			name: 'Classe Energetica',
		},
		{
			key: 'nr_utenti',
			name: 'N° Utenti',
		},
		{
			key: 'utilizzo_h_anno',
			name: 'Utilizzo Ore Anno',
		},
		{
			key: 'sup_mq',
			name: 'Superficie (mq)',
		},
		{
			key: 'vol_mc',
			name: 'Volume (mc)',
		},
		{
			key: 'note',
			name: 'Note',
		},
	],
	insert: [
		{
			key: 'edif_descr',
			name: 'Denominazione Ufficiale',
		},
		{
			key: 'indirizzo',
			name: 'Indirizzo',
		},
		{
			key: 'anno_costr',
			name: 'Anno Costruzione',
		},
		{
			key: 'sup_mq',
			name: 'Superficie (mq)',
		},
		{
			key: 'altra_denominazione',
			name: 'Altra Denominazione',
		},
	],
	'complete-inserts': [
		{
			key: 'edif_descr',
			name: 'Denominazione Ufficiale',
		},
		{
			key: 'sup_mq',
			name: 'Superficie (mq)',
		},
		{
			key: 'vol_mc',
			name: 'Volume (mc)',
		},
		{
			key: 'classe_energ',
			name: 'Classe Energetica',
		},
		{
			key: 'anno_costr',
			name: 'Anno Costruzione',
		},
		{
			key: 'utilizzo_h_anno',
			name: 'Utilizzo Ore Anno',
		},
		{
			key: 'nr_utenti',
			name: 'N° Utenti',
		},
		{
			key: 'note',
			name: 'Note',
		},
		{
			key: 'localita',
			name: 'Località',
		},
		{
			key: 'indirizzo',
			name: 'Indirizzo',
		},
		{
			key: 'appartenenza',
			name: 'Appartenenza',
		},
		{
			key: 'altra_denominazione',
			name: 'Altra Denominazione',
		},
	],
};
