import React from 'react';
import { Col, Row, CentralinaSelect, createInsertForm } from '../utils';

export default createInsertForm({
	featureType: 'pl',
	form: ({ formItem }) => (
		<>
			<Row>
				<Col>{formItem({ label: 'Codice', name: 'pl_cod', required: true })}</Col>
				<Col>
					{formItem({
						label: 'Centralina',
						name: 'id_imp',
						required: true,
						Component: CentralinaSelect,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Indirizzo', name: 'toponimo', required: true })}</Col>
				<Col>{formItem({ label: 'Tipo Punto Luce', name: 'pl_tipo', required: true })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ label: 'Tipo Corpo', name: 'sostegno_tipo' })}</Col>
				<Col>{formItem({ label: 'Sigla Lampada', name: 'lampot', required: true })}</Col>
			</Row>
		</>
	),
});
