import React, {useEffect, useRef, forwardRef, useImperativeHandle, useMemo, useState} from 'react'
import _ from 'lodash'
import * as ol from 'ol'
import {Vector as VectorSource} from 'ol/source'
import {defaults as defaultInteractions, DragBox, Draw, Modify, Snap} from 'ol/interaction'
import {defaults as defaultControls} from 'ol/control'
import ZoomSlider from 'ol/control/ZoomSlider'

import {
	MapControlBar,
	LayerSwitcher,
	ViewByElectricalBoxControl,
	FullScreenControl,
	MoveSelectToggle,
} from '../common/map/new-controls'
import {
	mapSelectionStyle,
	externalSelectionStyle,
	modifyFeatureStyle,
	modifyFeatureHoverStyle,
} from '../common/map/styles'
import styles from '../../configs/layer-styles'
import {centerViewOnFeatures, createGeojsonAPILayer} from '../common/map/utils'
import {VectorLayerX} from '../common/map/custom-classes'
import {getDefaultLayersMap} from '../common/map/layers'

import '../common/map/style.css'

const SECONDARY_LAYER_OPACITY = 0.3

const LayerDescriptor = [
	{
		name: 'Linee',
		type: 'linee',
	},
	{
		name: 'Centraline',
		type: 'centraline',
	},
	{
		name: 'Punti Luce',
		type: 'pl',
	},
	{
		name: 'Quadri',
		type: 'quadri',
	},
	{
		name: 'Edifici',
		type: 'edifici',
	},
	{
		name: 'Impianti',
		type: 'impianti',
	},
]

function createIPSupporLayers(mainFeatureType) {
	return LayerDescriptor.filter(({type}) => ['linee', 'centraline', 'pl', 'quadri'].includes(type))
		.filter(({type}) => type !== mainFeatureType)
		.map(({name, type}) =>
			createGeojsonAPILayer(type, {
				name,
				style: styles[type],
				opacity: SECONDARY_LAYER_OPACITY,
			})
		)
}

function getLayers({codistat, mainFeatureType}) {
	const layersMap = getDefaultLayersMap(codistat)
	const baseLayers = Object.values(layersMap.baseLayers)
	const mainLayer = createGeojsonAPILayer(mainFeatureType, {
		name: LayerDescriptor.find(({type}) => type === mainFeatureType).name,
		style: styles[mainFeatureType],
		opacity: 1,
	})

	let supportLayers
	switch (mainFeatureType) {
		case 'pl':
		case 'linee':
		case 'quadri':
		case 'centraline':
			supportLayers = createIPSupporLayers(mainFeatureType)
			break
		case 'edifici':
		case 'impianti':
		default:
			supportLayers = []
			break
	}
	return {layersMap, baseLayers, mainLayer, supportLayers}
}

export default forwardRef(
	(
		{center, codistat, mainFeatureType, keyProp, currentMode, externalSelection, onSelectionChange},
		ref
	) => {
		const container = useRef()
		const [mapSelection, setMapSelection] = useState([])
		const {
			map,
			layersMap,
			mainLayer,
			baseLayers,
			supportLayers,
			mapSelectionSource,
			mapSelectionOverlay,
			externalSelectionSource,
			externalSelectionOverlay,
			modifyFeatureSource,
			modifyFeatureOverlay,
			dragBox,
			draw,
			modify,
			snap,
		} = useMemo(() => {
			const {layersMap, baseLayers, mainLayer, supportLayers} = getLayers({
				codistat,
				mainFeatureType,
			})
			const mapSelectionSource = new VectorSource()
			const mapSelectionOverlay = new VectorLayerX({
				source: mapSelectionSource,
				style: mapSelectionStyle,
			})
			const externalSelectionSource = new VectorSource()
			const externalSelectionOverlay = new VectorLayerX({
				source: externalSelectionSource,
				style: externalSelectionStyle,
			})
			const modifyFeatureSource = new VectorSource()
			const modifyFeatureOverlay = new VectorLayerX({
				source: modifyFeatureSource,
				style: modifyFeatureStyle,
			})
			const dragBox = new DragBox({})
			dragBox.setActive(false)
			const draw = new Draw({
				source: modifyFeatureSource,
				style: modifyFeatureStyle,
				type: mainFeatureType === 'linee' ? 'LineString' : 'Point',
			})
			draw.setActive(false)
			const modify = new Modify({source: modifyFeatureSource, style: modifyFeatureHoverStyle})
			modify.setActive(false)
			const snap = new Snap({source: modifyFeatureSource})
			snap.setActive(false)
			const map = new ol.Map({
				view: new ol.View({
					projection: 'EPSG:900913',
					center,
					zoom: 11,
				}),
				controls: defaultControls({
					attributionOptions: {
						collapsible: false,
					},
				}).extend([new ZoomSlider()]),
				interactions: defaultInteractions().extend([dragBox, draw, modify, snap]),
				layers: [
					...baseLayers,
					...Object.values(layersMap.overlays),
					...supportLayers,
					mainLayer,
					mapSelectionOverlay,
					externalSelectionOverlay,
					modifyFeatureOverlay,
				],
			})
			return {
				map,
				layersMap,
				mainLayer,
				baseLayers,
				supportLayers,
				mapSelectionSource,
				mapSelectionOverlay,
				externalSelectionSource,
				externalSelectionOverlay,
				modifyFeatureSource,
				modifyFeatureOverlay,
				dragBox,
				draw,
				modify,
				snap,
			}
		}, [])

		const handleSelectionChange = () => {
			const mapSelection = mapSelectionSource.getFeatures().map(f => f.getProperties()[keyProp])
			setMapSelection(mapSelection)
			onSelectionChange(mapSelection)
		}

		useEffect(() => {
			map.setTarget(container.current)
			dragBox.on('boxend', function() {
				mapSelectionSource.clear()
				const extent = dragBox.getGeometry().getExtent()
				mainLayer
					.getSource()
					.forEachFeatureIntersectingExtent(extent, feature =>
						mapSelectionSource.addFeature(feature)
					)
				handleSelectionChange()
				centerOnSelection()
			})

			dragBox.on('boxstart', function() {
				mapSelectionSource.clear()
				handleSelectionChange()
			})
			draw.on('drawend', e => draw.setActive(false))
		}, [])

		function displayExternalSelection() {
			const ids = externalSelection.map(id => parseInt(id))
			externalSelectionSource.clear()
			const feats = mainLayer
				.getSource()
				.getFeatures()
				.filter(feat => ids.includes(parseInt(feat.getProperties()[keyProp])))

			externalSelectionOverlay.getSource().addFeatures(feats)
			centerOnSelection()
		}

		useEffect(() => {
			displayExternalSelection()
		}, [externalSelection])

		const centerOnSelection = () =>
			centerViewOnFeatures(map, [mapSelectionOverlay, externalSelectionOverlay])

		const [prevMode, setPrevMode] = useState(null)
		useEffect(() => {
			if (prevMode) {
				switch (prevMode) {
					case 'insert':
					case 'modify':
						draw.setActive(false)
						snap.setActive(false)
						modify.setActive(false)
						modifyFeatureSource.clear()
						break
				}
			}
			switch (currentMode) {
				case 'insert':
					draw.setActive(true)
					snap.setActive(true)
					modify.setActive(true)
					break
				case 'modify':
					const features = externalSelectionOverlay.getSource().getFeatures()
					modifyFeatureOverlay.getSource().addFeatures(features.map(f => f.clone()))
					modify.setActive(true)
					break
			}
			setPrevMode(currentMode)
		}, [currentMode])

		useImperativeHandle(ref, () => ({
			getFeaturesBeingEdited: () => modifyFeatureSource.getFeatures(),
			reload: async () =>
				new Promise(async (resolve, reject) => {
					try {
						const mainSource = mainLayer.getSource()
						await mainSource.clear()
						mainSource.once('change', () => {
							displayExternalSelection()
							mapSelectionSource.clear()
							mapSelectionSource.addFeatures(
								mainSource
									.getFeatures()
									.filter(feat => mapSelection.includes(feat.getProperties()[keyProp]))
							)
							resolve()
						})
					} catch (err) {
						reject(err)
					}
				}),
		}))

		let byElectricalBoxControl
		switch (mainFeatureType) {
			case 'pl':
				byElectricalBoxControl = (
					<ViewByElectricalBoxControl
						onChange={active => mainLayer.setStyle(active ? styles.plByCentralina : styles.pl)}
					/>
				)
				break
			case 'linee':
				byElectricalBoxControl = (
					<ViewByElectricalBoxControl
						onChange={active =>
							mainLayer.setStyle(active ? styles.lineeByCentralina : styles.linee)
						}
					/>
				)
				break
			default:
				byElectricalBoxControl = null
				break
		}
		const overlays = [...Object.values(layersMap.overlays), ...supportLayers, mainLayer]
		const controlBar = (
			<MapControlBar>
				{byElectricalBoxControl}
				<FullScreenControl container={container} />
				<LayerSwitcher
					defaultActiveOverlays={overlays.map((_, i) => i)}
					onBaseLayerChange={index => {
						baseLayers[index] && baseLayers[index].setVisible(true)
						baseLayers.filter((_, i) => i !== index).forEach(layer => layer.setVisible(false))
					}}
					onOverlaysChange={indexes => {
						overlays.forEach((overlay, i) => overlay.setVisible(indexes.includes(i)))
					}}
					baseLayers={[...baseLayers.map(({name}) => ({name: name})), {name: 'None'}]}
					overlays={overlays.map(({name}) => ({name: name}))}
				/>
				<MoveSelectToggle dragBox={dragBox} />
			</MapControlBar>
		)

		return (
			<>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'relative',
						background: '#f0f2f5',
					}}
					ref={container}
				>
					{controlBar}
				</div>
			</>
		)
	}
)
