import React, { useState, useEffect, useReducer } from 'react';
import SplitPaneOriginal from 'react-split-pane';
import _ from 'lodash';
import axios from 'axios';

export const identity = i => i;
export const noop = () => {};

export const dump = (children, { fontSize = '75%', indent, inline = false, style = {} } = {}) => {
	if (inline && indent !== undefined)
		console.error(
			`Warning: Only one of 'inline' and 'indent' should be passed. Using 'inline' (indent = 0).`
		);
	const _indent = inline ? 0 : indent !== undefined ? parseInt(indent, 10) : 2;
	return <pre style={{ fontSize, ...style }}>{JSON.stringify(children, null, _indent)}</pre>;
};

export const Dump = ({ children, ...props }) => dump(children, props)

export const dispatchWindowResize = _.throttle(({ delay = 0 } = {}) => {
	setTimeout(() => {
		window.dispatchEvent(new Event('resize'));
	}, delay);
}, 200);

export const SplitPane = ({ onChange, children, ...props }) => (
	<SplitPaneOriginal
		onChange={(...args) => {
			dispatchWindowResize();
			if (onChange) onChange(...args);
		}}
		{...props}
	>
		{children}
	</SplitPaneOriginal>
);

export const SplitPaneContent = ({ style = {}, children, ...props }) => (
	<div
		style={{
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			...style,
		}}
		{...props}
	>
		{children}
	</div>
);

const unquote = str => str.replace(/^['"](.*)['"]$/, '$1');

export const defaultStateReducer = (oldState, newState) => ({...oldState, ...newState})

// const filterObjectsArray = (array, search, includeFields) => {
// 	// $log.debug('search',search)
// 	if (!search || search === '!') return array;
// 	var negate = false;
// 	if (search[0] === '!') {
// 		search = search.substr(1);
// 		negate = true;
// 	}
// 	var re = new RegExp(search, 'i');
// 	// console.log(re);
// 	// $log.debug((negate ? 'negate:' : ''), search)
// 	return array.filter(function(obj) {
// 		for (var prop in obj) {
// 			if (includeFields && includeFields.indexOf(prop) === -1) continue;

// 			var match = String(obj[prop]).search(re);
// 			if (match !== -1) return !negate;
// 		}
// 		return negate;
// 	});
// };

// export const googleLikeFilter = (array, searchString, { includeFields = null } = {}) => {
// 	if (!searchString) return array;
// 	let include = [];
// 	let exclude = [];
// 	var searches = searchString.match(/([+-]?(?:'.+?'|".+?"|[^+\- ]{1}[^ ]*))/g);

// 	if (!searches) return array;

// 	for (let i = 0, len = searches.length; i < len; i++) {
// 		var s = searches[i];
// 		var start = s.substr(0, 1);
// 		if (start === '-') exclude.push(unquote(s.substr(1)));
// 		else if (start === '+') include.push(unquote(s.substr(1)));
// 		else include.push(unquote(s));
// 	}
// 	include = _.uniq(include);
// 	exclude = _.uniq(exclude);

// 	for (let i = 0, len = exclude.length; i < len; i++) {
// 		array = filterObjectsArray(array, '!' + exclude[i], includeFields);
// 		if (!array.length) return array;
// 	}

// 	for (let i = 0, len = include.length; i < len; i++) {
// 		array = filterObjectsArray(array, include[i], includeFields);
// 		if (!array.length) return array;
// 	}
// 	return array;
// };

const acceptAll = item => true;

const DEFAULT_EXCLUDE_FIELDS = [
	'bbox',
	'the_geom',
	'plid',
	'idf',
	'id_linea',
	'id_imp',
	'ip_q_sez',
];

const escapeRegExp = text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const googleLikeFilter = (searchString, { includeFields = null } = {}) => {
	if (!searchString || searchString === '!') return acceptAll;
	let searches = searchString.match(/([+-]?(?:'.+?'|".+?"|[^+\- ]{1}[^ ]*))/g);
	if (!searches) return acceptAll;
	let include = [];
	let exclude = [];
	for (let i = 0, len = searches.length; i < len; i++) {
		let s = searches[i];
		let start = s.substr(0, 1);
		if (start === '-') exclude.push(unquote(s.substr(1)));
		else if (start === '+') include.push(unquote(s.substr(1)));
		else include.push(unquote(s));
	}
	include = _.uniq(include).map(search => new RegExp(escapeRegExp(search), 'i'));
	exclude = _.uniq(exclude).map(search => new RegExp(escapeRegExp(search), 'i'));
	// console.log(include, exclude);
	return obj => {
		const vals = _.keys(_.omit(obj, DEFAULT_EXCLUDE_FIELDS))
			.filter(prop => (includeFields ? includeFields.includes(prop) : true))
			.map(prop => String(obj[prop]));
		for (let val of vals) {
			for (let search of exclude) {
				// console.log(prop, val);
				if (val.search(search) !== -1) {
					// console.log('exclude', val, search, exclude);
					return false;
				}
			}
		}
		loop: for (let search of include) {
			for (let val of vals) {
				if (val.search(search) !== -1) {
					// console.log('include', val, search, include);
					continue loop;
				}
			}
			return false;
		}

		return true;
	};
};

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay]
	);

	return debouncedValue;
};

export const useDebouncedFilter = ({delay = 200, initialValue = ''} = {}) => {
	const [filter, setFilter] = useState(initialValue)
	const debouncedFilter = useDebounce(filter, delay)
	return {filter, debouncedFilter, setFilter}
}

export const pluralize = (count, none, one, many) => {
	switch (count) {
		case 0:
			return none;
		case 1:
			return one;
		default:
			return many;
	}
};

export const useSmartState = (initialState = {}) => {
	return useReducer((oldState, newState) => ({...oldState, ...newState}), initialState)
}

export const useData = (urlOrLoader, {unwrapper} = {}) => {
	const [state, setState] = useSmartState({
		loading: true,
		data: null,
		error: null,
	})
	switch (unwrapper) {
		case false:
			unwrapper = data => data
			break
		case undefined:
			unwrapper = ({data}) => data
			break
	}
	useEffect(() => {
		state.loading &&
			(typeof urlOrLoader === 'function' ? urlOrLoader() : axios.get(urlOrLoader))
				.then(res => setState({data: unwrapper(res)}))
				.catch(err => {
					console.error(err)
					setState({error: err.message})
				})
				.then(() => {
					setState({loading: false})
				})
	}, [state.loading])
	const invalidate = () => setState({loading: true})
	return {...state, invalidate}
}