import * as pl from './pl'
import * as linee from './linee'
import * as quadri from './quadri'
import * as centraline from './centraline'
import * as edifici from './edifici'
import * as impianti from './impianti'

export default {
	oggetti: {
		pl,
		linee,
		quadri,
		centraline,
		edifici,
		impianti,
	},
	manutenzioni: [
		{
			key: 'codice',
			name: 'Codice',
		},
		{
			key: 'tipo_intervento',
			name: 'Tipo Intervento',
		},
		{
			key: 'data_fine',
			name: 'Data Conclusione',
		},
		{
			key: 'descrizione',
			name: 'Descrizione',
		},
		{
			key: 'attrezzatura',
			name: 'Attrezzatura',
		},
	],
}
