import React from 'react'
import styled from 'styled-components'

export const Space = styled(({className, direction, style, children}) => (
	<div
		className={className}
		style={{
			...style,
			display: 'flex',
			flexDirection: direction === 'vertical' ? 'column' : 'row',
		}}
	>
		{children}
	</div>
))`
	> * {
		margin-bottom: 10px;
	}
	> *:last-child {
		margin-bottom: 0;
	}
`
