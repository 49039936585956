import * as Extent from 'ol/extent'
import {Tile as TileLayer} from 'ol/layer'
import {OSM as OSMSource, TileWMS as TileWMSSource, Vector as VectorSource} from 'ol/source'
import {GeoJSON} from 'ol/format'
import * as loadingstrategy from 'ol/loadingstrategy'
import _ from 'lodash'

import styles from '../../../configs/layer-styles'
import {VectorLayerX} from './custom-classes'

export const centerViewOnFeatures = (map, layers) => {
	const finalExtent = Extent.createEmpty()
	layers.forEach(layer => {
		Extent.extend(finalExtent, layer.getSource().getExtent())
	})
	if (!Extent.isEmpty(finalExtent)) {
		map.getView().fit(Extent.buffer(finalExtent, 300), {maxZoom: 18})
	}
}

export function createWMSLayer(layers, {...options} = {}) {
	return new TileLayer({
		source: new TileWMSSource({
			url: '/geoserver/wms',
			params: {
				LAYERS: layers,
				TILED: true,
			},
			serverType: 'geoserver',
			transition: 0,
		}),
		...options,
	})
}

export function createGeojsonAPILayer(featureType, {style, ...options}) {
	return new VectorLayerX({
		source: new VectorSource({
			format: new GeoJSON(),
			// url: '/geoserver/wfs?service=WFS&' +
			// 	`version=1.1.0&request=GetFeature&typename=${featureType}&` +
			// 	'outputFormat=application/json&srsname=EPSG:900913',
			// strategy: loadingstrategy.all
			url: '/api/v2.0/' + featureType,
			strategy: loadingstrategy.all,
		}),
		style,
		// declutter: true,
		// renderOrder: (feature) => Number
		// updateWhileInteracting: true, // ### WARNING - VERY VERY SLOWWW!!! ###
		// updateWhileAnimating: true,
		renderBuffer: 400,
		...options,
	})
}

export function createWFSLayer(featureType, {style, ...options} = {}) {
	return new VectorLayerX({
		source: new VectorSource({
			format: new GeoJSON(),
			// url: '/geoserver/wfs?service=WFS&' +
			// 	`version=1.1.0&request=GetFeature&typename=${featureType}&` +
			// 	'outputFormat=application/json&srsname=EPSG:900913',
			// strategy: loadingstrategy.all
			url: extent =>
				'/geoserver/wfs?service=WFS&' +
				`version=1.1.0&request=GetFeature&typename=${featureType}&` +
				'outputFormat=application/json&srsname=EPSG:900913&' +
				'bbox=' +
				extent.join(',') +
				',EPSG:900913',
			strategy: loadingstrategy.bbox,
		}),
		style,
		// declutter: true,
		// renderOrder: (feature) => Number
		// updateWhileInteracting: true, // ### WARNING - VERY VERY SLOWWW!!! ###
		// updateWhileAnimating: true,
		renderBuffer: 400,
		...options,
	})
}

export function createBaseLayers({codistat}) {
	return [
		new TileLayer({source: new OSMSource()}),
		createGeojsonAPILayer('confini', {
			style: styles.borders,
			renderMode: 'image',
		}),
	]
}
