export function isFullScreenSupported() {
  const body = document.body
  return !!(
    body.webkitRequestFullscreen ||
    (body.msRequestFullscreen && document.msFullscreenEnabled) ||
    (body.requestFullscreen && document.fullscreenEnabled)
  )
}

export function isFullScreen() {
  return !!(
    document.webkitIsFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement
  )
}

export function requestFullScreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  }
}

export function requestFullScreenWithKeys(element) {
  if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else {
    requestFullScreen(element)
  }
}

export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}
