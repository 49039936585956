export const primaryKey = 'idf'
export const defaultSortOn = 'edif_descr'

export const columns = {
	filter: [
		{
			key: 'edif_descr',
			name: 'Descrizione',
		},
		{
			key: 'indirizzo',
			name: 'Indirizzo',
		},
	],
	selection: [
		{
			key: 'edif_descr',
			name: 'Edificio',
		},
		{
			key: 'indirizzo',
			name: 'Indirizzo',
		},
		{
			key: 'localita',
			name: 'Località',
		},
		{
			key: 'sup_mq',
			name: 'Superficie (mq)',
		},
		{
			key: 'nr_utenti',
			name: 'N° Utenti',
		},
	],
}
