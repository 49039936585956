import { Style, Stroke } from 'ol/style';

export default new Style({
	stroke: new Stroke({
		color: 'red',
		width: 4,
		lineCap: 'butt',
		lineDash: [9,4,4,4],
		// miterLimit: 100,
	})
})