import React, {useEffect, useMemo, useReducer} from 'react'
import {Col, Form as AntForm, Input, Row} from 'antd'
import _ from 'lodash'

import ImpiantiGrid from '../../components/bollettazione/impianti-grid'
import {InfoImpianto} from '../../components/bollettazione/info-impianto'
import TipiImpiantiSelect from '../../components/bollettazione/tipi-impianti-select'
import {formItemLayout} from '../../components/forms/common'
import {get as getImpianti} from '../../services/impianti'
import {googleLikeFilter, useDebounce} from '../../utils'
import BolletteGrid from './bollette-grid'

export default () => {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		currentTipo: null,
		filter: null,
		selectedImpiantoId: null,
		impianti: [],
	})

	useEffect(() => {
		getImpianti().then(impianti => setState({impianti}))
	}, [])

	const debouncedFilter = useDebounce(state.filter, 200)

	const impiantiFiltrati = useMemo(
		() =>
			state.impianti
				.filter(imp =>
					state.currentTipo && state.currentTipo !== '*'
						? _.get(imp, 'imp_tipo') === state.currentTipo
						: true
				)
				.filter(i => googleLikeFilter(debouncedFilter)(i)),
		[state.impianti, state.currentTipo, debouncedFilter]
	)

	const selectedImpianto = useMemo(
		() => state.impianti.find(({id_imp}) => id_imp === state.selectedImpiantoId),
		[state.selectedImpiantoId]
	)

	function bind(name, getter = v => v) {
		return {
			onChange: val => {
				setState({[name]: getter(val)})
			},
			value: state[name] || null,
		}
	}

	const onImpiantoSelectionChange = selection => {
		setState({selectedImpiantoId: selection && selection.length ? selection[0] : null})
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
			<div
				style={{height: '40%', backgroundColor: 'white', borderBottom: '1px solid #e8e8e8', padding: 10}}
			>
				<Row style={{height: '100%', display: 'flex'}}>
					<Col span={8} style={{overflowY: 'auto'}}>
						<AntForm>
							<AntForm.Item {...formItemLayout} label="Tipo Impianto">
								<TipiImpiantiSelect
									impianti={state.impianti}
									{...bind('currentTipo')}
									placeholder="Seleziona..."
									allowClear
								/>
							</AntForm.Item>
							<AntForm.Item {...formItemLayout} label="Ricerca libera" hasFeedback>
								<Input {...bind('filter', e => e.target.value)} />
							</AntForm.Item>
							{selectedImpianto && (
								<div style={{lineHeight: 0.5, fontSize: '80%'}}>
									<InfoImpianto impianto={selectedImpianto} />
								</div>
							)}
						</AntForm>
					</Col>
					<Col span={16}>
						<ImpiantiGrid
							style={{border: '1px solid #dddddd'}}
							data={impiantiFiltrati}
							onSelectionChange={onImpiantoSelectionChange}
							selection={state.selectedImpiantoId ? [state.selectedImpiantoId] : []}
						/>
					</Col>
				</Row>
			</div>

			<BolletteGrid impianto={selectedImpianto} />
		</div>
	)
}
