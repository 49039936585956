import {Tabs} from 'antd'
import React from 'react'

const {TabPane} = Tabs
export const ColumnGroupTabs = ({columnGroups, activeIndex, onChange}) => (
	<Tabs
		onChange={onChange}
		type="card"
		activeKey={String(activeIndex)}
		style={{marginBottom: -17}}
	>
		{columnGroups.map(({label}, idx) => (
			<TabPane tab={label} key={idx} />
		))}
	</Tabs>
)
