import React from 'react'
import ReactDataGrid from 'react-data-grid'
import joinClasses from 'classnames'

const {Row, Cell, CellExpand} = ReactDataGrid

class CustomCell extends Cell {
	render() {
		if (this.props.column.hidden) {
			return null
		}

		const style = this.getStyle()

		const className = joinClasses(this.getCellClass(), ['react-grid-Cell-CUSTOM'])

		// const cellActionButtons = this.getCellActions()
		const {value, column, rowIdx, isExpanded, isScrolling, rowData} = this.props
		// const cellContent =
		// 	this.props.children ||
		// 	this.renderCellContent({
		// 		value,
		// 		column,
		// 		rowIdx,
		// 		isExpanded,
		// 		isScrolling,
		// 	})

		const events = this.getEvents()

		// const cellExpander = this.canExpand() && (
		// 	<CellExpand
		// 		expandableOptions={this.props.expandableOptions}
		// 		onCellExpand={this.onCellExpand}
		// 	/>
		// )

		return (
			<div
				{...this.getKnownDivProps()}
				className={className}
				style={style}
				{...events}
				ref={this.setCellRef}
			>
				{this.props.column.renderer({
					value,
					column,
					rowIdx,
					isExpanded,
					isScrolling,
					rowData,
				})}
				{/* {cellActionButtons}
				{cellExpander}
				{cellContent} */}
			</div>
		)
	}
}

export class CustomRow extends Row {
	static defaultProps = {
		cellRenderer: Cell,
		isSelected: false,
		height: 35,
	}
	getCell = column => {
		// const CellRenderer = column.renderer || this.props.cellRenderer
		const CellRenderer = column.renderer ? CustomCell : this.props.cellRenderer
		const {
			idx,
			cellMetaData,
			isScrolling,
			row,
			isSelected,
			scrollLeft,
			lastFrozenColumnIndex,
		} = this.props
		const {key, formatter} = column
		const baseCellProps = {
			key: `${key}-${idx}`,
			idx: column.idx,
			rowIdx: idx,
			height: this.getRowHeight(),
			column,
			cellMetaData,
		}

		const cellProps = {
			ref: node => {
				this[key] = node
			},
			value: this.getCellValue(key || column.idx),
			rowData: row,
			isRowSelected: isSelected,
			expandableOptions: this.getExpandableOptions(key),
			formatter,
			isScrolling,
			scrollLeft,
			lastFrozenColumnIndex,
		}

		return <CellRenderer {...baseCellProps} {...cellProps} />
	}
}
