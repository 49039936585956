import React, {useState, useEffect, useRef, useMemo} from 'react'
import {Button, Input, Checkbox, Divider, message, Select} from 'antd'

import {Switch, Route, Redirect, withRouter} from 'react-router-dom'

import {GeoJSON} from 'ol/format'
import Feature from 'ol/Feature'

import _ from 'lodash'
import Box from 'ui-box'

import {saveAs} from 'file-saver'

import gridConfigs from '../../configs/grids'
import {loadFeatures, insertFeature, updateFeature, deleteFeature} from '../../services/data'

import {SplitPane, SplitPaneContent, googleLikeFilter, useDebounce} from '../../utils'

import {
	openNotificationWithIcon,
	showDeleteConfirm,
	showUpdateConfirm,
} from '../common/notifications'

import Map from './map'
import Grid from '../../components/Grid'
import {InterventiIlluminazioneInterniModal} from '../../components/illuminazione-interni'

import forms from './forms'
import {ModifyFormWrapper} from './forms/utils'
import * as styles from '../../styles'
import axios from 'axios'
import {PROPS_WHITELISTS} from '../../constants'
import {useAuth} from '../../lib/auth'
import {ColumnGroupTabs} from '../common/ui'

class UIMode {
	static VIEW = 'view'
	static INSERT = 'insert'
	static MODIFY = 'modify'
	static COMPLETE_INSERTS = 'complete-inserts'
}

const modes = [UIMode.VIEW, UIMode.INSERT, UIMode.MODIFY, UIMode.COMPLETE_INSERTS]

const getCurrentColumns = (featureType, mode, columnGroupIdx, plantType) => {
	const {columnGroups, columns} = gridConfigs[featureType]
	const currentColumnGroup = columnGroups[columnGroupIdx]
	return columns['*']
		.filter(
			col =>
				currentColumnGroup.keys.includes(col.key) ||
				currentColumnGroup.keys.includes(col._key)
		)
		.map(col => {
			if (!columns[mode]) return col
			const replacement = columns[mode].find(item => item.key === col.key)
			return replacement || col
		})
		.map(col => {
			let name = col.name
			if (featureType === 'impianti' && plantType !== '*') {
				name =
					_.get(
						gridConfigs,
						`impianti.TYPE_SPECIFIC_COLUMN_LABELS.${plantType}.${col.key}`
					) || '~ ' + col.key
			}
			return {...col, name, resizable: true}
		})
}

const exportFeatures = async ({query, features}) =>
	axios
		.post(
			'/api/utils/export',
			features.map(feature => ({...feature, query})),
			{
				responseType: 'blob',
			}
		)
		.then(({data}) => saveAs(data, 'export.xls'))
		.catch(err => message.error(`Errore nell'esportazione: ${err.message}`))

const ExportButton = ({children, disabled, query, features}) => {
	const [loading, setLoading] = useState(false)
	return (
		<Button
			size="small"
			loading={loading}
			disabled={disabled || loading}
			onClick={() => {
				setLoading(true)
				exportFeatures({
					query,
					features,
				}).then(() => setLoading(false))
			}}
		>
			{children}
		</Button>
	)
}

const Infrastruttura = withRouter(({mainFeatureType, keyProp, history, illuminazioneInterniId}) => {
	const {user, userCan} = useAuth()
	const {codistat, center} = user

	const mapRef = useRef()

	const [mapSelectedItems, setMapSelectedItems] = useState([])
	const [gridSelectedItems, setGridSelectedItems] = useState([])
	const [currentMode, setCurrentMode] = useState(modes[0])
	const [currentColumnGroupIdx, setCurrentColumnGroupIdx] = useState(0)
	const [featureBeingEdited, setFeatureBeingEdited] = useState(null)
	const [data, setData] = useState(null)
	const [filter, setFilter] = useState('')
	const [tipoImpiantoFilter, setTipoImpiantoFilter] = useState('*')
	const debouncedFilter = useDebounce(filter, 200)
	const [loading, setLoading] = useState(false)
	// const [illuminazioneInterniId, setIlluminazioneInterniId] = useState(null)

	const reload = async () => {
		await mapRef.current.reload()
		setData(await loadFeatures(mainFeatureType))
	}

	useEffect(() => {
		;(async () => {
			if (!data) setData(await loadFeatures(mainFeatureType))
		})()
	}, [])

	const filteredFeatures = useMemo(() => {
		const filteredFeatures = (data || [])
			.map(f => f.properties)
			.filter(f => {
				if (
					mainFeatureType === 'impianti' &&
					tipoImpiantoFilter !== '*' &&
					f.imp_tipo !== tipoImpiantoFilter
				)
					return false
				return (
					googleLikeFilter(debouncedFilter)(f) &&
					(mapSelectedItems.length ? mapSelectedItems.includes(f[keyProp]) : true)
				)
			})
		return filteredFeatures
	}, [debouncedFilter, data, mapSelectedItems, tipoImpiantoFilter])

	const mergeCommittedFeature = ({mapEntity, gridEntity = {}}) => {
		if (!mapEntity) {
			throw new Error('Map feature missing')
		}
		const newFeature = new Feature()
		newFeature.setGeometry(mapEntity.getGeometry())
		newFeature.setProperties(gridEntity)

		/* imposta a null le proprietà che erano presenti sulla mapEntity (preveniente da sever) in modo da cancellare le proprietà null-ish */
		_.keys(_.omit(mapEntity.getProperties(), ['geometry'])).forEach(prop => {
			if (_.isNil(gridEntity[prop])) newFeature.set(prop, null)
		})
		const featureGeoJson = new GeoJSON().writeFeature(newFeature)
		const cleanGeoJson = _.omit(JSON.parse(featureGeoJson), ['properties.bbox'])
		return cleanGeoJson
	}

	const startInsert = () => {
		setCurrentMode(UIMode.INSERT)
	}

	const commitInsert = async entity => {
		setLoading(true)
		const mapEntity = mapRef.current.getFeaturesBeingEdited()[0]
		if (!mapEntity) {
			openNotificationWithIcon({
				type: 'error',
				title: 'Elemento mappa mancante',
				message: 'Non è stato inserito un elemento in mappa',
			})
			return
		}
		const featureGeoJson = mergeCommittedFeature({
			gridEntity: entity,
			mapEntity,
		})
		const result = await insertFeature(mainFeatureType, featureGeoJson)
		if (result.success) {
			openNotificationWithIcon({
				type: 'success',
				title: 'Modifica salvata',
				message: 'La modifica è stata salvata con successo!',
			})
			await reload()
			setLoading(false)
			setCurrentMode(UIMode.VIEW)
		} else {
			openNotificationWithIcon({
				type: 'error',
				title: 'Errore nel salvataggio',
				message: result.error,
			})
			setLoading(false)
		}
	}

	async function commitDelete(id) {
		const result = await deleteFeature(mainFeatureType, id)
		if (result.success) {
			openNotificationWithIcon({
				type: 'success',
				title: 'Elemento eliminato',
				message: `L'elemento è stato eliminato con successo!`,
			})
			await reload()
			setLoading(false)
			setCurrentMode(UIMode.VIEW)
		} else {
			openNotificationWithIcon({
				type: 'error',
				title: `Errore durante l'eliminazione`,
				message: result.error,
			})
			setLoading(false)
		}
	}

	const startModify = () => {
		setFeatureBeingEdited(featuresMap[gridSelectedItems[0]])
		setCurrentMode(UIMode.MODIFY)
	}

	const commitModify = async entity => {
		setLoading(true)
		const mapEntity = mapRef.current.getFeaturesBeingEdited()[0]
		const featureGeoJson = mergeCommittedFeature({
			gridEntity: entity,
			mapEntity,
		})
		const result = await updateFeature(mainFeatureType, entity[keyProp], featureGeoJson)
		if (result.success) {
			openNotificationWithIcon({
				type: 'success',
				title: 'Modifica salvata',
				message: 'La modifica è stata salvata con successo!',
			})
			await reload()
			setLoading(false)
			setCurrentMode(UIMode.VIEW)
		} else {
			openNotificationWithIcon({
				type: 'error',
				title: 'Errore nel salvataggio',
				message: result.error,
			})
			setLoading(false)
		}
	}

	const renderButtons = () => {
		if (!userCan('SUPERVISORE')) return null
		const Wrapper = ({children}) => (
			<div
				style={{
					padding: 10,
				}}
			>
				{children}
			</div>
		)
		switch (currentMode) {
			case UIMode.INSERT:
			case UIMode.MODIFY:
				return null
			case UIMode.COMPLETE_INSERTS:
				return (
					<Wrapper>
						<Button
							key={0}
							onClick={() => setCurrentMode(UIMode.VIEW)}
							style={{marginRight: 5}}
						>
							Annulla
						</Button>
						<Button key={1} style={{marginRight: 5}}>
							Salva
						</Button>
						<Divider key={3} type="vertical" />
						<Checkbox key={4}>Mostra solo incompleti</Checkbox>
					</Wrapper>
				)
			default:
				return (
					<Wrapper>
						<Button
							size="small"
							key={0}
							onClick={() => startInsert()}
							style={{marginRight: 5}}
						>
							Inserisci
						</Button>
						{/* <Button size="small" key={1} onClick={() => setCurrentMode(UIMode.COMPLETE_INSERTS)}>
							Completa Inserimenti
						</Button> */}
						<Button
							size="small"
							key={2}
							onClick={startModify}
							disabled={gridSelectedItems.length != 1}
							style={{marginRight: 5}}
						>
							Modifica
						</Button>
						<ExportButton
							disabled={!data}
							query={debouncedFilter}
							features={filteredFeatures.map(feature =>
								_.pick(feature, PROPS_WHITELISTS[mainFeatureType])
							)}
						>
							Esporta{' '}
							{!data || !filteredFeatures || data.length === filteredFeatures.length
								? 'Tutti'
								: 'Filtrati'}
						</ExportButton>
						{mainFeatureType === 'edifici' ? (
							<>
								<Button
									size="small"
									onClick={() => {
										history.push(
											`/edifici-e-impianti/edifici/${gridSelectedItems[0]}/impianti`
										)
									}}
									disabled={gridSelectedItems.length != 1}
									style={{marginLeft: 5}}
								>
									Impianti associati
								</Button>
								{user.codistat === '031003' ? (
									<Button
										size="small"
										onClick={() =>
											history.push(
												`/edifici-e-impianti/edifici/${gridSelectedItems[0]}/illuminazione-interni`
											)
										}
										disabled={gridSelectedItems.length != 1}
										style={{marginLeft: 5}}
									>
										Illuminazione interni
									</Button>
								) : null}
							</>
						) : null}
						{mainFeatureType === 'impianti' ? (
							<>
								<Button
									size="small"
									onClick={() => {
										history.push(
											`/edifici-e-impianti/impianti/${gridSelectedItems[0]}/edifici`
										)
									}}
									disabled={gridSelectedItems.length != 1}
									style={{marginLeft: 5}}
								>
									Edifici associati
								</Button>
							</>
						) : null}
					</Wrapper>
				)
		}
	}

	const {columnGroups, columns, defaultSortOn} = gridConfigs[mainFeatureType]
	const [features, featuresMap] = useMemo(() => {
		const features = data || []
		return [
			features,
			features.reduce((acc, next) => {
				acc[next.properties[keyProp]] = next
				return acc
			}, {}),
		]
	}, [data])

	const currentColumns = getCurrentColumns(
		mainFeatureType,
		currentMode,
		currentColumnGroupIdx,
		tipoImpiantoFilter
	)

	const InsertForm = forms[mainFeatureType].insert
	const ModifyForm = forms[mainFeatureType].modify

	return (
		<div
			style={
				{height: '100%', display: 'flex', flex: 1, position: 'relative'} // THIS SEEMS REQUIRED
			}
		>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent>
					<Map
						key={codistat + '_' + mainFeatureType}
						codistat={codistat}
						center={center}
						mainFeatureType={mainFeatureType}
						keyProp={keyProp}
						externalSelection={gridSelectedItems}
						onSelectionChange={setMapSelectedItems}
						currentMode={currentMode}
						ref={mapRef}
					/>
				</SplitPaneContent>
				<SplitPaneContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						// backgroundColor: 'white',
					}}
				>
					{currentMode === UIMode.MODIFY ? null : (
						<Box padding={10} display="flex" alignItems="center">
							{mainFeatureType === 'impianti' && (
								<Select
									style={{width: 200, marginRight: 10}}
									value={tipoImpiantoFilter}
									onChange={value => setTipoImpiantoFilter(value)}
								>
									<Select.Option value="*">Tutti</Select.Option>
									<Select.Option value="automez_">Automezzi</Select.Option>
									<Select.Option value="ed_ele_">Utenze elettriche</Select.Option>
									<Select.Option value="ed_term_">Utenze termiche</Select.Option>
									<Select.Option value="fotov_">
										Impianti fotovoltaici
									</Select.Option>
								</Select>
							)}
							<Input
								placeholder="Filtro"
								onChange={e => setFilter(e.target.value)}
								value={filter}
								allowClear
							/>
						</Box>
					)}

					{currentMode === UIMode.INSERT || currentMode === UIMode.MODIFY ? null : (
						<ColumnGroupTabs
							style={{height: 30}}
							columnGroups={columnGroups}
							onChange={setCurrentColumnGroupIdx}
							activeIndex={currentColumnGroupIdx}
						/>
					)}

					<Grid // fit
						style={{
							flex: 1,
							position: 'relative',
							borderBottom: styles.BORDERS,
						}}
						columns={currentColumns}
						data={filteredFeatures}
						minColumnWidth={120}
						defaultSortOn={defaultSortOn}
						rowKey={keyProp}
						onSelectionChange={setGridSelectedItems}
						selectionFrozen={currentMode === UIMode.MODIFY}
					/>

					{currentMode !== UIMode.INSERT ? null : (
						<InsertForm
							onCancel={() => setCurrentMode(UIMode.VIEW)}
							onSave={commitInsert}
						/>
					)}

					{currentMode !== UIMode.MODIFY ? null : (
						<>
							<ColumnGroupTabs
								style={{height: 30}}
								columnGroups={columnGroups}
								onChange={setCurrentColumnGroupIdx}
								activeIndex={currentColumnGroupIdx}
							/>
							<ModifyFormWrapper
								featureType={mainFeatureType}
								initialEntity={featureBeingEdited.properties}
								onClose={() => setCurrentMode(UIMode.VIEW)}
								onSave={entity =>
									showUpdateConfirm({
										onOk: () => commitModify(entity),
										okType: 'warning',
									})
								}
								onDelete={id => showDeleteConfirm({onOk: () => commitDelete(id)})}
							>
								{({formItem, values}) =>
									ModifyForm({formItem, values})[
										columnGroups[currentColumnGroupIdx].name
									]
								}
							</ModifyFormWrapper>
						</>
					)}

					{renderButtons()}
				</SplitPaneContent>
			</SplitPane>
			<InterventiIlluminazioneInterniModal
				edificioId={illuminazioneInterniId}
				onCancel={() => {
					history.push('/edifici-e-impianti/edifici')
				}}
				// onCancel={() => setIlluminazioneInterniId(null)}
			/>
		</div>
	)
})

export default () => (
	<Switch>
		<Route
			exact
			path="/struttura/pl"
			render={() => <Infrastruttura key="pl" mainFeatureType="pl" keyProp="plid" />}
		/>
		<Route
			exact
			path="/struttura/linee"
			render={() => <Infrastruttura key="linee" mainFeatureType="linee" keyProp="id_linea" />}
		/>
		<Route
			exact
			path="/struttura/centraline"
			render={() => (
				<Infrastruttura key="centraline" mainFeatureType="centraline" keyProp="id_imp" />
			)}
		/>
		<Route
			exact
			path="/struttura/quadri"
			render={() => (
				<Infrastruttura key="quadri" mainFeatureType="quadri" keyProp="id_q_sez" />
			)}
		/>
		<Route
			exact
			path="/edifici-e-impianti/edifici"
			render={() => <Infrastruttura key="edifici" mainFeatureType="edifici" keyProp="idf" />}
		/>
		<Route
			exact
			path="/edifici-e-impianti/edifici/:building_id/illuminazione-interni"
			render={({match}) => (
				<Infrastruttura
					key="edifici"
					mainFeatureType="edifici"
					keyProp="idf"
					illuminazioneInterniId={match.params.building_id}
				/>
			)}
		/>
		<Route
			exact
			path="/edifici-e-impianti/impianti"
			render={() => (
				<Infrastruttura key="impianti" mainFeatureType="impianti" keyProp="id_imp" />
			)}
		/>

		<Redirect to="/" />
	</Switch>
)
