import React, {useImperativeHandle, forwardRef, useRef, useMemo} from 'react'
import _ from 'lodash'

import Grid from '../Grid'

const columns = [
	{
		key: 'pod_pdr_cont',
		name: 'POD/PDR',
	},
	{
		key: 'presa_al',
		name: 'Altro Identificativo',
	},
	{
		key: 'uten',
		name: 'Utenza',
	},
	{
		name: 'Indirizzo/Località',
		key: 'indirizzo',
	},
	{
		name: 'UM - Vettore - Consumo/Produzione',
		key: 'um_vettore_cp',
	},
	{
		key: 'note',
		name: 'Note',
	},
]

export default forwardRef(({data, selection, onSelectionChange, style}, ref) => {
	const $grid = useRef()
	useImperativeHandle(ref, () => ({
		selectAll: () => $grid.current.selectAll(),
	}))
	const _data = useMemo(
		() =>
			data.map(i => ({
				...i,
				indirizzo: [i.indir, i.loc].join(' - '),
				um_vettore_cp: _.compact([_.get(i, 'um'), _.get(i, 'vett'), _.get(i, 'cons_prod')]).join(
					' - '
				),
			})),
		[data]
	)
	return (
		<Grid
			ref={$grid}
			data={_data}
			columns={columns}
			rowKey="id_imp"
			onSelectionChange={selection => onSelectionChange(selection)}
			selection={selection}
			style={{width: '100%', height: '100%', ...style}}
		/>
	)
})
