import borders from './borders';
import centraline from './centraline';
import linee from './linee';
import lineeByCentralina from './linee-by-centralina';
import quadri from './quadri';
import pl from './pl';
import plByCentralina from './pl-by-centralina';
import edifici from './edifici';
import impianti from './impianti';

export default {
	borders,
	centraline,
	linee,
	lineeByCentralina,
	quadri,
	pl,
	plByCentralina,
	edifici,
	impianti,
};
