import React, {useState, useEffect} from 'react'
import {Button, Modal, Spin, message} from 'antd'

import {fetchPartenzeCentralina, savePartenzeCentralina} from '../../../../../services/data'
import {useData} from '../../../../../utils'
import PartenzeUI from './ui'

const PartenzeManager = ({id_imp, onClose}) => {
	const {loading, data, error, invalidate} = useData(() => fetchPartenzeCentralina(id_imp), {
		unwrapper: false,
	})
	const [partenze, setPartenze] = useState([])

	useEffect(() => {
		data && setPartenze(data)
	}, [data])

	const handleSave = async partenze => {
		savePartenzeCentralina(id_imp, partenze)
			.then(() => {
				message.success(`Partenze salvate con successo!`)
				onClose()
			})
			.catch(err =>
				message.error(
					`Si è verificato un errore durante il salvataggio delle partenze: ${err.message}`
				)
			)
	}

	return loading ? (
		<Spin />
	) : error ? (
		error.message
	) : partenze ? (
		<PartenzeUI initialData={partenze} onSave={handleSave} onExit={() => onClose()} />
	) : null
}

export const PartenzeModalButton = ({id_imp}) => {
	const [partenzeModalOpen, setPartenzeModalOpen] = useState(false)
	return (
		<>
			<Button onClick={e => setPartenzeModalOpen(true)}>Gestisci</Button>
			<Modal
				width="80%"
				title="Partenze"
				visible={partenzeModalOpen}
				footer={null}
				destroyOnClose={true}
				onCancel={() => setPartenzeModalOpen(false)}
			>
				<PartenzeManager id_imp={id_imp} onClose={() => setPartenzeModalOpen(false)} />
			</Modal>
		</>
	)
}
