import React from 'react'
import {Wrapper} from './common'

export default () => (
	<Wrapper>
		<h1>Contatti</h1>

		<p>
			Per domande sul Catasto Energetico Online o problemi con l'applicazione vi invitiamo a
			contattarci all'indirizzo:
		</p>

		<h2>Roberto Bonanni</h2>
		<p>APE FVG</p>
		<p>T: +39 0432 980 322</p>
		<p>
			E: <a href="mailto:roberto.bonanni@ape.fvg.it">roberto.bonanni@ape.fvg.it</a>
		</p>
		<p>
			W: <a href="http://www.ape.fvg.it">www.ape.fvg.it</a>
		</p>
	</Wrapper>
)
