import React from 'react'
import ReactDOM from 'react-dom'
import 'ol/ol.css'
import './index.scss'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import {AuthProvider} from './lib/auth'

const _send = XMLHttpRequest.prototype.send
XMLHttpRequest.prototype.send = function (...args) {
	this.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
	_send.call(this, ...args)
}

ReactDOM.render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById('root')
)
// registerServiceWorker()
