import React, {useEffect, useMemo, useRef, useState, useReducer} from 'react'
import {Button, Col, Input, Row, Modal, message} from 'antd'
import _ from 'lodash'
import {Switch, Redirect, Route} from 'react-router-dom'
import axios from 'axios'

import Grid from '../../components/Grid'
import {loadFeatures} from '../../services/data'
import {googleLikeFilter, SplitPane, SplitPaneContent, useDebounce} from '../../utils'
import ManutenzioneModalForm from './forms'
import gridConfigs from './grids'
import Map from './map'
import {useSmartState} from '../../utils'
import {useAuth} from '../../lib/auth'

const Title = ({children, style}) => (
	<h3 style={{margin: 0, marginBottom: 5, ...style}}>{children}</h3>
)

const UI = ({mainFeatureType, keyProp}) => {
	const {user} = useAuth()
	if (!mainFeatureType || !user) return null
	const {codistat, center} = user
	return (
		<UICore
			mainFeatureType={mainFeatureType}
			codistat={codistat}
			center={center}
			keyProp={keyProp}
		/>
	)
}

const SelectionTitle = ({featureType}) => {
	switch (featureType) {
		case 'pl':
			return <Title>Punti Luce selezionati</Title>
		case 'linee':
			return <Title>Linee selezionate</Title>
		case 'quadri':
			return <Title>Sottoquadri selezionati</Title>
		case 'centraline':
			return <Title>Centraline selezionate</Title>
		case 'edifici':
			return <Title>Edifici selezionati</Title>
		case 'impianti':
			return <Title>Impianti selezionati</Title>
		default:
			return <Title>Oggetti selezionati</Title>
	}
}

const ManutenzioniPrecedentiTitle = ({featureType}) => {
	switch (featureType) {
		case 'pl':
			return <Title>Manutenzioni precedenti sui Punti Luce selezionati</Title>
		case 'linee':
			return <Title>Manutenzioni precedenti sulle Linee selezionate</Title>
		case 'quadri':
			return <Title>Manutenzioni precedenti sui Sottoquadri selezionati</Title>
		case 'centraline':
			return <Title>Manutenzioni precedenti sulle Centraline selezionate</Title>
		case 'edifici':
			return <Title>Manutenzioni precedenti sugli Edifici selezionati</Title>
		case 'impianti':
			return <Title>Manutenzioni precedenti sugli Impianti selezionati</Title>
		default:
			return <Title>Manutenzioni precedenti sugli oggetti selezionati</Title>
	}
}

const loadManutenzioni = async featureType => axios.get(`/api/manutenzioni/${featureType}`)
const deleteManutenzioni = async ids => axios.post('/api/manutenzioni/elimina', {ids})

const useManutenzioni = featureType => {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		loading: true,
		data: null,
		error: null,
	})
	const invalidate = () => setState({loading: true})
	useEffect(() => {
		state.loading &&
			loadManutenzioni(featureType)
				.then(({data}) => setState({loading: false, data}))
				.catch(err => setState({loading: false, errore: err.message}))
	}, [state.loading])
	return {...state, invalidate}
}

const ManutenzioniView = ({featureType, keyProp, selectionItems, removeFromSelection}) => {
	const {columns, defaultSortOn} = gridConfigs.oggetti[featureType]

	const [selectionSelectedItems, setSelectionSelectedItems] = useState([])
	const [showInsertModal, setShowInsertModal] = useState(false)
	const {loading, data, error, invalidate} = useManutenzioni(featureType)

	const [state, setState] = useSmartState({
		manutenzioniDaConfermareSelection: [],
	})

	const [manutenzioniDaConfermare, manutenzioniPrecedenti, selectionIds] = useMemo(() => {
		let _data = data || []
		const selectionIds = selectionItems.map(x => x[keyProp])
		return [
			_data.filter(m => !m.confermata),
			_data.filter(m => m.confermata && selectionIds.includes(m.id_elemento)),
			selectionIds,
		]
	}, [data, selectionItems])

	return (
		<>
			<div
				style={{
					flex: 1,
					borderBottom: '1px solid #dddddd',
					display: 'flex',
					flexDirection: 'column',
					padding: 10,
				}}
			>
				<SelectionTitle featureType={featureType} />
				<div style={{flex: 1, position: 'relative'}}>
					<Grid
						fit
						style={{
							border: '1px solid #dddddd',
						}}
						columns={columns.selection}
						data={selectionItems}
						defaultSortOn={defaultSortOn}
						rowKey={keyProp}
						selection={selectionSelectedItems}
						onSelectionChange={setSelectionSelectedItems}
					/>
				</div>
				<div style={{paddingTop: 10}}>
					<Button
						onClick={e => {
							removeFromSelection(selectionSelectedItems)
							setSelectionSelectedItems([])
						}}
						disabled={selectionSelectedItems.length === 0}
					>
						Rimuovi dalla selezione
					</Button>
					<Button
						disabled={selectionItems.length === 0}
						onClick={e => setShowInsertModal(true)}
						style={{marginLeft: 10}}
					>
						Inserisci intervento
					</Button>
				</div>
			</div>
			<div
				style={{
					flex: 2,
					display: 'flex',
					flexDirection: 'column',
					padding: 10,
				}}
			>
				{loading || data ? (
					<>
						<ManutenzioniPrecedentiTitle featureType={featureType} />
						<Grid
							style={{
								flex: 1,
								border: '1px solid #dddddd',
							}}
							columns={gridConfigs.manutenzioni}
							data={manutenzioniPrecedenti}
							loading={loading}
							defaultSortOn={defaultSortOn}
							rowKey="id"
						/>
						<Title style={{marginTop: 10}}>Manutenzioni inserite in attesa di conferma</Title>
						<Grid
							style={{
								flex: 1,
								border: '1px solid #dddddd',
							}}
							columns={gridConfigs.manutenzioni}
							data={manutenzioniDaConfermare}
							loading={loading}
							defaultSortOn={defaultSortOn}
							rowKey="id"
							selection={state.manutenzioniDaConfermareSelection}
							onSelectionChange={ids => setState({manutenzioniDaConfermareSelection: ids})}
						/>
						<Button
							style={{alignSelf: 'flex-start', marginTop: 10}}
							disabled={!state.manutenzioniDaConfermareSelection.length}
							onClick={() =>
								Modal.confirm({
									title: 'Sei sicuro?',
									content: `Sei sicuro di voler eliminare gli interventi selezionati?`,
									okText: 'Conferma',
									cancelText: 'Annulla',
									onOk: () => {
										deleteManutenzioni(state.manutenzioniDaConfermareSelection)
											.then(() => {
												message.success(`Manutenzioni eliminate`)
												setState({manutenzioniDaConfermareSelection: []})
												invalidate()
											})
											.catch(err => message.error(`Errore durante l'eliminazione: ${err.message}`))
									},
								})
							}
						>
							Elimina
						</Button>
					</>
				) : (
					error
				)}
			</div>
			{showInsertModal && (
				<ManutenzioneModalForm
					featureType={featureType}
					items={selectionIds}
					onComplete={e => {
						setShowInsertModal(false)
						removeFromSelection(selectionIds)
						invalidate()
					}}
					onCancel={e => setShowInsertModal(false)}
				/>
			)}
		</>
	)
}

const UICore = ({mainFeatureType, keyProp, codistat, center}) => {
	const mapRef = useRef()

	const [mapSelectedItems, setMapSelectedItems] = useState([])
	const [gridSelectedItems, setGridSelectedItems] = useState([])
	const [data, setData] = useState(null)
	const [filter, setFilter] = useState('')
	const debouncedFilter = useDebounce(filter, 200)
	const [selection, setSelection] = useState([])

	const {columns, defaultSortOn} = gridConfigs.oggetti[mainFeatureType]

	async function load() {
		setData(await loadFeatures(mainFeatureType))
	}

	useEffect(() => {
		;(async () => {
			if (!data) load()
		})()
	}, [])

	const [items, itemsMap] = useMemo(() => {
		const items = (data || []).map(feat => feat.properties)
		const itemsMap = items.reduce((acc, next) => ({...acc, [next[keyProp]]: next}), {})
		return [items, itemsMap]
	}, [data])

	function addToSelection() {
		setSelection([...selection, ...gridSelectedItems])
		setGridSelectedItems([])
	}

	function removeFromSelection(ids) {
		setSelection(_.difference(selection, ids))
	}

	const [selectionItems, unselectedItems] = useMemo(
		() => [
			selection.map(id => itemsMap[id]),
			items.filter(item => !selection.includes(item[keyProp])),
		],
		[items, itemsMap, selection]
	)

	const filteredItems = useMemo(
		() =>
			unselectedItems.filter(
				f =>
					googleLikeFilter(debouncedFilter)(f) &&
					(mapSelectedItems.length ? mapSelectedItems.includes(f[keyProp]) : true)
			),
		[debouncedFilter, items, mapSelectedItems, selectionItems]
	)

	return (
		<div
			style={
				{height: '100%', display: 'flex', flex: 1, position: 'relative'} // THIS SEEMS REQUIRED
			}
		>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						// backgroundColor: 'white',
					}}
				>
					<Row>
						<Col span={14} style={{padding: 10, paddingRight: 5}}>
							<Input
								size="default"
								placeholder="Filtro"
								onChange={e => setFilter(e.target.value)}
								value={filter}
								allowClear
							/>
						</Col>
						<Col span={10} style={{padding: 10, paddingLeft: 5}}>
							<Button
								onClick={e => setGridSelectedItems([])}
								disabled={gridSelectedItems.length === 0}
							>
								Deseleziona
							</Button>
							<Button
								onClick={e => addToSelection()}
								disabled={gridSelectedItems.length === 0}
								style={{marginLeft: 10}}
							>
								Aggiungi
							</Button>
						</Col>
					</Row>
					<Row style={{flex: 1}}>
						<Col span={14} style={{height: '100%', position: 'relative'}}>
							<SplitPaneContent>
								<Map
									key={codistat + '_' + mainFeatureType}
									codistat={codistat}
									center={center}
									mainFeatureType={mainFeatureType}
									keyProp={keyProp}
									externalSelection={gridSelectedItems}
									onSelectionChange={setMapSelectedItems}
									currentMode={'view'}
									ref={mapRef}
								/>
							</SplitPaneContent>
						</Col>
						<Col span={10} style={{height: '100%'}}>
							<SplitPaneContent>
								<Grid
									fit
									style={{
										borderTop: '1px solid #dddddd',
									}}
									columns={columns.filter}
									data={filteredItems}
									defaultSortOn={defaultSortOn}
									rowKey={keyProp}
									selection={gridSelectedItems}
									onSelectionChange={setGridSelectedItems}
								/>
							</SplitPaneContent>
						</Col>
					</Row>
				</SplitPaneContent>
				<SplitPaneContent
					style={{
						height: '100%',
						backgroundColor: 'white',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<ManutenzioniView
						featureType={mainFeatureType}
						keyProp={keyProp}
						selectionItems={selectionItems}
						removeFromSelection={removeFromSelection}
					/>
				</SplitPaneContent>
			</SplitPane>
		</div>
	)
}

export default () => (
	<Switch>
		<Route exact path="/manutenzioni/pl" render={() => <UI key="pl" mainFeatureType="pl" keyProp="plid" />} />
		<Route
			exact
			path="/manutenzioni/linee"
			render={() => <UI key="linee" mainFeatureType="linee" keyProp="id_linea" />}
		/>
		<Route
			exact
			path="/manutenzioni/centraline"
			render={() => <UI key="centraline" mainFeatureType="centraline" keyProp="id_imp" />}
		/>
		<Route
			exact
			path="/manutenzioni/quadri"
			render={() => <UI key="quadri" mainFeatureType="quadri" keyProp="id_q_sez" />}
		/>
		<Route
			exact
			path="/manutenzioni/edifici"
			render={() => <UI key="edifici" mainFeatureType="edifici" keyProp="idf" />}
		/>
		<Route
			exact
			path="/manutenzioni/impianti"
			render={() => <UI key="impianti" mainFeatureType="impianti" keyProp="id_imp" />}
		/>
		<Redirect to="/" />
	</Switch>
)
