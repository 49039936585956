import {OSM as OSMSource, BingMaps, TileWMS} from 'ol/source'
import {TileLayerX} from './custom-classes'
import {createGeojsonAPILayer} from './utils'
import styles from '../../../configs/layer-styles'

const createBingLayer = ({name, imagerySet, visible}) =>
	new TileLayerX({
		name,
		visible,
		preload: Infinity,
		source: new BingMaps({
			key: process.env.REACT_APP_BING_API_KEY,
			imagerySet,
			// use maxZoom 19 to see stretched tiles instead of the BingMaps
			// "no photos at this zoom level" tiles
			// maxZoom: 19
		}),
	})

const createCTRNLayer = codistat =>
	new TileLayerX({
		name: 'CTRN',
		source: new TileWMS({
			url: '/geoserver/wms',
			params: {LAYERS: `l_${codistat}_ctrn`, TILED: true, TRANSPARENT: true},
			serverType: 'geoserver',
			// Countries have transparency, so do not fade tiles:
			transition: 0,
		}),
	})
const createCiviciLayer = codistat =>
	new TileLayerX({
		name: 'Numeri Civici',
		source: new TileWMS({
			url: '/geoserver/wms',
			params: {LAYERS: `l_${codistat}_civici`, TILED: true, TRANSPARENT: true},
			serverType: 'geoserver',
			// Countries have transparency, so do not fade tiles:
			transition: 0,
		}),
		maxResolution: 2.4,
	})

const createDefaultOverlayLayers = codistat => ({
	ctrn: createCTRNLayer(codistat),
	civici: createCiviciLayer(codistat),
	confini: createGeojsonAPILayer('confini', {
		name: 'Confini Comune',
		style: styles.borders,
		renderMode: 'image',
	}),
})

export const getDefaultLayersMap = codistat => ({
	baseLayers: {
		osm: new TileLayerX({name: 'OpenStreetMap', source: new OSMSource()}),
		bingRoad: createBingLayer({name: 'Bing Road', imagerySet: 'Road', visible: false}),
		bingHybrid: createBingLayer({
			name: 'Bing Hybrid',
			imagerySet: 'AerialWithLabels',
			visible: false,
		}),
		bingAerial: createBingLayer({name: 'Bing Aerial', imagerySet: 'Aerial', visible: false}),
	},
	overlays: createDefaultOverlayLayers(codistat),
})
