import React, {useState, useRef} from 'react'
import {Button, Popconfirm} from 'antd'
import Icon from '@ant-design/icons'
import Box from 'ui-box'
import styled from 'styled-components'

import * as styles from '../../../../../styles'
import {useSmartState} from '../../../../../utils'

const PartenzeUI = ({initialData, onSave, onExit}) => {
	const columns = [
		{name: 'Via Linea', key: 'via_linea', required: true},
		{name: 'Differenziale', key: 'differenziale_tipo', required: true},
		{name: 'Protezione', key: 'protez_tipo'},
		{name: 'Formazione', key: 'mono_bi_trifase'},
		{name: 'Sez. Fase(mmq)', key: 'sez_fase_mmq'},
		{name: 'Sigla Cavo', key: 'sigla_cavo'},
		{name: 'Codice Linea', key: 'linea_cod'},
		{name: 'Note', key: 'note'},
	]
	const [state, setState] = useSmartState({
		data: initialData,
		newItem: null,
	})

	const Editor = ({initialValue, onOk, onCancel = () => {}, disabled}) => {
		const ref = useRef()
		const [value, setValue] = useState(initialValue)
		return (
			<input
				disabled={disabled}
				style={{
					width: '100%',
					height: '100%',
				}}
				ref={ref}
				value={value}
				onChange={e => setValue(e.target.value)}
				onBlur={() => onOk(value)}
				onKeyDown={e => {
					if (e.key === 'Escape') {
						onCancel()
					}
					if (e.key === 'Enter') {
						onOk(value)
					}
				}}
			/>
		)
	}

	const Table = styled(({className}) => (
		<table className={className}>
			<thead>
				<tr>
					{columns.map((col, colIdx) => (
						<th key={colIdx}>
							{col.required && <span style={{color: 'red'}}>* </span>}
							{col.name}
						</th>
					))}
					<th key="actions-header" />
				</tr>
			</thead>
			<tbody>
				{state.data.map((row, rowIdx) => (
					<tr style={state.newItem ? {pointerEvents: 'none', opacity: 0.4} : null}>
						{columns.map((col, colIdx) => {
							const style = {
								width: '100%',
								height: '100%',
							}
							let cell = (
								<Box style={style}>
									<Editor
										disabled={state.newItem}
										initialValue={row[col.key]}
										onOk={value =>
											setState({
												data: [
													...state.data.slice(0, rowIdx),
													{...state.data[rowIdx], [col.key]: value},
													...state.data.slice(rowIdx + 1),
												],
											})
										}
									/>
								</Box>
							)
							return <td>{cell}</td>
						})}
						<td key={'actions-' + rowIdx}>
							<Popconfirm
								title="Sei sicuro di rimuovere questa partenza?"
								onConfirm={() => setState({data: state.data.filter(x => x !== row)})}
							>
								<Button type="link" size="small">
									<Icon type="delete" />
								</Button>
							</Popconfirm>
						</td>
					</tr>
				))}
				{state.newItem && (
					<tr>
						{columns.map((col, colIdx) => {
							const style = {
								width: '100%',
								height: '100%',
							}
							let cell = (
								<Box style={style}>
									<Editor
										initialValue={state.newItem[col.key] || ''}
										onOk={value => setState({newItem: {...state.newItem, [col.key]: value}})}
									/>
								</Box>
							)
							return <td>{cell}</td>
						})}
						<td key={'actions-newItem'}>
							<Button
								type="link"
								size="small"
								onClick={() => {
									setState({
										data: [...state.data, state.newItem],
										newItem: null,
									})
								}}
								disabled={
									columns
										.filter(col => col.required)
										.map(col => state.newItem[col.key])
										.filter(val => !val).length
								}
							>
								<Icon type="check-circle" />
							</Button>
							<Button
								type="link"
								size="small"
								onClick={() =>
									setState({
										newItem: null,
									})
								}
							>
								<Icon type="close-circle" />
							</Button>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	))`
		width: 100%;
		font-size: 12px;
		thead {
			background-color: #f9f9f9;
		}
		th,
		td input {
			padding: 8px;
		}
		td input {
			// border: none;
		}
		tr,
		td {
			height: 35px;
		}
		td:last-child {
			width: 62px;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
	`
	return (
		<>
			<Table />
			<Box paddingTop={styles.PADDING}>
				<Button
					onClick={() => setState({newItem: {}})}
					style={{marginRight: styles.PADDING}}
					disabled={state.newItem}
				>
					Aggiungi Partenza
				</Button>
				<Button
					onClick={() => setState({data: initialData})}
					style={{marginRight: styles.PADDING}}
					disabled={state.newItem}
				>
					Ripristina
				</Button>
				<Button
					onClick={() => onSave(state.data)}
					style={{marginRight: styles.PADDING}}
					disabled={state.newItem}
				>
					Salva
				</Button>
				<Button onClick={() => onExit()}>Esci</Button>
			</Box>
		</>
	)
}

export default PartenzeUI
