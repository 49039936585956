import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 20px;
	overflow: auto;
	height: 100%;
	background: url(/img/paesaggio-footer.png) bottom no-repeat white;

  h1 {
		font-size: 1.3rem;
	}

  h2 {
		font-size: 1.1rem;
	}
`
