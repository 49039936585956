import React, {useEffect, useState} from 'react'
import {Select, AutoComplete} from 'antd'
import _ from 'lodash'
import {getFeatures} from '../../services/data'

export function CentralinaSelect({value, onChange, ...props}) {
	return (
		<FeatureSelect
			featureType="centraline"
			keyProp="id_imp"
			labelProp="uten"
			value={value}
			onChange={onChange}
			{...props}
		/>
	)
}

export function FeatureSelect({featureType, keyProp, labelProp, value, onChange, ...props}) {
	const [items, setItems] = useState([])
	useEffect(() => {
		getFeatures(featureType).then(items =>
			setItems(
				items.map(i => ({
					label: i.properties[labelProp],
					value: i.properties[keyProp],
				}))
			)
		)
	}, [])
	return (
		<Select showSearch optionFilterProp="label" value={value} onChange={onChange}>
			{items.map((item, idx) => (
				<Select.Option key={idx} value={item.value} label={item.label}>
					{item.label}
				</Select.Option>
			))}
		</Select>
	)
}

export const SmartAutoComplete = ({maxHints = 100, dataSource, value, ...props}) => {
	const [result, setResult] = useState([])
	const handleSearch = value => {
		const result = (dataSource || [])
			.filter(src =>
				src
					? value
						? String(src || '')
								.toLowerCase()
								.indexOf(String(value).toLowerCase()) !== -1
						: true
					: false
	)
			.filter(Boolean)
			.slice(0, maxHints)
		setResult(result)
	}
	useEffect(() => {
		handleSearch('')
	}, [dataSource])
	return (
		<AutoComplete value={!_.isNil(value) ? String(value) : value} {...props} onSearch={handleSearch}>
			{result.map(value => (
				<AutoComplete.Option key={value} value={value}>
					{value}
				</AutoComplete.Option>
			))}
		</AutoComplete>
	)
}


export const checkRequired = value => (value ? undefined : 'Questo campo è obbligatorio')
