import { Style, RegularShape, Stroke, Fill } from 'ol/style';

export default new Style({
	image: new RegularShape({
		points: 4,
		radius: 8,
		rotation: (45 * Math.PI) / 180,
		stroke: new Stroke({
			color: 'black',
			width: 0.5,
		}),
		fill: new Fill({
			color: '#CC5500',
		}),
	}),
});
