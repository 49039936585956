import React, {useEffect, useRef, forwardRef, useMemo} from 'react'
import _ from 'lodash'
import * as ol from 'ol'
import {Vector as VectorSource} from 'ol/source'
import {defaults as defaultControls, ZoomSlider} from 'ol/control'
import {GeoJSON} from 'ol/format'

import {MapControlBar, LayerSwitcher, FullScreenControl} from '../../common/map/new-controls'
import {highlightedSelectionStyle, externalSelectionStyle} from '../../common/map/styles'
import styles from '../../../configs/layer-styles'
import {centerViewOnFeatures, createGeojsonAPILayer} from '../../common/map/utils'
import {VectorLayerX} from '../../common/map/custom-classes'
import {getDefaultLayersMap} from '../../common/map/layers'

import '../../common/map/style.css'

const SECONDARY_LAYER_OPACITY = 1

const LayerDescriptor = [
	{
		name: 'Linee',
		type: 'linee',
	},
	{
		name: 'Centraline',
		type: 'centraline',
	},
	{
		name: 'Punti Luce',
		type: 'pl',
	},
	{
		name: 'Quadri',
		type: 'quadri',
	},
	{
		name: 'Edifici',
		type: 'edifici',
	},
	{
		name: 'Impianti',
		type: 'impianti',
	},
]

function getLayers({codistat}) {
	const layersMap = getDefaultLayersMap(codistat)
	const baseLayers = Object.values(layersMap.baseLayers)
	const defaultOverlays = Object.values(layersMap.overlays)
	const supportOverlays = LayerDescriptor.map(({name, type}) =>
		createGeojsonAPILayer(type, {
			name,
			style: styles[type],
			opacity: SECONDARY_LAYER_OPACITY,
		})
	)
	const maintenanceInterventionsLayer = new VectorLayerX({
		source: new VectorSource({}),
		opacity: 0,
	})
	return {baseLayers, defaultOverlays, supportOverlays, maintenanceInterventionsLayer}
}

export default forwardRef(
	(
		{
			maintenanceInterventions,
			center,
			codistat,
			externalSelection,
			highlightedSelection = [],
			displayHighlightedSelection = false,
		},
		_ref
	) => {
		const container = useRef()
		const {
			map,
			defaultOverlays,
			supportOverlays,
			maintenanceInterventionsLayer,
			baseLayers,
			externalSelectionSource,
			externalSelectionOverlay,
			highlightedSelectionSource,
			highlightedSelectionOverlay,
		} = useMemo(() => {
			const {
				baseLayers,
				defaultOverlays,
				supportOverlays,
				maintenanceInterventionsLayer,
			} = getLayers({
				codistat,
			})
			const externalSelectionSource = new VectorSource()
			const externalSelectionOverlay = new VectorLayerX({
				source: externalSelectionSource,
				style: externalSelectionStyle,
			})
			const highlightedSelectionSource = new VectorSource()
			const highlightedSelectionOverlay = new VectorLayerX({
				source: highlightedSelectionSource,
				style: highlightedSelectionStyle,
			})
			const map = new ol.Map({
				view: new ol.View({
					projection: 'EPSG:900913',
					center,
					zoom: 11,
				}),
				controls: defaultControls({
					attributionOptions: {
						collapsible: false,
					},
				}).extend([new ZoomSlider()]),
				layers: [
					...baseLayers,
					...defaultOverlays,
					...supportOverlays,
					maintenanceInterventionsLayer,
					highlightedSelectionOverlay,
					externalSelectionOverlay,
				],
			})
			return {
				map,
				defaultOverlays,
				supportOverlays,
				maintenanceInterventionsLayer,
				baseLayers,
				externalSelectionSource,
				externalSelectionOverlay,
				highlightedSelectionSource,
				highlightedSelectionOverlay,
			}
		}, [])

		useEffect(() => {
			map.setTarget(container.current)
		}, [])

		useEffect(() => {
			const src = maintenanceInterventionsLayer.getSource()
			src.clear()
			const data = maintenanceInterventions || []
			const geoJSON = new GeoJSON()
			src.addFeatures(
				data
					.filter(d => _.has(d, 'elemento.the_geom'))
					.map(d => {
						const feature = geoJSON.readFeatureFromObject(d.elemento.the_geom)
						feature.setProperties(d)
						feature.setId(d.id)
						return feature
					})
			)
		}, [maintenanceInterventions])

		function displayExternalSelection() {
			const ids = externalSelection.map(id => parseInt(id))
			externalSelectionSource.clear()
			const feats = maintenanceInterventionsLayer
				.getSource()
				.getFeatures()
				.filter(feat => ids.includes(parseInt(feat.getId())))

			externalSelectionOverlay.getSource().addFeatures(feats)
		}

		useEffect(() => {
			displayExternalSelection()
			centerOnSelection()
		}, [externalSelection])

		function doDisplayHighlightedSelection() {
			const ids = highlightedSelection.map(id => parseInt(id))
			highlightedSelectionSource.clear()
			if (displayHighlightedSelection) {
				const feats = maintenanceInterventionsLayer
					.getSource()
					.getFeatures()
					.filter(feat => ids.includes(parseInt(feat.getId())))

				highlightedSelectionOverlay.getSource().addFeatures(feats)
				centerOnSelection()
			}
		}

		useEffect(() => {
			doDisplayHighlightedSelection()
		}, [highlightedSelection, displayHighlightedSelection])

		const centerOnSelection = () =>
			centerViewOnFeatures(map, [externalSelectionOverlay, highlightedSelectionOverlay])

		const overlays = [...defaultOverlays, ...supportOverlays]
		const controlBar = (
			<MapControlBar>
				<FullScreenControl container={container} />
				<LayerSwitcher
					defaultActiveOverlays={overlays.map((_, i) => i)}
					onBaseLayerChange={index => {
						baseLayers[index] && baseLayers[index].setVisible(true)
						baseLayers.filter((_, i) => i !== index).forEach(layer => layer.setVisible(false))
					}}
					onOverlaysChange={indexes => {
						overlays.forEach((overlay, i) => overlay.setVisible(indexes.includes(i)))
					}}
					baseLayers={[...baseLayers.map(({name}) => ({name: name})), {name: 'None'}]}
					overlays={overlays.map(({name}) => ({name: name}))}
				/>
			</MapControlBar>
		)

		return (
			<>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'relative',
						background: '#f0f2f5',
					}}
					ref={container}
				>
					{controlBar}
				</div>
			</>
		)
	}
)
