import React, {useEffect, useReducer, useContext} from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {notification} from 'antd'
import _ from 'lodash'

const AUTH_COOKIE = 'cadaster.auth'

const logout = async () =>
	axios
		.post('/api/logout')
		.then(res => {
			cookie.remove(AUTH_COOKIE)
			window.location.pathname = '/login'
		})
		.catch(err => {
			console.error(err)
			notification.error({
				message: `Error logging out!`,
			})
		})

export const Logout = () => {
	useEffect(() => {
		logout()
	}, [])
	return null
}

const AuthContext = React.createContext()
export const AuthProvider = ({children}) => {
	const [{user, ...state}, setState] = useReducer(
		(oldState, newState) => ({...oldState, ...newState}),
		{
			bootstrapping: true,
			user: null,
			error: null,
		}
	)

	useEffect(() => {
		axios
			.get('/api/v2.0/me')
			.then(({data}) => {
				setState({user: data, bootstrapping: false})
			})
			.catch(error => {
				console.log(error)
				if ([401, 403].includes(error.response.status)) {
					console.error('Unauthenticated')
				} else {
					console.log('Some othe error occurred')
				}
				setState({bootstrapping: false, error})
			})
	}, [])

	const userCanAny = capabilities => {
		if (!user) return false
		return _.intersection(_.get(user, 'capabilities', []), capabilities).length > 0
	}

	const userCan = capability => {
		if (!user) return false
		return _.get(user, 'capabilities', []).includes(capability)
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				...state,
				logout,
				userCan,
				userCanAny,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider')
	}
	return context
}
