export const primaryKey = 'id_q_sez'
export const defaultSortOn = 'linea_cod'

export const columns = {
	filter: [
		{
			key: 'centr_sez_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
	],
	selection: [
		{
			key: 'centr_sez_cod',
			name: 'Codice',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'descrizione',
			name: 'Descrizione',
		},
	],
}
