import React from 'react'
import {Alert, Table} from 'antd'
import Button from 'antd/es/button'
import ReactECharts from 'echarts-for-react'
import {AGGREGAZIONI, ENERGIA_RILEVANTE_PER_CONVENZIONE, PANEL_HEIGHT} from '../common'
import {GRID_BACKGROUND_COLOR} from '../../../../styles'

const chartsDefaultOptions = {
	grid: {
		left: 70,
		top: 20,
		right: 20,
		bottom: 30,
	},
}

export const ContractDetailsStateless = ({
	aggregatedData,
	aggregation,
	convention,
	otherPodsInvolved = [],
	onViewOtherPod = null,
}) => {
	return (
		<>
			{otherPodsInvolved && otherPodsInvolved.length ? (
				<div style={{padding: 10}}>
					{convention !== 'SSA' ? (
						<Alert
							type="error"
							message={
								<>
									<span>Altri impianti coinvolti:</span>
									{otherPodsInvolved.map(pod => (
										<Button
											danger
											key={pod}
											style={{marginLeft: 10}}
											onClick={() => onViewOtherPod?.(pod)}
										>
											{pod}
										</Button>
									))}
								</>
							}
						/>
					) : (
						<>
							<span>Altri impianti coinvolti:</span>
							{otherPodsInvolved.map(pod => (
								<Button
									key={pod}
									style={{marginLeft: 10}}
									onClick={() => onViewOtherPod?.(pod)}
								>
									{pod}
								</Button>
							))}
						</>
					)}
				</div>
			) : null}
			<div style={{display: 'flex', height: PANEL_HEIGHT - 24 * 2}}>
				<Table
					rowKey="period"
					style={{
						width: '50%',
						backgroundColor: GRID_BACKGROUND_COLOR,
					}}
					scroll={{y: PANEL_HEIGHT - 24 * 2 - 39}}
					size="small"
					bordered
					pagination={false}
					columns={[
						{
							title: AGGREGAZIONI[aggregation],
							dataIndex: 'period',
							key: 'period',
						},
						{
							title: 'Corrispettivo',
							dataIndex: 'corrispettivo',
							key: 'corrispettivo',
						},
						{
							title: ENERGIA_RILEVANTE_PER_CONVENZIONE[convention],
							dataIndex: 'energia',
							key: 'energia',
						},
					]}
					dataSource={aggregatedData}
				/>
				<div
					style={{
						height: '100%',
						width: '50%',
						backgroundColor: 'aliceblue',
					}}
				>
					<ReactECharts
						style={{
							height: '50%',
							width: '100%',
						}}
						option={{
							...chartsDefaultOptions,
							yAxis: {
								type: 'value',
								axisLabel: {
									formatter: '{value} kWh',
								},
							},
							xAxis: {
								type: 'category',
								data: aggregatedData.map(d => d.period),
							},
							series: [
								{
									data: aggregatedData.map(d => d.energia),
									type: 'bar',
									color: '#E8346E',
								},
							],
						}}
					/>
					<ReactECharts
						style={{
							height: '50%',
							width: '100%',
						}}
						option={{
							...chartsDefaultOptions,
							yAxis: {
								type: 'value',
								axisLabel: {
									formatter: '{value} €',
								},
							},
							xAxis: {
								type: 'category',
								data: aggregatedData.map(d => d.period),
							},
							series: [
								{
									data: aggregatedData.map(d => d.corrispettivo),
									type: 'bar',
									color: '#4BA4ED',
								},
							],
						}}
					/>
				</div>
			</div>
		</>
	)
}
