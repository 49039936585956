import XLSX from 'xlsx'
import {saveAs} from 'file-saver'

function saveXLSX(wbout, filename) {
	function s2ab(s) {
		var buf = new ArrayBuffer(s.length)
		var view = new Uint8Array(buf)
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
		return buf
	}

	saveAs(
		new Blob([s2ab(wbout)], {
			type: 'application/octet-stream',
		}),
		filename
	)
}

interface XLSXExportOptions {
	sheets: {
		title?: string
		data: object[]
	}[]
	title?: string
	filename?: string
}

export function exportXLSX({
	sheets,
	title = 'Export',
	filename = 'export.xlsx',
}: XLSXExportOptions) {
	var wb = XLSX.utils.book_new()
	wb.Props = {
		Title: title,
		CreatedDate: new Date(),
	}
	sheets.forEach(({title, data}) => {
		wb.SheetNames.push(title)
		wb.Sheets[title] = XLSX.utils.json_to_sheet(data)
	})
	var wbout = XLSX.write(wb, {
		bookType: 'xlsx',
		type: 'binary',
	})
	saveXLSX(wbout, filename)
}
