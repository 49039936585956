export const primaryKey = 'id_imp'
export const defaultSortOn = 'uten'

export const columns = {
	filter: [
		{
			key: 'uten',
			name: 'Codice',
		},
		{
			key: 'indir',
			name: 'Indirizzo',
		},
	],
	selection: [
		{
			key: 'uten',
			name: 'Codice',
		},
		{
			key: 'indir',
			name: 'Indirizzo',
		},
		{
			key: 'pod_pdr_cont',
			name: 'POD',
		},
		{
			key: 'd4_reg',
			name: 'Regolatore',
		},
	],
}
