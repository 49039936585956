import { CellEditor, CellRenderer } from './cell-renderers';

export const primaryKey = 'plid';
export const defaultSortOn = 'pl_cod';

export const columnGroups = [
	{
		name: 'punto_luce',
		label: 'Punto Luce',
		keys: ['pl_cod', 'pl_tipo', 'sostegno_tipo', 'toponimo', 'lampot'],
	},
	{
		name: 'corpo',
		label: 'Corpo',
		keys: ['pl_cod', 'lamp_nr', 'corpi_nr', 'corpo_tipo', 'coppa_tipo', 'coppa_conserv'],
	},
	{
		name: 'alimentazione',
		label: 'Alimentazione',
		keys: [
			'pl_cod',
			'alim_linea_cod',
			'alim_tipo',
			'mors_pot_posiz',
			'isol_classe',
			'alimentat_tipo',
			'telecontrollo',
			'regolaz_cod',
		],
	},
	{
		name: 'sostegno',
		label: 'Sostegno',
		keys: [
			'pl_cod',
			'sostegno_tipo',
			'sost_materiale',
			'sost_conserv',
			'altezza_pl',
			'sbraccio_lung',
			'arretram_ciglio',
		],
	},
	{
		name: 'supplemento_gestione',
		label: 'Suppl. Gestione',
		keys: [
			'pl_cod',
			'id_imp',
			'data_impianto',
			'data_attvaz',
			'data_eliminaz',
			'codistat',
			'localita',
		],
	},
	{
		name: 'supplemento_tecnico',
		label: 'Suppl. Tecnico',
		keys: ['pl_cod', 'lamp_tipo', 'lamp_pot', 'lamp_pot_ass', 'eff_illumin', 'note'],
	},
];

export const columns = {
	'*': [
		{
			key: 'pl_cod',
			name: 'Codice',
			width: 65,
		},
		{
			key: 'pl_tipo',
			name: 'Tipo Punto Luce',
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
		},
		{
			key: 'alim_tipo',
			name: 'Tipo Alimentazione',
		},
		{
			key: 'alim_linea_cod',
			name: 'Codice Linea',
		},
		{
			key: 'lamp_tipo',
			name: 'Tipo Lampada',
		},
		{
			key: 'lamp_pot',
			name: 'Potenza Lampada',
		},
		{
			key: 'lamp_pot_ass',
			name: 'Potenza Punto Luce',
		},
		{
			key: 'eff_illumin',
			name: 'Efficienza Illuminotecnica',
		},
		{
			key: 'lampot',
			name: 'Sigla',
			width: 65,
		},
		{
			key: 'lamp_nr',
			name: 'Numero Lampade',
		},
		{
			key: 'corpo_tipo',
			name: 'Tipo Corpo',
		},
		{
			key: 'corpi_nr',
			name: 'Numero Corpi',
		},
		{
			key: 'corpo_inclin',
			name: 'Inclinato',
			formatter: CellRenderer.Checkbox,
		},
		{
			key: 'sbraccio_lung',
			name: 'Lunghezza Sbraccio',
		},
		{
			key: 'coppa_tipo',
			name: 'Tipo Coppa',
		},
		{
			key: 'coppa_conserv',
			name: 'Stato Conservazione Coppa',
		},
		{
			key: 'sostegno_tipo',
			name: 'Tipo Sostegno',
		},
		{
			key: 'sost_materiale',
			name: 'Materiale Sostegno',
		},
		{
			key: 'sost_conserv',
			name: 'Stato Conservazione Sostegno',
		},
		{
			key: 'altezza_pl',
			name: 'Altezza',
		},
		{
			key: 'interdistanza',
			name: 'Interdistanza',
		},
		{
			key: 'arretram_ciglio',
			name: 'Arretramento Ciglio',
		},
		{
			key: 'mors_pot_posiz',
			name: 'Posizione Morsettiera',
		},
		{
			key: 'isol_classe',
			name: 'Classe Isolamento',
		},
		{
			key: 'alimentat_tipo',
			name: 'Tipo Alimentatore',
		},
		{
			key: 'telecontrollo',
			name: 'Telecontrollo',
			formatter: CellRenderer.Checkbox,
		},
		{
			key: 'conforme_leggi',
			name: 'Conforme Leggi',
			formatter: CellRenderer.Checkbox,
		},
		{
			key: 'data_impianto',
			name: 'Data Impianto',
		},
		{
			key: 'note',
			name: 'Note',
		},
		{
			key: 'riferimenti',
			name: 'Riferimenti',
		},
		{
			key: 'localita',
			name: 'Località',
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
		},
		{
			key: 'regolaz_cod',
			name: 'Codice Riduzione',
		},
	],
	insert: [
		{
			key: 'pl_cod',
			name: 'Codice',
			width: 65,
		},
		{
			_key: 'id_imp',
			key: '_centralina',
			name: 'Centralina',
			width: 70,
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
			editable: true,
		},
		{
			key: 'pl_tipo',
			name: 'Tipo Punto Luce',
			editable: true,
		},
		{
			key: 'corpo_tipo',
			name: 'Tipo Corpo',
			editable: true,
		},
		{
			key: 'lampot',
			name: 'Sigla Lampada',
			editable: true,
		},
	],
	'complete-inserts': [
		{
			key: 'pl_cod',
			name: 'Codice',
			width: 65,
		},
		{
			key: 'pl_tipo',
			name: 'Tipo Punto Luce',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'id_imp',
			name: 'Centralina',
			editableCellTemplate: CellEditor.Centralina,
			editable: true,
		},
		{
			key: 'alim_tipo',
			name: 'Tipo Alimentazione',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'alim_linea_cod',
			name: 'Codice Linea',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'lamp_tipo',
			name: 'Tipo Lampada',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'lamp_pot',
			name: 'Potenza Lampada',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'lamp_pot_ass',
			name: 'Potenza Punto Luce',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'eff_illumin',
			name: 'Efficienza Illuminotecnica',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'lampot',
			name: 'Sigla',
			width: 65,
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'lamp_nr',
			name: 'Numero Lampade',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'corpo_tipo',
			name: 'Tipo Corpo',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'corpi_nr',
			name: 'Numero Corpi',
			editable: true,
			editableCellTemplate: CellEditor.Number,
		},
		{
			key: 'corpo_inclin',
			name: 'Inclinato',
			formatter: CellEditor.Checkbox,
			editable: true,
		},
		{
			key: 'sbraccio_lung',
			name: 'Lunghezza Sbraccio',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'coppa_tipo',
			name: 'Tipo Coppa',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'coppa_conserv',
			name: 'Stato Conservazione Coppa',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'sostegno_tipo',
			name: 'Tipo Sostegno',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'sost_materiale',
			name: 'Materiale Sostegno',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'sost_conserv',
			name: 'Stato Conservazione Sostegno',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'altezza_pl',
			name: 'Altezza',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'interdistanza',
			name: 'Interdistanza',
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			key: 'arretram_ciglio',
			name: 'Arretramento Ciglio',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'mors_pot_posiz',
			name: 'Posizione Morsettiera',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'isol_classe',
			name: 'Classe Isolamento',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'alimentat_tipo',
			name: 'Tipo Alimentatore',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'telecontrollo',
			name: 'Telecontrollo',
			formatter: CellEditor.Checkbox,
			editable: true,
		},
		{
			key: 'conforme_leggi',
			name: 'Conforme Leggi',
			formatter: CellEditor.Checkbox,
			editable: true,
		},
		{
			key: 'data_impianto',
			name: 'Data Impianto',
			editableCellTemplate: CellEditor.Date,
			editable: true,
		},
		{
			key: 'note',
			name: 'Note',
			editableCellTemplate: CellEditor.Text,
			editable: true,
		},
		{
			key: 'riferimenti',
			name: 'Riferimenti',
			editableCellTemplate: CellEditor.Text,
			editable: true,
		},
		{
			key: 'localita',
			name: 'Località',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'toponimo',
			name: 'Indirizzo',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			key: 'regolaz_cod',
			name: 'Codice Riduzione',
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
	],
};
