import React from 'react'
import {Select, Input, Form as AntForm, DatePicker, Modal} from 'antd'
import {Form, Field} from 'react-final-form'
import _ from 'lodash'

import {SmartAutoComplete, checkRequired} from '../../common/forms'
import {insertManutenzione} from '../../../services/data'
import {openNotificationWithIcon} from '../../common/notifications'
import {Col, Row} from '../../infrastruttura/forms/utils'

const FormItem = AntForm.Item
const {TextArea} = Input

const formItem = ({
	label,
	name,
	required = false,
	isNumber = false,
	Component = null,
	render = null,
	dataSource = null,
}) => {
	return (
		<Field name={name} validate={required ? checkRequired : () => null}>
			{({input, meta}) => {
				let rendered
				if (render) {
					rendered = render(input)
				} else if (isNumber) {
					rendered = <Input {...input} type="number" />
				} else if (Component) {
					rendered = <Component {...input} />
				} else if (dataSource) {
					rendered = <SmartAutoComplete {...input} {...dataSource(name)} />
				} else {
					rendered = <Input {...input} />
				}
				return (
					<FormItem
						label={label}
						required={required}
						validateStatus={meta.error && meta.touched ? 'error' : ''}
						help={meta.touched && meta.error}
					>
						{rendered}
					</FormItem>
				)
			}}
		</Field>
	)
}

const TIPI_INTERVENTI = ['ordinaria programmata', 'straordinaria programmata', 'pronto intervento']

const ManutenzioneModalForm = ({featureType, items, onComplete, onCancel}) => {
	const onSave = async values => {
		try {
			await insertManutenzione(featureType, {...values, items})
			openNotificationWithIcon({
				type: 'success',
				title: 'Manutenzione inserita',
				message: `L'intervento è stato inserito con successo`,
			})
			onComplete()
		} catch (err) {
			openNotificationWithIcon({
				type: 'error',
				title: `Errore nel salvataggio`,
				message: `Si è verificato un errore nel salvataggio. L'intervento non è stato inserito.`,
			})
		}
	}
	return (
		<Form onSubmit={onSave}>
			{({handleSubmit, values}) => (
				<Modal
					title="Inserisci Intervento"
					visible={true}
					onOk={handleSubmit}
					onCancel={
						onCancel // confirmLoading={true}
					}
				>
					<AntForm layout="vertical">
						<Row>
							<Col>
								{formItem({label: 'Operatore', name: 'operatore', required: true})}
							</Col>
						</Row>
						<Row>
							<Col>
								{formItem({
									label: 'Tipo Intervento',
									name: 'tipo_intervento',
									required: true,
									render: input => (
										<Select {...input}>
											{TIPI_INTERVENTI.map(tipo => (
												<Select.Option key={tipo} value={tipo}>
													{tipo}
												</Select.Option>
											))}
										</Select>
									),
								})}
							</Col>
							<Col>
								{formItem({
									label: 'Data Conclusione',
									name: 'data_fine',
									required: true,
									Component: DatePicker,
								})}
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								{formItem({
									label: 'Descrizione',
									name: 'descrizione',
									required: true,
									Component: TextArea,
								})}
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								{formItem({
									label: 'Attrezzatura',
									name: 'attrezzatura',
									Component: TextArea,
								})}
							</Col>
						</Row>
					</AntForm>
				</Modal>
			)}
		</Form>
	)
}

export default ManutenzioneModalForm
