import React from 'react'
import _ from 'lodash'

export const InfoImpianto = ({impianto: {pod_pdr_cont, presa_al, uten, indir, um, vett, note}}) => (
	<>
		{/* {JSON.stringify(impianto)} */}
		<p>
			<strong>Impianto selezionato</strong>
		</p>
		<p>{_.compact([pod_pdr_cont, presa_al]).join(' | ')}</p>
		<p>{uten}</p>
		<p>{indir}</p>
		<p>{_.compact([um, vett]).join(' - ')}</p>
		<p>{note}</p>
	</>
)
