import axios from 'axios';

import Loader from './cached-loader';
import { useEffect, useState } from 'react';

const ensureFeatureLoaderExists = featureType => {
	if (!Loader.get(featureType)) {
		Loader.create(featureType, () =>
			axios({
				method: 'get',
				url: `/api/v2.0/${featureType}`,
			}).then(({ data }) => data.features)
		);
	}
};

export const getFeatures = async featureType => {
	ensureFeatureLoaderExists(featureType);
	return Loader.read(featureType);
};

export const getFeaturesMapByKey = async (featureType, key) => {
	ensureFeatureLoaderExists(featureType);
	return Loader.readMap(featureType, key);
};

export const loadFeatures = async featureType => {
	switch (featureType) {
		case 'centraline':
		case 'edifici':
		case 'impianti':
			return getFeatures(featureType);
		default:
			const [centralineMap, items] = await Promise.all([
				getFeaturesMapByKey('centraline', 'id_imp'),
				getFeatures(featureType),
			]);
			return items.map(f => {
				const centralina = centralineMap[f.properties.id_imp];
				return {
					...f,
					properties: { ...f.properties, _centralina: centralina ? centralina.properties.uten : null },
				};
			});
	}
};

export const insertFeature = async (featureType, geoJson) =>
	axios({
		method: 'post',
		url: `/api/v2.0/${featureType}`,
		data: geoJson,
	})
		.then(() => {
			Loader.clear(featureType);
			return { success: true };
		})
		.catch(err => ({
			success: false,
			error: err,
		}));

export const updateFeature = async (featureType, id, geoJson) =>
	axios({
		method: 'put',
		url: `/api/v2.0/${featureType}/${id}`,
		data: geoJson,
	})
		.then(() => {
			Loader.clear(featureType);
			return { success: true };
		})
		.catch(err => ({
			success: false,
			error: err,
		}));

export const deleteFeature = async (featureType, id) =>
	axios({
		method: 'delete',
		url: `/api/v2.0/${featureType}/${id}`,
	})
		.then(() => {
			Loader.clear(featureType);
			return { success: true };
		})
		.catch(err => ({
			success: false,
			error: err,
		}));

export const insertManutenzione = async (featureType, data) =>
	axios({
		method: 'post',
		url: `/api/manutenzioni/${featureType}`,
		data,
	})
		.then(() => {
			// Loader.clear(featureType);
			return { success: true };
		})
		.catch(err => ({
			success: false,
			error: err,
		}));

export const fetchPartenzeCentralina = async centralina =>
	axios({
		method: 'get',
		url: `/api/partenze/${centralina}`,
	})
		.then(({ data }) => data)
		.catch(err => ({ success: false, error: err }));

export const savePartenzeCentralina = async (id_imp, partenze) =>
	axios({
		method: 'post',
		url: `/api/v2.0/partenze`,
		data: {id_imp, partenze},
	})
		.then(() => {
			// Loader.clear(featureType);
			return { success: true };
		})
		.catch(err => ({
			success: false,
			error: err,
		}));

export function useVettoriEnergetici() {
	const [vettori, setVettori] = useState(null)
	const [error, setError] = useState(null)
	useEffect(() => {
		setError(null)
		fetchVettoriEnergetici()
			.then(vettori => setVettori(vettori))
			.catch(err => setError(err))
	}, [])
	return { vettori, error }
}

export async function fetchVettoriEnergetici() {
	const { data } = await axios.get("/api/v2.0/vettori")
	return data
}