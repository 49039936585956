import React from 'react';

export const CellEditor = {
	Centralina: ({ value, ...props }) => <div>Edit Centr: {value}</div>,
	Checkbox: ({ value, ...props }) => (
		<div>
			<input type="checkbox" checked={!!value} />
		</div>
	),
	Hinted: ({ value, ...props }) => <div>{value}</div>,
	Text: ({ value, ...props }) => <div>{value}</div>,
	Number: ({ value, ...props }) => <div>{value}</div>,
	Date: ({ value, ...props }) => <div>{value}</div>,
};

export const CellRenderer = {
	Centralina: ({ value, ...props }) => <div>Centr: {JSON.stringify(props)}</div>,
	Checkbox: ({ value, ...props }) => (
		<div>
			<input type="checkbox" disabled checked={!!value} readOnly />
		</div>
	),
};
