import React from 'react'
import {Breadcrumb} from 'antd'
import {withRouter} from 'react-router-dom'

export default withRouter(({location: {pathname}}) => {
	let breadcrumbs
	switch (pathname) {
		case '/edifici-e-impianti/edifici':
			breadcrumbs = ['Gestione Edifici']
			break
		case '/edifici-e-impianti/impianti':
			breadcrumbs = ['Gestione Impianti']
			break
		case '/struttura/pl':
			breadcrumbs = ['Modifiche e Inserimenti', 'Punti Luce']
			break
		case '/struttura/centraline':
			breadcrumbs = ['Modifiche e Inserimenti', 'Centraline']
			break
		case '/struttura/quadri':
			breadcrumbs = ['Modifiche e Inserimenti', 'Quadri di Sezionamento']
			break
		case '/struttura/linee':
			breadcrumbs = ['Modifiche e Inserimenti', 'Linee di Alimentazione']
			break
		case '/manutenzioni/pl':
			breadcrumbs = ['Manutenzioni', 'Punti Luce']
			break
		case '/manutenzioni/centraline':
			breadcrumbs = ['Manutenzioni', 'Centraline']
			break
		case '/manutenzioni/quadri':
			breadcrumbs = ['Manutenzioni', 'Quadri di Sezionamento']
			break
		case '/manutenzioni/linee':
			breadcrumbs = ['Manutenzioni', 'Linee di Alimentazione']
			break
		case '/manutenzioni/edifici':
			breadcrumbs = ['Manutenzioni', 'Edifici']
			break
		case '/manutenzioni/impianti':
			breadcrumbs = ['Manutenzioni', 'Impianti']
			break
		case '/verifica-manutenzioni':
			breadcrumbs = ['Manutenzioni', 'Conferma Manutenzioni']
			break
		case '/interrogazioni/pl':
			breadcrumbs = ['Interrogazioni', 'Punti Luce']
			break
		case '/interrogazioni/manutenzioni':
			breadcrumbs = ['Interrogazioni', 'Manutenzioni']
			break
		case '/consumi/bollette':
			breadcrumbs = ['Consumi', 'Inserimento Nuove Bollette']
			break
		case '/consumi/analisi':
			breadcrumbs = ['Consumi', 'Analisi']
			break
		default:
			switch (true) {
				case /\/edifici-e-impianti\/edifici\/.+/.test(pathname):
					breadcrumbs = ['Associazione Impianti a Edificio']
					break
				case /\/edifici-e-impianti\/impianti\/.+/.test(pathname):
					breadcrumbs = ['Associazione Edifici a Impianto']
					break
				default:
					breadcrumbs = []
			}
	}
	return (
		<Breadcrumb separator=">" style={{display: 'inline-block'}}>
			{breadcrumbs.map(b => (
				<Breadcrumb.Item key={b}>{b}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	)
})
