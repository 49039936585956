import React, {useState, useEffect, useRef} from 'react'
import {Button, Input, message} from 'antd'
import _ from 'lodash'
import Box from 'ui-box'
import axios from 'axios'
import {saveAs} from 'file-saver'

import Map from './map'
import {
	SplitPane,
	SplitPaneContent,
	googleLikeFilter,
	useData,
	useDebouncedFilter,
} from '../../../utils'
import Grid from '../../../components/Grid'
import {UIMode, modes} from '../utils'
import columns from '../../../configs/grids/manutenzioni'
import {enrichManutenzione} from '../../../utils/manutenzioni'
import * as styles from '../../../styles'
import {useAuth} from '../../../lib/auth'
import {PROPS_LABELS} from '../../../constants'

const Grids = ({
	currentMode,
	filteredFeatures,
	keyProp,
	setGridSelectedItems,
	highlightedSelectionFeatures,
}) => {
	const defaultSortOn = 'id'
	return (
		<>
			{currentMode === UIMode.SELECT ? (
				<Grid
					style={{flex: 1, position: 'relative'}}
					columns={columns}
					data={filteredFeatures}
					minColumnWidth={120}
					defaultSortOn={defaultSortOn}
					rowKey={keyProp}
					onSelectionChange={setGridSelectedItems}
					selectionFrozen={currentMode === UIMode.MODIFY}
				/>
			) : (
				<Grid
					style={{flex: 1, position: 'relative'}}
					columns={columns}
					data={highlightedSelectionFeatures}
					minColumnWidth={120}
					defaultSortOn={defaultSortOn}
					rowKey={keyProp}
					onSelectionChange={setGridSelectedItems}
					selectionFrozen={currentMode === UIMode.MODIFY}
					groupBy={['query']}
				/>
			)}
		</>
	)
}

const InterrogazioniManutenzioni = () => {
	const keyProp = 'id'
	const {user} = useAuth()
	const {codistat, center} = user
	const mapRef = useRef()

	const [currentMode, setCurrentMode] = useState(modes[0])

	const {data} = useData('/api/manutenzioni/comune', {
		unwrapper: ({data}) => data.map(x => ({...x, ...enrichManutenzione(x)})),
	})
	const [mapSelectedItems, setMapSelectedItems] = useState([])
	const [gridSelectedItems, setGridSelectedItems] = useState([])
	const {filter, debouncedFilter, setFilter} = useDebouncedFilter()
	const [filteredFeatures, setFilteredFeatures] = useState([])
	const [selection, setSelection] = useState([])

	useEffect(() => {
		const filter = googleLikeFilter(debouncedFilter)
		const filteredFeatures = (data || []).filter(
			x =>
				filter({...x, ...x.elemento}) &&
				(mapSelectedItems.length ? mapSelectedItems.includes(x[keyProp]) : true)
		)
		setFilteredFeatures(filteredFeatures)
	}, [debouncedFilter, data, mapSelectedItems])

	function addCurrentFilterToSelection() {
		const currentFilter = {
			filter,
			filteredFeatures,
		}
		setSelection([...selection, currentFilter])
		setFilter('')
	}

	function clearSelection() {
		setSelection([])
	}

	function exportSelection() {
		const data = _.flatten(
			selection.map(({filter, filteredFeatures}) =>
				filteredFeatures.map(feature => {
					const desiredValues = _.pick(feature, _.keys(PROPS_LABELS.manutenzioni))
					desiredValues.codice = feature.codice
					desiredValues.confermata = desiredValues.confermata ? 'SI' : 'NO'
					desiredValues.data_registrazione = feature.created_at
					const finalObject = {
						query: filter,
						'Tipo Elemento': {
							punto_luce: 'Punto Luce',
							linea: 'Linea',
							quadro: 'Quadro',
							centralina: 'Centralina',
							impianto: 'Impianto',
							edificio: 'Edificio',
						}[feature.tipo_elemento],
					}
					_.keys(desiredValues).forEach(prop => {
						finalObject[PROPS_LABELS.manutenzioni[prop] || prop] = desiredValues[prop]
					})
					return finalObject
				})
			)
		)
		axios
			.post('/api/utils/export', data, {responseType: 'blob'})
			.then(({data}) => {
				saveAs(data, 'export.xls', {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			})
			.catch(err => {
				message.error(`Errore nell'esportazione in Excel: ${err.message}`)
			})
	}

	const renderButtons = () => {
		const Wrapper = ({children}) => (
			<Box padding={styles.PADDING} paddingTop={0}>
				{children}
			</Box>
		)
		switch (currentMode) {
			case UIMode.SELECT:
				return (
					<Wrapper>
						<Button
							disabled={!filter}
							onClick={() => addCurrentFilterToSelection()}
							style={{marginRight: styles.PADDING}}
						>
							Aggiungi a Selezione
						</Button>
						<Button
							disabled={!selection.length}
							onClick={() => setCurrentMode(UIMode.VIEW_SELECTION)}
							style={{marginRight: styles.PADDING}}
						>
							Visualizza Selezione
						</Button>
						<Button disabled={!selection.length} onClick={() => clearSelection()}>
							Vuota Selezione
						</Button>
					</Wrapper>
				)
			case UIMode.VIEW_SELECTION:
				return (
					<Wrapper>
						<Button
							onClick={() => setCurrentMode(UIMode.SELECT)}
							style={{marginRight: styles.PADDING}}
						>
							Indietro
						</Button>
						<Button onClick={() => exportSelection()}>Esporta</Button>
					</Wrapper>
				)
			default:
				return null
		}
	}

	const highlightedSelectionFeatures = _.flatten(
		selection.map(group => group.filteredFeatures.map(feat => ({...feat, query: group.filter})))
	)

	const highlightedSelection = _.uniq(
		_.flatten(selection.map(group => group.filteredFeatures.map(feat => feat[keyProp])))
	)

	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flex: 1,
				position: 'relative', // THIS SEEMS REQUIRED
			}}
		>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent>
					<Map
						maintenanceInterventions={data || []}
						key={codistat + '_pl'}
						codistat={codistat}
						center={center}
						mainFeatureType="pl"
						keyProp={keyProp}
						externalSelection={gridSelectedItems}
						highlightedSelection={highlightedSelection}
						displayHighlightedSelection={currentMode === UIMode.VIEW_SELECTION}
						ref={mapRef}
					/>
				</SplitPaneContent>
				<SplitPaneContent style={{display: 'flex', flexDirection: 'column'}}>
					{currentMode === UIMode.SELECT ? (
						<Box padding={styles.PADDING}>
							<Input
								placeholder="Filtro"
								onChange={e => setFilter(e.target.value)}
								value={filter}
								allowClear
							/>
						</Box>
					) : null}

					<Grids
						currentMode={currentMode}
						filteredFeatures={filteredFeatures}
						keyProp={keyProp}
						setGridSelectedItems={setGridSelectedItems}
						highlightedSelectionFeatures={highlightedSelectionFeatures}
					/>
					<Box padding={styles.PADDING}>
						{selection.length === 0
							? 'Non ci sono query nella Selezione'
							: selection.length === 1
							? `C'è 1 query nella Selezione`
							: `Ci sono ${selection.length} query nella Selezione`}
					</Box>
					<div>{renderButtons()}</div>
				</SplitPaneContent>
			</SplitPane>
		</div>
	)
}

export default InterrogazioniManutenzioni
