import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer'

export class TileLayerX extends TileLayer {
	constructor({name, ...props}) {
		super(props)
		this._name = name
	}

	get name() {
		return this._name
	}
}

export class VectorLayerX extends VectorLayer {
	constructor({name, ...props}) {
		super(props)
		this._name = name
	}

	get name() {
		return this._name
	}
}
