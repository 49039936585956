import React, {
	createContext,
	createElement,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react'
import {Button, DatePicker, Form as AntForm, Input, message, Popconfirm, Radio} from 'antd'
import {DeleteOutlined, LockOutlined, UnlockOutlined} from '@ant-design/icons'
import axios from 'axios'
import * as d3 from 'd3'
import moment from 'moment'
import {Field, Form} from 'react-final-form'
import styled from 'styled-components'
import Box from 'ui-box'

import {
	ConsumiGraphRenderer,
	ImportiGraphRenderer,
	ScaleRenderer,
} from '../../components/datagrid-graph-renderers'
import Grid from '../../components/Grid'
import {getBolletteImpianto} from '../../services/bollette'

const insertBolletta = async input => axios.post('/api/bollette', input)
const updateBolletta = async (id, input) => axios.put(`/api/bollette/${id}`, input)
const deleteBolletta = async id => axios.delete(`/api/bollette/${id}`)

const formGutter = 10
const formItemsStyle = {fontSize: '80%', paddingLeft: formGutter / 2, paddingRight: formGutter / 2}
const InsertFormItem = styled(({label, name, render, ...props}) => {
	return (
		<AntForm.Item label={label} style={formItemsStyle} {...props}>
			<Field name={name} render={({input}) => render({input})} />
		</AntForm.Item>
	)
})`
	.ant-form-item-label {
		line-height: 2;
		label {
			font-size: 12px;
		}
		padding-bottom: 0;
		padding-left: 3px;
	}
	margin-bottom: 0;
	padding-top: 5px;
	padding-bottom: 5px;

	.ant-picker {
		width: 100%;
	}
`

const dateFieldProps = ({value, onChange}) => ({
	onChange: v => onChange(v ? moment(v).format('YYYY-MM-DD') : null),
	selectedValue: value || null,
})

const GridContext = createContext({})

const GraphColHeader = () => {
	const {consumiDomain = [0, 0], importiDomain = [0, 0], showImportiCharts} = useContext(
		GridContext
	)
	const domain = showImportiCharts ? importiDomain : consumiDomain
	const style = {
		width: '100%',
		height: '100%',
		display: 'block',
	}
	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			}}
		>
			<ScaleRenderer style={style} min={domain[0]} max={domain[1]} />
		</div>
	)
}

const GridGraphRendererWithCtx = ({rowData, rowIdx}) => {
	const {consumiDomain = [0, 0], importiDomain = [0, 0], showImportiCharts} = useContext(
		GridContext
	)
	const {valore_f1, valore_f2, valore_f3, valore_f0, totale_lettura, importo} = rowData
	return showImportiCharts ? (
		<ImportiGraphRenderer
			importo={importo}
			min={0}
			max={importiDomain[1]}
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: '#e6e6e6',
			}}
		/>
	) : (
		<ConsumiGraphRenderer
			key={rowIdx}
			f1={valore_f1}
			f2={valore_f2}
			f3={valore_f3}
			f0={valore_f0}
			tot={totale_lettura}
			min={0}
			max={consumiDomain[1]}
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: '#e6e6e6',
			}}
		/>
	)
}

const calcDomain = (prop, items, {min = 0} = {}) => {
	const values = items.map(item => item[prop])
	return [Math.min(d3.min(values), min), d3.max(values) * 1.2]
}

export const BolletteGridView = ({
	bollette,
	cons_prod,
	um,
	style = {},
	onUpdate,
	onLockChange,
	onDelete,
	loading,
	showImportiCharts,
}) => {
	const {columns, consumiDomain, importiDomain} = useMemo(() => {
		const consumiDomain = calcDomain('totale_lettura', bollette)
		const importiDomain = calcDomain('importo', bollette)
		const isRowEditable = row => !row.locked
		const columns = [
			{
				key: 'identificativo',
				name: 'Identificativo Documento',
				editable: isRowEditable,
				width: 170,
			},
			{
				key: 'periodo_inizio',
				name: 'Inizio Periodo',
				editable: isRowEditable,
				_w: 100,
			},
			{
				key: 'periodo_fine',
				name: 'Fine Periodo',
				editable: isRowEditable,
				_w: 93,
			},
			{
				key: 'importo',
				name: 'Importo (€)',
				editable: isRowEditable,
				_w: 85,
			},
			{
				key: 'totale_lettura',
				name: `${cons_prod === 'produzione' ? 'Produzione' : 'Consumo'}${um ? ` (${um})` : ''}`,
				editable: isRowEditable,
				width: 125,
			},
			{
				key: 'valore_f1',
				name: 'F1',
				editable: isRowEditable,
				width: 60,
			},
			{
				key: 'valore_f2',
				name: 'F2',
				editable: isRowEditable,
				width: 60,
			},
			{
				key: 'valore_f3',
				name: 'F3',
				editable: isRowEditable,
				width: 60,
			},
			{
				key: 'valore_f0',
				name: 'F0',
				editable: isRowEditable,
				width: 60,
			},
			{
				width: 100,
				key: 'actions',
				headerRenderer: createElement(() => {
					return <div />
				}),
				renderer: ({rowData}) => (
					<div style={{height: '100%'}}>
						<Popconfirm
							title={`Sei sicuro di voler ${
								rowData.locked ? 'sbloccare' : 'convalidare'
							} questa bolletta?`}
							onConfirm={() => onLockChange(rowData.id_letture, !rowData.locked)}
							okText="Si"
							cancelText="No"
						>
							<Button
								style={{
									width: '50%',
									height: '100%',
								}}
								size="small"
								type="link"
							>
								{rowData.locked ? <LockOutlined /> : <UnlockOutlined />}
							</Button>
						</Popconfirm>
						<Popconfirm
							title={`Sei sicuro di voler eliminare questa bolletta?`}
							onConfirm={() => onDelete(rowData.id_letture)}
							okText="Si"
							cancelText="No"
						>
							<Button
								style={{
									width: '50%',
									height: '100%',
								}}
								size="small"
								type="link"
							>
								{/* Elimina */}
								<DeleteOutlined />
							</Button>
						</Popconfirm>
					</div>
				),
			},
			{
				width: 200,
				key: 'graph',
				headerRenderer: <GraphColHeader />,
				// renderer: props => showImportiCharts ? 'importi' : <GridGraphRendererWithCtx {...props} />,
				renderer: props => <GridGraphRendererWithCtx {...props} />,
			},
		]
		return {columns, consumiDomain, importiDomain}
	}, [cons_prod, um, bollette])

	return (
		<>
			<GridContext.Provider value={{consumiDomain, importiDomain, showImportiCharts}}>
				<Grid
					rowKey="id_letture"
					style={{height: '100%', ...style}}
					columns={columns}
					data={bollette}
					onGridRowsUpdated={({fromRowId, updated, fromRowData}) => {
						const keys = Object.keys(updated)
						let modified = false
						for (let k of keys) {
							if (updated[k] != fromRowData[k]) {
								modified = true
								break
							}
						}
						modified && onUpdate && onUpdate(fromRowId, updated)
					}}
					loading={loading}
				/>
			</GridContext.Provider>
		</>
	)
}

const BolletteGrid = ({impianto}) => {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		savingBolletta: false,
		showImportiCharts: false,
	})
	const [bollette, setBollette] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		setBollette([])
		if (impianto && impianto.id_imp) {
			setLoading(true)
			getBolletteImpianto(impianto.id_imp)
				.then(({data}) => setBollette(data))
				.catch(err => console.error(err))
				.then(() => setLoading(false))
		}
	}, [impianto])

	const onUpdate = (id, input) => {
		updateBolletta(id, input)
			.then(() => {
				message.success('Bolletta salvata!')
				setBollette(bollette => {
					const index = bollette.findIndex(x => x.id_letture === id)
					return [
						...bollette.slice(0, index),
						{...bollette[index], ...input},
						...bollette.slice(index + 1),
					]
				})
			})
			.catch(err => message.error(`Errore nel salvataggio: ${err.message}`))
	}
	const onLockChange = (id, locked) => {
		updateBolletta(id, {locked})
			.then(() => {
				message.success(locked ? 'Bolletta convalidata' : 'Bolletta sbloccata')
				setBollette(bollette => {
					const index = bollette.findIndex(x => x.id_letture === id)
					return [
						...bollette.slice(0, index),
						{...bollette[index], locked},
						...bollette.slice(index + 1),
					]
				})
			})
			.catch(err => message.error(`Errore nell'aggiornamento della bolletta: ${err.message}`))
	}

	const onDelete = async id => {
		deleteBolletta(id)
			.then(() => {
				message.success('Bolletta eliminata')
				setBollette(bollette => {
					const index = bollette.findIndex(x => x.id_letture === id)
					return [...bollette.slice(0, index), ...bollette.slice(index + 1)]
				})
			})
			.catch(err => message.error(`Errore nell'eliminazione della bolletta: ${err.message}`))
	}

	const formDisabled = state.savingBolletta || !impianto

	return (
		<>
			<Box backgroundColor="white">
				<Form
					onSubmit={(values, form) => {
						console.log('onSubmit', values, form)
						setState({savingBolletta: true})
						insertBolletta({...values, id_contat: impianto.id_imp})
							.then(res => {
								message.success(`Bolletta inserita`)
								setBollette(bollette => [...bollette, res.data.data])
								form.reset()
							})
							.catch(err => message.error(`Errore nel salvataggio: ${err.message}`))
							.then(() => {
								setState({savingBolletta: false})
							})
					}}
					validate={values => {
						console.log('validate', values)
						try {
							if (!values.periodo_inizio)
								throw new Error(`È necessario specificare l'Inizio Periodo`)
							if (!values.periodo_fine) throw new Error(`È necessario specificare la Fine Periodo`)
							if (!values.totale_lettura) throw new Error(`È necessario specificare il Consumo`)
						} catch (err) {
							return err.message
						}
					}}
					render={({values, handleSubmit}) => (
						<Box position="relative">
							<AntForm
								layout='vertical'
								onSubmit={handleSubmit}
								style={{display: 'flex', width: '100%', paddingRight: 200}}
							>
								<div style={{width: 170}}>
									<InsertFormItem
										label="Identificativo Documento"
										name="identificativo"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{flex: 1}}>
									<InsertFormItem
										label="Inizio Periodo"
										name="periodo_inizio"
										render={({input}) => (
											<DatePicker {...dateFieldProps(input)} disabled={formDisabled} />
										)}
										required
									/>
								</div>
								<div style={{flex: 1}}>
									<InsertFormItem
										label="Fine Periodo"
										name="periodo_fine"
										render={({input}) => (
											<DatePicker {...dateFieldProps(input)} disabled={formDisabled} />
										)}
										required
									/>
								</div>
								<div style={{flex: 1}}>
									<InsertFormItem
										label="Importo (€)"
										name="importo"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{width: 125}}>
									<InsertFormItem
										label="Consumo"
										name="totale_lettura"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
										required
									/>
								</div>
								<div style={{width: 60}}>
									<InsertFormItem
										label="F1"
										name="valore_f1"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{width: 60}}>
									<InsertFormItem
										label="F2"
										name="valore_f2"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{width: 60}}>
									<InsertFormItem
										label="F3"
										name="valore_f3"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{width: 60}}>
									<InsertFormItem
										label="F0"
										name="valore_f0"
										render={({input}) => <Input {...input} disabled={formDisabled} />}
									/>
								</div>
								<div style={{width: 100, display: 'flex', alignItems: 'end'}}>
									<Button
										htmlType="submit"
										onClick={handleSubmit}
										style={{bottom: 5, marginLeft: formGutter / 2}}
										disabled={formDisabled}
									>
										Inserisci
									</Button>
								</div>
								<input type="submit" hidden />
							</AntForm>
							<Box position="absolute" right={0} bottom={8} textAlign="right">
								<Radio.Group
									disabled={formDisabled}
									value={state.showImportiCharts}
									onChange={e => setState({showImportiCharts: e.target.value})}
								>
									<Radio value={false} size="small">
										Consumi
									</Radio>
									<Radio value={true} size="small">
										Importi
									</Radio>
								</Radio.Group>
							</Box>
						</Box>
					)}
				/>
			</Box>
			<div style={{flex: 50}}>
				{impianto && (
					<BolletteGridView
						bollette={bollette}
						cons_prod={impianto.cons_prod}
						um={impianto.um}
						onUpdate={onUpdate}
						onLockChange={onLockChange}
						onDelete={onDelete}
						loading={loading}
						showImportiCharts={state.showImportiCharts}
					/>
				)}
			</div>
		</>
	)
}

export default BolletteGrid
