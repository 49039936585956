import {TIPI} from '../../constants'

const columns = [
  {
    name: 'Tipo Elemento',
    key: 'tipo_elemento',
    formatter: ({value}) => TIPI[value] || '',
  },
  {
    name: 'Codice',
    key: 'codice',
  },
  {
    name: 'Descrizione Intervento',
    key: 'descrizione_estesa',
  },
  {
    name: 'Chi',
    key: 'chi',
  },
  {
    name: 'Data Conclusione',
    key: 'data_fine',
  },
  {
    name: 'Descrizione Elemento',
    key: 'identificativo_elemento',
  },
]

export default columns