import React, {useEffect, useState} from 'react'
import {Row as AntRow, Col as AntCol, Input, Button, Form as AntForm, Checkbox} from 'antd'
import {Form, Field} from 'react-final-form'
import _ from 'lodash'

import {getFeatures} from '../../../services/data'

import gridDefs from '../../../configs/grids/index'

import {
	CentralinaSelect as CentralinaSelectOrig,
	SmartAutoComplete as SmartAutoCompleteOrig,
	checkRequired,
} from '../../common/forms'
import * as styles from '../../../styles'
import Box from 'ui-box'

const FormItem = AntForm.Item

export const Row = ({children, style, ...props}) => {
	style = {marginTop: 10, ...style}
	return (
		<AntRow style={style} gutter={10} {...props}>
			{children}
		</AntRow>
	)
}

export const Col = ({children, ...props}) => (
	<AntCol span={12} {...props} style={{marginBottom: 0}}>
		{children}
	</AntCol>
)

export const CentralinaSelect = CentralinaSelectOrig
export const SmartAutoComplete = SmartAutoCompleteOrig

export const useFormModel = ({autocompleteProvider = null} = {}) => {
	const [hints, setHints] = useState([])

	useEffect(() => {
		autocompleteProvider && autocompleteProvider().then(items => setHints(items))
	}, [])

	const dataSource = key => {
		const dataSource = _.compact(_.uniq((hints || []).map(i => String(i[key]))))
		return {dataSource, filterOption: true}
	}

	const formItem = ({
		label,
		name,
		required = false,
		isNumber = false,
		Component = null,
		render = null,
		type = null,
	}) => {
		return (
			<Field name={name} validate={required ? checkRequired : () => null}>
				{({input, meta}) => {
					let rendered
					if (render) {
						rendered = render(input)
					} else if (isNumber || type === 'number' || type === 'integer') {
						rendered = <Input {...input} type="number" />
					} else if (Component) {
						rendered = <Component {...input} />
					} else if (type === 'checkbox') {
						rendered = <Checkbox checked={input.value} onChange={input.onChange} />
					} else {
						rendered = <SmartAutoComplete {...input} {...dataSource(name)} />
					}
					return (
						<FormItem
							label={label}
							required={required}
							validateStatus={meta.error && meta.touched ? 'error' : ''}
							help={meta.touched && meta.error}
							style={{marginBottom: 0}}
						>
							{rendered}
						</FormItem>
					)
				}}
			</Field>
		)
	}

	return {
		formItem,
	}
}

export const createInsertForm = ({featureType, form}) => {
	return ({onSave, onCancel}) => {
		const {formItem} = useFormModel({
			autocompleteProvider: () =>
				getFeatures(featureType).then(feats => feats.map(f => f.properties)),
		})
		return (
			<>
				<Form onSubmit={onSave}>
					{({handleSubmit, values}) => (
						<>
							<AntForm layout="vertical" style={{paddingLeft: 10, paddingRight: 10}}>
								{form({formItem})}
							</AntForm>
							<Box padding={styles.PADDING}>
								<Button
									onClick={() => onCancel && onCancel()}
									style={{marginRight: 10}}
								>
									Annulla
								</Button>
								<Button onClick={() => handleSubmit()}>Salva</Button>
							</Box>
						</>
					)}
				</Form>
			</>
		)
	}
}

export const ModifyFormWrapper = ({
	children,
	featureType,
	onClose,
	onSave,
	onDelete,
	initialEntity = {},
}) => {
	const keyProp = gridDefs[featureType].primaryKey
	const primaryKey = initialEntity[keyProp]
	const {formItem} = useFormModel({
		autocompleteProvider: () =>
			getFeatures(featureType).then(feats => feats.map(f => f.properties)),
	})
	return (
		<>
			<Form initialValues={initialEntity} onSubmit={onSave}>
				{({handleSubmit, values}) => (
					<>
						<AntForm
							layout="vertical"
							style={{
								minHeight: 400,
								paddingLeft: styles.PADDING,
								paddingRight: styles.PADDING,
							}}
						>
							{children({formItem, values})}
						</AntForm>
						<Box padding={styles.PADDING}>
							<Button onClick={() => onClose && onClose()} style={{marginRight: 10}}>
								Annulla
							</Button>
							<Button onClick={() => handleSubmit()} style={{marginRight: 10}}>
								Salva
							</Button>
							<Button onClick={() => onDelete(primaryKey)}>Elimina</Button>
						</Box>
					</>
				)}
			</Form>
		</>
	)
}
