import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import _ from 'lodash'
import InterrogazioniPuntiLuce from './pl'
import InterrogazioniManutenzioni from './manutenzioni'

export default () => (
	<Switch>
		<Route exact path="/interrogazioni/pl" component={InterrogazioniPuntiLuce} />
		<Route exact path="/interrogazioni/manutenzioni" component={InterrogazioniManutenzioni} />
		<Redirect to="/" />
	</Switch>
)
