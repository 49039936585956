import React, {
	useState,
	useEffect,
	useRef,
	useReducer,
	forwardRef,
	useMemo,
	useImperativeHandle,
} from 'react'
import ReactDataGrid from 'react-data-grid'
import {Data} from 'react-data-grid-addons'
import './style.scss'
import _ from 'lodash'

import {CustomRow} from './custom-renderer'
import {Empty, Spin} from 'antd'

const DEBUG = false

function useKeysPressed(keys = []) {
	const keysMap = useMemo(() => keys.reduce((acc, k) => ({...acc, [k]: true}), {}), [])
	const [keysPressed, setKeysPressed] = useReducer(
		(prevState, newState) => ({...prevState, ...newState}),
		keys.reduce((acc, k) => ({...acc, [k]: false}), {})
	)
	function downHandler({key}) {
		if (!keysMap[key]) return
		setKeysPressed({[key]: true})
	}
	function upHandler({key}) {
		if (!keysMap[key]) return
		setKeysPressed({[key]: false})
	}
	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)
		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
		}
	}, [])
	return keysPressed
}

const Grid = forwardRef(
	(
		{
			columns,
			data,
			defaultSortOn,
			rowKey = 'id',
			style = {},
			fit,
			selectionFrozen,
			selection,
			onSelectionChange,
			groupBy,
			loading = false,
			...props
		},
		ref
	) => {
		const $grid = useRef()
		const [selectedIds, setSelectedIds] = useState([])
		const [lastOp, setLastOp] = useReducer(
			(state, newState) =>
				newState
					? {
							...state,
							..._.pickBy(newState, v => v !== null && v !== undefined),
					  }
					: null,
			null
		)
		const [{sortColumn, sortDirection}, setSort] = useReducer(
			(oldState, newState) => ({...oldState, ...newState}),
			{}
		)
		const [expandedRows, setExpandedRows] = useState({})
		const keysPressed = useKeysPressed(['Meta', 'Alt', 'Shift'])

		const sortRowsDefault = rows => _.sortBy(rows, [defaultSortOn || rowKey])

		const realRows = useMemo(() => {
			let rows = data || []
			rows =
				!sortDirection || sortDirection === 'NONE'
					? sortRowsDefault(rows)
					: rows.sort((a, b) => {
							if (sortDirection === 'ASC') {
								return a[sortColumn] > b[sortColumn] ? 1 : -1
							} else if (sortDirection === 'DESC') {
								return a[sortColumn] < b[sortColumn] ? 1 : -1
							}
					  })
			rows = Data.Selectors.getRows({rows, groupBy, expandedRows})
			return rows
		}, [data, groupBy, sortDirection, sortColumn, expandedRows])

		function emitOnSelectionChange(ids) {
			DEBUG && console.log('emitOnSelectionChange')
			onSelectionChange && onSelectionChange(ids)
		}

		const memoSelectedIds = useMemo(() => (selection ? selection : selectedIds), [
			selection,
			selectedIds,
		])

		useEffect(() => {
			const refreshGrid = () => {
				if ($grid.current) {
					const viewport = $grid.current.base.viewport
					if (viewport) {
						viewport.metricsUpdated()
						setTimeout(() => {
							try {
								viewport.onScroll(viewport.getScroll())
							} catch (err) {
								console.warn(err)
							}
						})
					}
				}
			}
			refreshGrid()
		}, [realRows, columns])

		const updateSelection = ids => {
			DEBUG && console.log('updateSelection')
			emitOnSelectionChange(ids)
			if (!selection) setSelectedIds(ids)
		}

		const rowGetter = i => realRows[i]

		const onRowClick = (rowIdx, ...args) => {
			if (selectionFrozen || !onSelectionChange) return
			const row = rowGetter(rowIdx)
			if (!row) return
			const id = row[rowKey]
			const selectedIds = memoSelectedIds
			const index = selectedIds.indexOf(id)

			let newSelectedIds = []
			let op
			if (keysPressed.Shift && lastOp !== null) {
				const [min, max] = _.sortBy([lastOp.idx, rowIdx])
				const indexes = _.range(min, max + 1)
				const rowsIds = indexes
					.map(idx => rowGetter(idx))
					.filter(Boolean)
					.map(row => row[rowKey])
				if (lastOp.op === 'add') {
					newSelectedIds = _.sortBy(_.uniq([...selectedIds, ...rowsIds]))
				} else {
					newSelectedIds = _.sortBy(_.uniq(_.difference(selectedIds, rowsIds)))
				}
			} else if (keysPressed.Meta) {
				if (index === -1) {
					newSelectedIds = [...selectedIds, id]
					op = 'add'
				} else {
					newSelectedIds = [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)]
					op = 'remove'
				}
			} else {
				if (selectedIds.length === 1 && selectedIds[0] === id) {
					newSelectedIds = []
					op = 'remove'
				} else {
					newSelectedIds = [id]
					op = 'add'
				}
			}
			updateSelection(newSelectedIds)
			setLastOp({op, idx: rowIdx})
		}

		useImperativeHandle(ref, () => ({
			selectAll: () => updateSelection(realRows.map(row => row[rowKey]).filter(Boolean)),
		}))

		const onRowExpandToggle = ({columnGroupName, name, shouldExpand}) => {
			console.log('onRowExpandToggle', columnGroupName, name, shouldExpand)
			const _expandedRows = {...expandedRows}
			_expandedRows[columnGroupName] = {..._expandedRows[columnGroupName]}
			_expandedRows[columnGroupName][name] = {isExpanded: shouldExpand}
			setExpandedRows(_expandedRows)
		}

		return (
			<div
				style={{
					...style,
					position: 'relative',
					overflow: 'hidden',
					width: fit ? '100%' : style.width || undefined,
					height: fit ? '100%' : style.height || undefined, // by default fill all available space
				}}
			>
				<ReactDataGrid
					ref={$grid}
					{...props}
					onGridSort={(sortColumn, sortDirection) => setSort({sortColumn, sortDirection})}
					columns={columns.map(c => ({sortable: true, ...c}))}
					rowGetter={rowGetter}
					rowKey={rowKey}
					// rowsCount={rows.length}
					rowsCount={realRows.length}
					//minHeight={500}
					onRowClick={onRowClick}
					enableCellSelect={true}
					enableRowSelect="multi"
					onRowExpandToggle={onRowExpandToggle}
					rowRenderer={CustomRow}
					emptyRowsView={() => (
						<div
							style={{
								position: 'absolute',
								width: '100%',
								top: 35,
								bottom: 0,
								display: 'flex',
								justifyContent: 'space-around',
								alignItems: 'center',
								overflow: 'hidden',
							}}
						>
							{loading ? <Spin /> : <Empty />}
						</div>
					)}
					rowSelection={{
						showCheckbox: false,
						selectBy: {
							keys: {
								rowKey: rowKey,
								values: memoSelectedIds,
							},
						},
					}}
				/>
				{DEBUG && (
					<div style={{position: 'fixed', top: 0}}>
						<div>{JSON.stringify(memoSelectedIds)}</div>
					</div>
				)}
			</div>
		)
	}
)

export default Grid
