import { Style, RegularShape, Stroke, Fill } from 'ol/style';

export default feature => {
	const props = feature.getProperties();
	let fillColor;
	switch (props.imp_tipo) {
		case 'ed_term_':
			fillColor = '#FE7979';
			break;
		case 'ed_ele_':
			fillColor = '#6DC2FE';
			break;
		case 'fotov_':
			fillColor = '#C885FF';
			break;
		default:
			fillColor = '#AEE2D8';
			break;
	}
	return new Style({
		image: new RegularShape({
			points: 4,
			// radius: 5,
			radius: 8,
			// rotation: (45 * Math.PI) / 180,
			stroke: new Stroke({
				color: 'black',
				width: 0.5,
			}),
			fill: new Fill({
				color: fillColor,
			}),
		}),
	});
};

// <?xml version="1.0" encoding="UTF-8"?>
// <sld:StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:sld="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml" version="1.0.0">
//   <sld:NamedLayer>
//     <sld:Name>impianti_new_dyn</sld:Name>
//     <sld:UserStyle>
//       <sld:Name>impianti_new_dyn</sld:Name>
//       <sld:Title>impianti_new_dyn</sld:Title>
//       <sld:FeatureTypeStyle>
//         <sld:Name>name</sld:Name>

//         <sld:Rule>
//           <sld:Name>ed_term_</sld:Name>
//           <sld:Title>ed_term_</sld:Title>
//           <ogc:Filter>
//             <ogc:PropertyIsLike wildCard="*" singleChar="." escape="!">
//               <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//               <ogc:Literal>ed_term_</ogc:Literal>
//             </ogc:PropertyIsLike>
//                   <sld:CssParameter name="fill">#FE7979</sld:CssParameter>
//                 </sld:Fill>
//                 <sld:Stroke>
//                   <sld:CssParameter name="stroke-width">0.5</sld:CssParameter>
//                 </sld:Stroke>
//               </sld:Mark>
//               <sld:Size>12</sld:Size>
//               <sld:Rotation>45.0</sld:Rotation>
//             </sld:Graphic>
//           </sld:PointSymbolizer>
//         </sld:Rule>

//         <sld:Rule>
//           <sld:Name>ed_ele_</sld:Name>
//           <sld:Title>ed_ele_</sld:Title>
//           <ogc:Filter>
//             <ogc:PropertyIsLike wildCard="*" singleChar="." escape="!">
//               <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//               <ogc:Literal>ed_ele_</ogc:Literal>
//             </ogc:PropertyIsLike>
//                   <sld:CssParameter name="fill">#6DC2FE</sld:CssParameter>

//         <sld:Rule>
//           <sld:Name>fotov_</sld:Name>
//           <sld:Title>fotov_</sld:Title>
//           <ogc:Filter>
//             <ogc:PropertyIsLike wildCard="*" singleChar="." escape="!">
//               <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//               <ogc:Literal>fotov_</ogc:Literal>
//             </ogc:PropertyIsLike>
//                   <sld:CssParameter name="fill">#C885FF</sld:CssParameter>

//         <sld:Rule>
//           <sld:Name>__OTHER__</sld:Name>
//           <sld:Title>__OTHER__</sld:Title>
//           <ogc:Filter>
//             <ogc:And>
//               <ogc:And>
//                 <ogc:PropertyIsNotEqualTo>
//                   <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//                   <ogc:Literal>ed_ele_</ogc:Literal>
//                 </ogc:PropertyIsNotEqualTo>
//                 <ogc:PropertyIsNotEqualTo>
//                   <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//                   <ogc:Literal>ed_term_</ogc:Literal>
//                 </ogc:PropertyIsNotEqualTo>
//               </ogc:And>
//               <ogc:PropertyIsNotEqualTo>
//                 <ogc:PropertyName>imp_tipo</ogc:PropertyName>
//                 <ogc:Literal>fotov_</ogc:Literal>
//               </ogc:PropertyIsNotEqualTo>
//             </ogc:And>
//                   <sld:CssParameter name="fill">#AEE2D8</sld:CssParameter>

//       </sld:FeatureTypeStyle>
//     </sld:UserStyle>
//   </sld:NamedLayer>
// </sld:StyledLayerDescriptor>
