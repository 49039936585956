import axios from 'axios'

let cache = {}

const loadBolletteImpianto = async impiantoId =>
	axios({
		method: 'get',
		url: `/api/bollette?imp=${impiantoId}`,
	}).then(({data}) => data)

export const getBolletteImpianto = async impiantoId => {
	return loadBolletteImpianto(impiantoId).then(data => {
		cache[impiantoId] = data
		return data
	})
	// return cache[impiantoId]
	// 	? Promise.resolve(cache[impiantoId])
	// 	: loadBolletteImpianto(impiantoId).then(data => {
	// 			cache[impiantoId] = data;
	// 			return data;
	// 	  });
}

export const fetchConsumiImpianti = async impiantiIds =>
	axios({
		method: 'get',
		url: `/api/consumi-v3?impianti=${impiantiIds.join(',')}`,
	}).then(({data}) => data)
