import React, {useState, useRef} from 'react'
import {Popconfirm, Button, message} from 'antd'
import {CheckCircleTwoTone, DeleteTwoTone} from '@ant-design/icons'
import axios from 'axios'

import Grid from '../../components/Grid'
import {useData, SplitPane, SplitPaneContent} from '../../utils'
import {enrichManutenzione} from '../../utils/manutenzioni'
import columns from '../../configs/grids/manutenzioni'
import Map from './map'
import {useAuth} from '../../lib/auth'

const convalidaManutenzione = async id => axios.post('/api/manutenzioni/conferma', {id})
const eliminaManutenzione = async id => axios.post('/api/manutenzioni/elimina', {ids: [id]})

export default () => {
	const mapRef = useRef()
	const {user} = useAuth()
	const {codistat, center} = user
	const {loading, data, error, invalidate} = useData('/api/manutenzioni/verifica')
	const [gridSelectedItems, setGridSelectedItems] = useState([])
	return (
		<div style={{height: '100%', position: 'relative'}}>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent>
					<Map
						maintenanceInterventions={data || []}
						key={codistat + '_pl'}
						codistat={codistat}
						center={center}
						externalSelection={gridSelectedItems}
						ref={mapRef}
					/>
				</SplitPaneContent>
				<SplitPaneContent>
					{error ? (
						error
					) : (
						<Grid
							data={(data || []).map(m => ({...m, ...enrichManutenzione(m)}))}
							loading={loading}
							onSelectionChange={setGridSelectedItems}
							columns={[
								...columns.map(col => ({...col, resizable: true})),
								{
									name: '',
									key: 'actions',
									width: 64,
									renderer: ({rowData}) => (
										<div style={{height: '100%'}}>
											<Popconfirm
												title={`Sei sicuro di voler convalidare questo intervento?`}
												onConfirm={() =>
													convalidaManutenzione(rowData.id)
														.then(() => invalidate())
														.catch(err =>
															message.error(
																`Errore durante l'eliminazione della manutenzione: ${err.message}`
															)
														)
												}
												okText="Si"
												cancelText="No"
											>
												<Button
													style={{height: '100%'}}
													size="small"
													type="link"
												>
													<CheckCircleTwoTone twoToneColor="#52c41a" />
												</Button>
											</Popconfirm>
											<Popconfirm
												title={`Sei sicuro di voler eliminare questo intervento?`}
												onConfirm={() =>
													eliminaManutenzione(rowData.id)
														.then(() => invalidate())
														.catch(err =>
															message.error(
																`Errore durante l'eliminazione della manutenzione: ${err.message}`
															)
														)
												}
												okText="Si"
												cancelText="No"
											>
												<Button
													style={{height: '100%'}}
													size="small"
													type="link"
												>
													<DeleteTwoTone twoToneColor="red" />
												</Button>
											</Popconfirm>
										</div>
									),
								},
							]}
							fit
						/>
					)}
				</SplitPaneContent>
			</SplitPane>
		</div>
	)
}
