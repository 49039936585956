import React from 'react'
import {XYPlot, XAxis, YAxis, VerticalRectSeries, ChartLabel} from 'react-vis'
import _ from 'lodash'
import * as d3 from 'd3'
import moment from 'moment'
import {SizeProvider} from '../../../utils/ui'

const mesi = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']

const tickFormat = (value, period) => {
	switch (period) {
		case 'P1M':
			return value.format('YYYY') + ' ' + mesi[value.month()]
		case 'P3M':
			return value.format('YYYY') + ' ' + value.quarter() + '° trim.'
		case 'P1Y':
			return value.format('YYYY')
	}
}

const multiplyPeriod = (period, multiplier) =>
	Math.round(moment.duration(period).asSeconds() * multiplier)

const periodsMap = {
	P1M: 'month',
	P3M: 'quarter',
	P1Y: 'year',
}

const ConsumiChartInner = ({width, height, data, start, end, period}) => {
	const margins = {left: 75, right: 10, top: 10, bottom: 70}
	const xDomain = [moment(start, 'YYYY'), moment(end, 'YYYY').endOf('y')]
	const ticksMinDistance = 20
	const maxTicks = (width - margins.left - margins.right) / ticksMinDistance
	const xDomainDuration = xDomain[1].diff(xDomain[0], 's')
	const minTickDuration = xDomainDuration / maxTicks
	const tickDuration = Math.ceil(moment.duration(minTickDuration, 's').as(periodsMap[period]))
	const tickCount = Math.floor(xDomainDuration / minTickDuration)
	const tickValues = _.range(0, tickCount)
		.map(i => moment(xDomain[0]).add(i * tickDuration, periodsMap[period]))
		.filter(tick => tick.unix() >= xDomain[0].unix() && tick.unix() <= xDomain[1].unix())

	const maximums = data.map(d =>
		Math.max(d.consumo, (d.f1 || 0) + (d.f2 || 0) + (d.f3 || 0) + (d.f0 || 0))
	)
	const yDomain = [d3.min([...maximums, 0]), d3.max(maximums) * 1.2]
	const yTicksCount = Math.floor((height - margins.top - margins.bottom) / ticksMinDistance)

	const timeSlotsSeries = data.map(({startDate, ...values}) => ({
		...values,
		x0: moment(startDate).add(multiplyPeriod(period, 0.2), 's'),
		x: moment(startDate).add(multiplyPeriod(period, 0.8), 's'),
	}))
	const totalsSeries = data.map(({startDate, ...values}) => ({
		...values,
		x0: moment(startDate).add(multiplyPeriod(period, 0), 's'),
		x: moment(startDate).add(multiplyPeriod(period, 0.8), 's'),
	}))

	return (
		<XYPlot
			margin={margins}
			style={{background: '#e9f1f9'}}
			xDomain={xDomain}
			yDomain={yDomain}
			width={width}
			height={height}
			stackBy="y"
		>
			<VerticalRectSeries
				cluster="stack2"
				data={totalsSeries.map(({x0, x, consumo}) => ({
					x0,
					x,
					y: consumo,
				}))}
				color="#FC006D"
			/>
			<VerticalRectSeries
				cluster="stack1"
				data={timeSlotsSeries.map(({x0, x, f1}) => ({x0, x, y: f1}))}
				color="#2749FA"
			/>
			<VerticalRectSeries
				cluster="stack1"
				data={timeSlotsSeries.map(({x0, x, f2}) => ({x0, x, y: f2}))}
				color="#82CA3F"
			/>
			<VerticalRectSeries
				cluster="stack1"
				data={timeSlotsSeries.map(({x0, x, f3}) => ({x0, x, y: f3}))}
				color="#FEDD23"
			/>
			<VerticalRectSeries
				cluster="stack1"
				data={timeSlotsSeries.map(({x0, x, f0}) => ({x0, x, y: f0}))}
				color="#000000"
			/>
			<XAxis
				tickLabelAngle={-45}
				tickValues={tickValues}
				tickTotal={tickValues.length}
				tickFormat={v => tickFormat(moment(v), period)}
				style={{
					line: {stroke: 'black'},
					text: {fontSize: 10, textRendering: 'geometricPrecision', shapeRendering: 'crispEdges'},
				}}
				tickSizeInner={0}
				tickSizeOuter={3}
			/>
			<ChartLabel
				text="Consumo (kWh)"
				includeMargin={true}
				xPercent={0}
				yPercent={0}
				style={{transform: 'rotate(-90)', fontSize: 10, dx: -20, dy: 12}}
			/>
			<YAxis
				tickTotal={yTicksCount}
				style={{line: {stroke: 'black'}, text: {fontSize: 10}, title: {fontSize: 10}}}
				tickSizeInner={0}
				tickSizeOuter={3}
			/>
		</XYPlot>
	)
}

const ConsumiChart = ({style, data, annoInizio, annoFine, periodo}) => {
	return (
		<SizeProvider style={{width: 200, height: 100, ...style}}>
			{({width, height}) => {
				return (
					<ConsumiChartInner
						start={annoInizio}
						end={annoFine}
						period={periodo}
						data={data}
						width={width}
						height={height}
					/>
				)
			}}
		</SizeProvider>
	)
}

const ImportiChartInner = ({width, height, data, start, end, period}) => {
	const margins = {left: 75, right: 10, top: 10, bottom: 70}
	const xDomain = [moment(start, 'YYYY'), moment(end, 'YYYY').endOf('y')]
	const ticksMinDistance = 20
	const maxTicks = (width - margins.left - margins.right) / ticksMinDistance
	const xDomainDuration = xDomain[1].diff(xDomain[0], 's')
	const minTickDuration = xDomainDuration / maxTicks
	const tickDuration = Math.ceil(moment.duration(minTickDuration, 's').as(periodsMap[period]))
	const tickCount = Math.floor(xDomainDuration / minTickDuration)
	const tickValues = _.range(0, tickCount)
		.map(i => moment(xDomain[0]).add(i * tickDuration, periodsMap[period]))
		.filter(tick => tick.unix() >= xDomain[0].unix() && tick.unix() <= xDomain[1].unix())

	const maximums = data.map(d => d.importo)
	const yDomain = [d3.min([...maximums, 0]), d3.max(maximums) * 1.2]
	const yTicksCount = Math.floor((height - margins.top - margins.bottom) / ticksMinDistance)

	const totalsSeries = data.map(({startDate, ...values}) => ({
		...values,
		x0: moment(startDate).add(multiplyPeriod(period, 0), 's'),
		x: moment(startDate).add(multiplyPeriod(period, 0.8), 's'),
	}))

	return (
		<XYPlot
			margin={margins}
			style={{background: '#e9f1f9'}}
			xDomain={xDomain}
			yDomain={yDomain}
			width={width}
			height={height}
			stackBy="y"
		>
			<VerticalRectSeries
				cluster="stack2"
				data={totalsSeries.map(({x0, x, importo}) => ({
					x0,
					x,
					y: importo,
				}))}
				color="#1BA5F4"
				style={{
					stroke: 'black',
					strokeWidth: .5,
				}}
			/>
			<XAxis
				tickLabelAngle={-45}
				tickValues={tickValues}
				tickTotal={tickValues.length}
				tickFormat={v => tickFormat(moment(v), period)}
				style={{
					line: {stroke: 'black'},
					text: {fontSize: 10, textRendering: 'geometricPrecision', shapeRendering: 'crispEdges'},
				}}
				tickSizeInner={0}
				tickSizeOuter={3}
			/>
			<ChartLabel
				text="Importo (€)"
				includeMargin={true}
				xPercent={0}
				yPercent={0}
				style={{transform: 'rotate(-90)', fontSize: 10, dx: -20, dy: 12}}
			/>
			<YAxis
				tickTotal={yTicksCount}
				style={{line: {stroke: 'black'}, text: {fontSize: 10}, title: {fontSize: 10}}}
				tickSizeInner={0}
				tickSizeOuter={3}
			/>
		</XYPlot>
	)
}

const ImportiChart = ({style, data, annoInizio, annoFine, periodo}) => {
	return (
		<SizeProvider style={{width: 200, height: 100, ...style}}>
			{({width, height}) => {
				return (
					<ImportiChartInner
						start={annoInizio}
						end={annoFine}
						period={periodo}
						data={data}
						width={width}
						height={height}
					/>
				)
			}}
		</SizeProvider>
	)
}

export {ConsumiChart, ImportiChart}
