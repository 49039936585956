import React, {useEffect, useMemo, useState, createContext, useContext} from 'react'
import {Button, Col, Input, Row, Select, Modal, InputNumber, message} from 'antd'
import _ from 'lodash'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import Box from 'ui-box'
import {Machine, assign} from 'xstate'
import {useMachine} from '@xstate/react'

import Grid from '../../components/Grid'
import {googleLikeFilter, SplitPane, SplitPaneContent} from '../../utils'
import gridConfigs from '../../configs/grids'
import Map from './map'
import {useAuth} from '../../lib/auth'
import * as styles from '../../styles'
import {ColumnGroupTabs} from '../common/ui'
import {Spacer} from '../../utils/ui'

const createOrUpdateBinding = async ({id_edificio, id_impianto, coefficiente}) =>
	axios.post(`/api/edifici-impianti`, {
		id_edificio,
		id_impianto,
		coefficiente: _.round(Math.min(coefficiente, 1), 2),
	})

const removeBinding = async ({id_edificio, id_impianto}) =>
	axios.post(`/api/edifici-impianti/delete`, {id_edificio, id_impianto})

const Title = ({children, style}) => (
	<h3 style={{margin: 0, paddingTop: 10, paddingBottom: 10, ...style}}>{children}</h3>
)

const LinkedItemsTitle = ({featureType}) => {
	switch (featureType) {
		case 'edifici':
			return <Title>Edifici associati</Title>
		case 'impianti':
			return <Title>Impianti associati</Title>
		default:
			return null
	}
}

const getCurrentColumns = (featureType, mode, columnGroupIdx, plantType) => {
	const {columnGroups, columns} = gridConfigs[featureType]
	const currentColumnGroup = columnGroups[columnGroupIdx]
	return columns['*']
		.filter(
			col =>
				currentColumnGroup.keys.includes(col.key) ||
				currentColumnGroup.keys.includes(col._key)
		)
		.map(col => {
			if (!columns[mode]) return col
			const replacement = columns[mode].find(item => item.key === col.key)
			return replacement || col
		})
		.map(col => {
			let name = col.name
			if (featureType === 'impianti' && plantType !== '*') {
				name =
					_.get(
						gridConfigs,
						`impianti.TYPE_SPECIFIC_COLUMN_LABELS.${plantType}.${col.key}`
					) || '~ ' + col.key
			}
			return {...col, name, resizable: true}
		})
}

const formatItem = item => ({id: item.id, ...item.properties})

const PageContext = createContext()
const PageContextProvider = ({
	loading,
	data,
	invalidate,
	sourceFeatureId,
	targetFeatureType,
	keyProp,
	children,
}) => {
	data = data || {edifici: [], impianti: []}
	const sourceFeatureType = targetFeatureType !== 'edifici' ? 'edifici' : 'impianti'
	const sourceFeature = _.find(data[sourceFeatureType], x => x.id == sourceFeatureId)
	const targetItems = data[targetFeatureType].map(formatItem)
	const associations = _.get(sourceFeature, `properties.${targetFeatureType}`, []).reduce(
		(acc, next) => ({...acc, [next.id]: next.quota_parte}),
		{}
	)

	const [linkedItems, unlinkedItems] = targetItems.reduce(
		(acc, item) => {
			const quota = associations[item.id]
			!_.isNil(quota) ? acc[0].push({...item, quota}) : acc[1].push(item)
			return acc
		},
		[[], []]
	)
	const [mapSelection, mapSelectionSet] = useState([])
	const [linkedItemsGridSelection, linkedItemsGridSelectionSet] = useState([])
	const [unlinkedItemsGridSelection, unlinkedItemsGridSelectionSet] = useState([])
	const [textualFilter, textualFilterSet] = useState('')
	const [plantTypeFilter, plantTypeFilterSet] = useState('*')
	const filteredUnlinkedItems = useMemo(
		() =>
			unlinkedItems.filter(
				x =>
					googleLikeFilter(textualFilter)(x) &&
					(mapSelection.length ? mapSelection.includes(x[keyProp]) : true) &&
					(targetFeatureType === 'impianti'
						? ['*', x.imp_tipo].includes(plantTypeFilter)
						: true)
			),
		[textualFilter, plantTypeFilter, mapSelection, data]
	)
	return (
		<PageContext.Provider
			value={{
				loading,
				invalidate,
				associations,
				sourceItem: sourceFeature,
				linkedItems,
				filteredUnlinkedItems,
				mapSelection,
				mapSelectionSet,
				linkedItemsGridSelection,
				linkedItemsGridSelectionSet,
				unlinkedItemsGridSelection,
				unlinkedItemsGridSelectionSet,
				textualFilter,
				textualFilterSet,
				plantTypeFilter,
				plantTypeFilterSet,
			}}
		>
			{children}
		</PageContext.Provider>
	)
}

const useData = () => {
	const [state, setState] = useState({
		loading: true,
		data: null,
		error: null,
	})
	useEffect(() => {
		if (state.loading)
			Promise.all([
				axios({
					method: 'get',
					url: `/api/edifici`,
				}),
				axios({
					method: 'get',
					url: `/api/impianti`,
				}),
			])
				.then(results => {
					setState({
						data: {
							edifici: _.get(results, '0.data.features'),
							impianti: _.get(results, '1.data.features'),
						},
						loading: false,
					})
				})
				.catch(error => setState({error, loading: false}))
	}, [state.loading])
	return {...state, invalidate: () => setState({...state, loading: true})}
}

const DataProvider = ({children}) => {
	const {loading, data, error, invalidate} = useData()
	const {user} = useAuth()
	if (!user) return null
	const {codistat, center} = user
	return children({data, codistat, center, loading, invalidate})
}

const associationMachine = Machine({
	id: 'association',
	initial: 'idle',
	states: {
		idle: {
			on: {
				CREATE_ASSOCIATION: {
					target: 'creatingAssociation',
					actions: [assign(() => ({editedQuota: 1}))],
				},
				MODIFY_ASSOCIATION: {
					target: 'modifyingAssociation',
					actions: [assign((_ctx, evt) => ({editedQuota: evt.data}))],
				},
			},
		},
		creatingAssociation: {
			on: {
				TYPING: {
					target: 'creatingAssociation',
					actions: [assign((_ctx, evt) => ({editedQuota: evt.data}))],
				},
				SAVE: 'inserting',
				CANCEL: 'idle',
			},
		},
		inserting: {
			invoke: {
				src: 'insertAssociation',
				onError: {
					target: 'creatingAssociation',
					actions: ['notifyCreateAssociationError'],
				},

				onDone: {
					target: 'idle',
					actions: ['notifyCreateAssociationSuccess'],
				},
			},
		},
		modifyingAssociation: {
			on: {
				TYPING: {
					target: 'modifyingAssociation',
					actions: [assign((_ctx, evt) => ({editedQuota: evt.data}))],
				},
				SAVE: 'updating',
				DETACH: 'detaching',
				CANCEL: 'idle',
			},
		},
		updating: {
			invoke: {
				src: 'updateAssociation',
				onError: {
					target: 'modifyingAssociation',
					actions: ['notifyUpdateAssociationError'],
				},

				onDone: {
					target: 'idle',
					actions: ['notifyUpdateAssociationSuccess'],
				},
			},
		},
		detaching: {
			invoke: {
				src: 'deleteAssociation',
				onError: {
					target: 'modifyingAssociation',
					actions: ['notifyDetachError'],
				},

				onDone: {
					target: 'idle',
					actions: ['notifyDetachSuccess'],
				},
			},
		},
	},
})

const UI = ({featureType, keyProp}) => {
	const history = useHistory()
	const [targetType, sourceTypeLabel, targetTypeLabel, bindingSourceKey, bindingTargetKey] = {
		edifici: ['impianti', 'Edifici', 'Impianti', 'id_impianto', 'id_edificio'],
		impianti: ['edifici', 'Impianti', 'Edifici', 'id_edificio', 'id_impianto'],
	}[featureType]

	const {columnGroups, columns, defaultSortOn} = gridConfigs[featureType]
	const [currentColumnGroupIdx, setCurrentColumnGroupIdx] = useState(0)

	const {
		loading,
		invalidate,
		associations,
		sourceItem,
		linkedItems,
		filteredUnlinkedItems,
		mapSelection,
		mapSelectionSet,
		linkedItemsGridSelection,
		linkedItemsGridSelectionSet,
		unlinkedItemsGridSelection,
		unlinkedItemsGridSelectionSet,
		textualFilter,
		textualFilterSet,
		plantTypeFilter,
		plantTypeFilterSet,
	} = useContext(PageContext)

	const [state, send] = useMachine(associationMachine, {
		services: {
			insertAssociation: async ctx =>
				createOrUpdateBinding({
					[bindingSourceKey]: _.get(sourceItem, 'id'),
					[bindingTargetKey]: unlinkedItemsGridSelection[0],
					coefficiente: ctx.editedQuota,
				}).then(() => invalidate()),
			updateAssociation: async ctx =>
				createOrUpdateBinding({
					[bindingSourceKey]: _.get(sourceItem, 'id'),
					[bindingTargetKey]: linkedItemsGridSelection[0],
					coefficiente: ctx.editedQuota,
				}).then(() => invalidate()),
			deleteAssociation: async () =>
				removeBinding({
					[bindingSourceKey]: _.get(sourceItem, 'id'),
					[bindingTargetKey]: linkedItemsGridSelection[0],
				}).then(() => invalidate()),
		},
		actions: {
			notifyCreateAssociationSuccess: async () =>
				message.success(`Associazione creata con successo`),
			notifyCreateAssociationError: async () => message.error(`Errore nel salvataggio`),
			notifyUpdateAssociationSuccess: async () =>
				message.success(`Associazione modificata con successo`),
			notifyUpdateAssociationError: async () => message.error(`Errore nel salvataggio`),
			notifyDetachSuccess: async () => message.success(`Associazione rimossa con successo`),
			notifyDetachError: async () => message.error(`Errore nel salvataggio`),
		},
	})

	const currentColumns = getCurrentColumns(
		featureType,
		'*',
		currentColumnGroupIdx,
		plantTypeFilter
	)

	const {user} = useAuth()
	if (!user) return null
	const {codistat, center} = user
	return (
		<div
			style={
				{height: '100%', display: 'flex', flex: 1, position: 'relative'} // THIS SEEMS REQUIRED
			}
		>
			<SplitPane split="vertical" defaultSize="50%">
				<SplitPaneContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						// backgroundColor: 'white',
					}}
				>
					<Row>
						<Col span={24} style={{padding: 10, backgroundColor: 'white'}}>
							{
								{
									impianti: (
										<>
											Edificio:{' '}
											{sourceItem ? (
												<strong>
													{_.get(sourceItem, 'properties.edif_descr')} -{' '}
													{_.get(sourceItem, 'properties.indirizzo')}
												</strong>
											) : null}
										</>
									),
									edifici: (
										<>
											Impianto:{' '}
											{sourceItem ? (
												<strong>
													{_.get(sourceItem, 'properties.pod_pdr_cont')} -{' '}
													{_.get(sourceItem, 'properties.indir')}
												</strong>
											) : null}
										</>
									),
								}[featureType]
							}
						</Col>
					</Row>
					<Row style={{flex: 1}}>
						<Col span={24} style={{height: '100%', position: 'relative'}}>
							<Map
								key={codistat + '_' + featureType}
								codistat={codistat}
								center={center}
								mainFeatureType={featureType}
								keyProp={keyProp}
								externalSelection={linkedItemsGridSelection}
								highlightedSelection={unlinkedItemsGridSelection}
								onSelectionChange={mapSelectionSet}
								currentMode={'view'}
								// ref={mapRef}
							/>
						</Col>
					</Row>
				</SplitPaneContent>
				<SplitPaneContent
					style={{
						height: '100%',
						backgroundColor: 'white',
						display: 'flex',
						flexDirection: 'column',
						paddingLeft: 10,
						paddingRight: 10,
					}}
				>
					<>
						<LinkedItemsTitle featureType={featureType} />
						<ColumnGroupTabs
							style={{height: 30}}
							columnGroups={columnGroups}
							onChange={setCurrentColumnGroupIdx}
							activeIndex={currentColumnGroupIdx}
						/>
						<Grid // fit
							style={{
								flex: 1,
								position: 'relative',
								border: styles.BORDERS,
							}}
							columns={[
								...currentColumns,
								{
									name: 'Quota',
									key: 'quota',
									width: 60,
									resizable: false,
									formatter: ({value}) => `${value * 100}%`,
								},
							]}
							data={linkedItems}
							minColumnWidth={120}
							defaultSortOn={defaultSortOn}
							rowKey={keyProp}
							onSelectionChange={linkedItemsGridSelectionSet}
							selection={linkedItemsGridSelection}
						/>
						<div
							style={{
								paddingTop: 10,
								paddingBottom: 10,
							}}
						>
							<Button
								disabled={linkedItemsGridSelection.length !== 1}
								onClick={e => {
									send('MODIFY_ASSOCIATION', {
										data: associations[linkedItemsGridSelection[0]],
									})
								}}
							>
								Modifica associazione
							</Button>
							<Modal
								footer={
									<div style={{display: 'flex', justifyContent: 'flex-end'}}>
										<Spacer>
											<Button onClick={() => send('CANCEL')}>Annulla</Button>
											<Button onClick={() => send('SAVE')}>Conferma</Button>
											<Button
												onClick={() => send('DETACH')}
												disabled={[
													'idle',
													'creatingAssociation',
													'inserting',
												].some(state.matches)}
											>
												Disassocia
											</Button>
										</Spacer>
									</div>
								}
								visible={[
									'creatingAssociation',
									'modifyingAssociation',
									'inserting',
									'updating',
									'detaching',
								].some(state.matches)}
								title="Modifica associazione"
								onCancel={() => send('CANCEL')}
							>
								<form
									onSubmit={e => {
										e.preventDefault()
										send('SAVE')
									}}
								>
									Utilizzato in questo edificio al{' '}
									<InputNumber
										max={100}
										value={state.context.editedQuota * 100}
										onChange={value => send('TYPING', {data: value / 100})}
									/>{' '}
									%
									<button hidden />
								</form>
							</Modal>
						</div>

						<Title>
							{featureType === 'edifici' ? 'Edifici' : 'Impianti'} non associati
						</Title>
						<Box paddingBottom={10} display="flex" alignItems="center">
							{featureType === 'impianti' && (
								<Select
									style={{width: 200, marginRight: 10}}
									value={plantTypeFilter}
									onChange={plantTypeFilterSet}
								>
									<Select.Option value="*">Tutti</Select.Option>
									<Select.Option value="automez_">Automezzi</Select.Option>
									<Select.Option value="ed_ele_">Utenze elettriche</Select.Option>
									<Select.Option value="ed_term_">Utenze termiche</Select.Option>
									<Select.Option value="fotov_">
										Impianti fotovoltaici
									</Select.Option>
								</Select>
							)}
							<Input
								placeholder="Filtro"
								onChange={e => textualFilterSet(e.target.value)}
								value={textualFilter}
								allowClear
							/>
						</Box>
						<Grid // fit
							style={{
								flex: 2,
								position: 'relative',
								border: styles.BORDERS,
							}}
							columns={currentColumns}
							data={filteredUnlinkedItems}
							minColumnWidth={120}
							defaultSortOn={defaultSortOn}
							rowKey={keyProp}
							onSelectionChange={unlinkedItemsGridSelectionSet}
							selection={unlinkedItemsGridSelection}
						/>
						<div style={{paddingTop: 10, paddingBottom: 10}}>
							<Button
								disabled={unlinkedItemsGridSelection.length !== 1}
								onClick={e => send('CREATE_ASSOCIATION')}
							>
								Associa
							</Button>
							<Button
								onClick={() =>
									history.push(
										featureType === 'impianti'
											? '/edifici-e-impianti/edifici'
											: '/edifici-e-impianti/impianti'
									)
								}
								style={{marginLeft: 10}}
							>
								Torna a {featureType === 'impianti' ? 'Edifici' : 'Impianti'}
							</Button>
						</div>
					</>
				</SplitPaneContent>
			</SplitPane>
		</div>
	)
}

export const ImpiantiEdificio = ({match}) => (
	<DataProvider>
		{({data, loading, invalidate}) => (
			<PageContextProvider
				loading={loading}
				invalidate={invalidate}
				sourceFeatureId={match.params.id}
				data={data}
				targetFeatureType="impianti"
				keyProp={'id_imp'}
			>
				<UI featureType="impianti" keyProp="id_imp" />
			</PageContextProvider>
		)}
	</DataProvider>
)

export const EdificiImpianto = ({match}) => (
	<DataProvider>
		{({data, loading, invalidate}) => (
			<PageContextProvider
				loading={loading}
				invalidate={invalidate}
				sourceFeatureId={match.params.id}
				data={data}
				targetFeatureType="edifici"
				keyProp={'idf'}
			>
				<UI featureType="edifici" keyProp="idf" />
			</PageContextProvider>
		)}
	</DataProvider>
)
