import * as pl from './pl';
import * as linee from './linee';
import * as quadri from './quadri';
import * as centraline from './centraline';
import * as edifici from './edifici';
import * as impianti from './impianti';

export default {
	pl,
	linee,
	quadri,
	centraline,
	edifici,
	impianti,
};
