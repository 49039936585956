import {Style, Stroke} from 'ol/style'

const colorsMap = {
	// 0: '#000000',
	0: '#ff0000',
	1: '#b3048d',
	2: '#3e9919',
	4: '#aa0000',
	5: '#ff8800',
	6: '#d1d100',
	7: '#870566',
	8: '#428a1b',
	9: '#0000ff',
}

export default feature => {
	const props = feature.getProperties()
	return new Style({
		stroke: new Stroke({
			color: colorsMap[props.modulo],
			width: 1.2,
		}),
	})
}
