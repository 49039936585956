import React from 'react'
import {Wrapper} from './common'
import styled from 'styled-components'
import {Divider} from 'antd'

const CustomWrapper = styled(Wrapper)`
	display: flex;

	// p {
	// 	margin-bottom: 0.35rem;
	// }

	.left {
		flex: 3;
		margin-right: 40px;
		padding-bottom: 10px;
	}

	// .left h2 {
	// 	margin-top: 20px;
	// 	margin-bottom: 20px;
	// }

	.left .loghi-partner img {
		margin-right: 20px;
		margin-bottom: 20px;
		max-height: 60px;
	}

	.left .logo-slovenija {
		width: 250px;
		margin-top: 50px;
	}

	.left hr {
		margin-top: 0;
	}

	.right {
		flex: 1;
		min-height: 550px;
		position: relative;
	}

	.right .logo {
		position: absolute;
		right: 10px;
		max-width: 280px;
		width: 100%;
	}

	.right .logo-futurelights {
		top: 10px;
	}

	.right .logo-interreg {
		top: 70px;
		width: 200px;
	}

	.right .loghi-partner {
		position: absolute;
		bottom: 10px;
		text-align: left;
	}
`

export default () => (
	<CustomWrapper>
		<div className="left">
			<h1>Crediti</h1>

			<p>
				Il Catasto Energetico Online è una piattaforma software ideata e sviluppata dall’Agenzia per
				l’energia del Friuli Venezia Giulia per sostenere una gestione energetica consapevole del
				patrimonio pubblico comunale.{' '}
			</p>

			<p>Supervisione tecnica:</p>
			<p>
				Matteo Mazzolini, <a href="http://www.ape.fvg.it/?sID=2316">www.ape.fvg.it</a>
			</p>

			<p>Progettazione software:</p>
			<p>
				Roberto Bonanni, <a href="http://www.ape.fvg.it">www.ape.fvg.it</a>
			</p>

			<p>Programmazione web:</p>
			<p>
				Mattia Carel Borini, <a href="mailto:mattia.borini@gmail.com">mattia.borini(@)gmail.com</a>
			</p>

			<p>
				La realizzazione del Catasto Energetico Online è in parte avvenuta all'interno dei seguenti
				progetti cofinanziati dal fondo europeo per lo sviluppo regionale:
			</p>

			<ul>
				<li>
					Progetto FUTURELIGHTS - Interreg IV Italia-Slovenia -{' '}
					<a href="http://www.futurelights.eu" target="_blank" rel="noopener noreferrer">
						www.futurelights.eu
					</a>
				</li>
				<h2>Partner</h2>
				<div className="loghi-partner">
					<img
						src="/img/logo-ape-new.svg"
						alt="Agenzia Per l'Energia del Friuli Venezia-Giulia"
						style={{maxHeight: 59}}
					/>
					<img src="/img/istituzionali/partner/opt/sempeter.png" alt="Obcina Sempeter - Vrtojba" />

					<img src="/img/istituzionali/partner/opt/doberdo.png" alt="Comune di Doberdò del Lago" />
					<img
						src="/img/istituzionali/partner/opt/portomaggiore.png"
						alt="Comune di Portomaggiore"
					/>
					<img src="/img/istituzionali/partner/opt/tolmin.gif" alt="Comune di Tolmin" />
					<img src="/img/istituzionali/partner/opt/gorizia.gif" alt="Provincia di Gorizia" />
					<img src="/img/istituzionali/partner/opt/temno.gif" alt="Temno nebo - Slovenije" />
				</div>

				<Divider />

				<div className="loghi-partner">
					<img src="/img/istituzionali/partner/opt/slovenija.png" alt="Republika Slovenija" />
					<img
						src="/img/istituzionali/partner/opt/ministero.png"
						alt="Ministero dell'Economia e delle Finanze"
					/>
				</div>

				<li>
					Progetto COME - Interreg IV Italia-Austria -{' '}
					<a href="http://www.comunita-energia.it" target="_blank" rel="noopener noreferrer">
						www.comunita-energia.it
					</a>
				</li>

				<img src="img/loghi-austria.png" alt="Partner Austria" />
			</ul>

			<p>Tutti diritti sono riservati ad APE FVG.</p>
		</div>
		<div className="right">
			<img
				src="img/istituzionali/Logo-Futurelights-Osnovni.jpg"
				alt="Logo Futurelights Osnovni"
				className="logo logo-futurelights"
			/>

			<img
				src="img/istituzionali/logo_ITA_SLO.jpg"
				alt="Logo Interreg"
				className="logo logo-interreg"
			/>
		</div>
	</CustomWrapper>
)
