import React from 'react';
import { CellEditor, CellRenderer } from './cell-renderers';

export const primaryKey = 'id_imp';
export const defaultSortOn = 'uten';

export const TIPOLOGIE = [
	{
		label: 'Automezzo',
		value: 'automez_',
	},
	{
		label: 'Centralina',
		value: 'centr_',
	},
	{
		label: 'Utenza Elettrica',
		value: 'ed_ele_',
	},
	{
		label: 'Utenza Termica',
		value: 'ed_term_',
	},
	{
		label: 'Centrale termica fonti rinnovabili',
		value: 'ct_fer_',
	},
	{
		label: 'Centrale termica tradizionale',
		value: 'ct_tradiz_',
	},
	{
		label: 'Cogeneratore',
		value: 'cogen_',
	},
	{
		label: 'Impianto eolico',
		value: 'eol_',
	},
	{
		label: 'Impianto fotovoltaico',
		value: 'fotov_',
	},
	{
		label: 'Impianto geotermico',
		value: 'geot_',
	},
	{
		label: 'Impianto eolico',
		value: 'idro_',
	},
	{
		label: 'Pompa di calore',
		value: 'pomcal_',
	},
	{
		label: 'Refrigeratore ad assorbimento',
		value: 'refr_ass_',
	},
	{
		label: 'Refrigeratore tradizionale',
		value: 'refr_tradiz_',
	},
	{
		label: 'Impianto solate termico',
		value: 'solterm_',
	},
	{
		label: 'Impianto teleriscaldamento',
		value: 'telerisc_',
	},
	{
		label: 'Rete di teleriscaldamento',
		value: 'rete_tele_',
	},
	{
		label: 'Varie',
		value: 'varie_',
	},
	{
		label: '(imp_)',
		value: 'imp_',
	},
	{
		label: '(w)',
		value: 'w',
	},
];

export const PLANT_TYPES_LOOKUP = TIPOLOGIE.reduce(
	(acc, {value, label}) => ({...acc, [value]: label}),
	{}
)

export const APPARTENENZE = [
	{
		label: 'Regionale Amministrativa',
		value: 'regionale amm',
	},
	{
		label: 'Provinciale Amministrativa',
		value: 'provinciale amm',
	},
	{
		label: 'Comunale Amministrativa',
		value: 'comunale amm',
	},
	{
		label: 'Amministrazione Comunale',
		value: 'amm_comunale',
	},
	{
		label: 'Terziario',
		value: 'terziario',
	},
	{
		label: 'Privato',
		value: 'privato',
	},
	{
		label: 'Altro',
		value: 'altro',
	},
	{
		label: '<non specificato>',
		value: null,
	},
];

export const columnGroups = [
	{
		label: 'Impianto',
		name: 'impianto',
		keys: ['imp_tipo', 'uten', 'indir', 'pod_pdr_cont', 'presa_al'],
	},
	{
		label: 'Dati produttivi',
		name: 'dati_produttivi',
		keys: ['imp_tipo', 'cons_prod', 'vett', 'um', 'c_anno', 'p_anno'],
	},
	{
		label: 'Dati tecnici',
		name: 'dati_tecnici',
		keys: ['imp_tipo', 'p_ele', 'p_term', 'p_ut', 'p_frig', 'n1_inom', 'num2', 'n3_kva'],
	},
	{
		label: 'Descrizione',
		name: 'descrizione',
		keys: ['imp_tipo', 'd1_flu', 'd2_pom', 'd3_crep', 'd4_reg', 'd5_mo', 'desc6'],
	},
	{
		label: 'Altro e note',
		name: 'altro_e_note',
		keys: ['imp_tipo', 'anno', 'appart', 'loc', 'note'],
	},
];

const plantTypeFormatter = ({value}) => PLANT_TYPES_LOOKUP[value]

export const columns = {
	'*': [
		{
			name: 'Impianto Tipo',
			key: 'imp_tipo',
			formatter: plantTypeFormatter,
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Appartenenza',
			key: 'appart',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'UM',
			key: 'um',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Consumo/Produzione',
			key: 'cons_prod',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Utenza',
			key: 'uten',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Indirizzo',
			key: 'indir',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Località',
			key: 'loc',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'POD/PDR/Contatore',
			key: 'pod_pdr_cont',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ presa_al',
			key: 'presa_al',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'd4_reg',
			key: 'd4_reg',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Materia Prima',
			key: 'vett',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ p_frig',
			key: 'p_frig',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ p_ele',
			key: 'p_ele',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ p_term',
			key: 'p_term',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Consumo',
			key: 'c_anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ n1_inom',
			key: 'n1_inom',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ num2',
			key: 'num2',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ n3_kva',
			key: 'n3_kva',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ desc6',
			key: 'desc6',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ d1_flu',
			key: 'd1_flu',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Produzione',
			key: 'p_anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ d2_pom',
			key: 'd2_pom',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ d3_crep',
			key: 'd3_crep',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: '~ p_ut',
			key: 'p_ut',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
		},
		{
			name: '~ d5_mo',
			key: 'd5_mo',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Anno',
			key: 'anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Note',
			key: 'note',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
	],
	insert: [
		{
			name: 'Impianto Tipo',
			key: 'imp_tipo',
			formatter: plantTypeFormatter,
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Appartenenza',
			key: 'appart',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Consumo/Produzione',
			key: 'cons_prod',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Utenza',
			key: 'uten',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Indirizzo',
			key: 'indir',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editable: true,
		},
		{
			name: 'POD/PDR/Contatore',
			key: 'pod_pdr_cont',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editable: true,
		},
		{
			name: '~ presa_al',
			key: 'presa_al',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editable: true,
		},
		{
			name: 'd4_reg',
			key: 'd4_reg',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editable: true,
		},
	],
	'complete-inserts': [
		{
			name: 'Impianto Tipo',
			key: 'imp_tipo',
			formatter: plantTypeFormatter,
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Appartenenza',
			key: 'appart',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'UM',
			key: 'um',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Consumo/Produzione',
			key: 'cons_prod',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl')
		},
		{
			name: 'Utenza',
			key: 'uten',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			width: 100,
		},
		{
			name: 'Indirizzo',
			key: 'indir',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'Località',
			key: 'loc',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'POD/PDR/Contatore',
			key: 'pod_pdr_cont',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editable: true,
		},
		{
			name: '~ presa_al',
			key: 'presa_al',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'd4_reg',
			key: 'd4_reg',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'Materia Prima',
			key: 'vett',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: '~ p_frig',
			key: 'p_frig',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ p_ele',
			key: 'p_ele',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ p_term',
			key: 'p_term',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: 'Consumo',
			key: 'c_anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ n1_inom',
			key: 'n1_inom',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ num2',
			key: 'num2',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ desc6',
			key: 'desc6',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: '~ d1_flu',
			key: 'd1_flu',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'Produzione',
			key: 'p_anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ d2_pom',
			key: 'd2_pom',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: '~ d3_crep',
			key: 'd3_crep',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: '~ p_ut',
			key: 'p_ut',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Number,
			editable: true,
		},
		{
			name: '~ d5_mo',
			key: 'd5_mo',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'Anno',
			key: 'anno',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Hinted,
			editable: true,
		},
		{
			name: 'Note',
			key: 'note',
			// headerCellTemplate: $templateCache.get('impianti-dynamic-header-cell.tpl'),
			editableCellTemplate: CellEditor.Text,
			editable: true,
		},
	],
};
// 	mappaEtichetteColonne: {
// 		"geot_": {
// 			"presa_al": "",
// 			"n1_inom": "Nr Sonde",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "POD/PDR",
// 			"d5_mo": "Fluido Operativo",
// 			"n3_kva": "Profondità Sonde",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Elettrica Assorbita",
// 			"p_term": "kW Pot. Termica Generatore",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Pompa Marca/Modello",
// 			"p_frig": "kW Pot. Frigorifera Generatore",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Pompa Anno",
// 			"d2_pom": "Pompa Tipo",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Sonda Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"**": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "POD/PDR/Contatore",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "",
// 			"p_term": "",
// 			"uten": "Utenza",
// 			"d4_reg": "",
// 			"p_frig": "",
// 			"vett": "Materia Prima",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo",
// 			"p_anno": "Produzione"
// 		},
// 		"ct_tradiz_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "PDR",
// 			"d5_mo": "Modello",
// 			"n3_kva": "Potenza Minima",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "kW Potenza Utile",
// 			"p_ele": "",
// 			"p_term": "kW Pot. Focolare",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca",
// 			"p_frig": "",
// 			"vett": "Gas/Olio",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "",
// 			"d3_crep": "Vaso Espansione Tipo",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Fluido Operativo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": ""
// 		},
// 		"refr_ass_": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "POD/PDR",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Elettrica Assorbita",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca/Modello",
// 			"p_frig": "kW Pot. Frigorifera Generata",
// 			"vett": "Combustibile",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Refrigeratore Anno",
// 			"d2_pom": "Fluido Refrigerante",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Refrigeratore Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": "Pot. Frigorifera Anno"
// 		},
// 		"ed_term_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "PDR",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "",
// 			"p_term": "kW Pot. Disponibile",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "",
// 			"p_frig": "",
// 			"vett": "Gas/GPL/Gasolio",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": ""
// 		},
// 		"fotov_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "Nr Moduli",
// 			"num2": "Nr Inverter",
// 			"pod_pdr_cont": "POD",
// 			"d5_mo": "Tipo",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Nominale Totale",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca/Tipo Moduli",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "",
// 			"d2_pom": "Dimensione Moduli",
// 			"d3_crep": "Inverter Descrizione",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Tetto/Terra",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"refr_tradiz_": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "POD/PDR",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Elettrica Assorbita",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca/Modello",
// 			"p_frig": "kW Pot. Frigorifera Generata",
// 			"vett": "Combustibile",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Refrigeratore Anno",
// 			"d2_pom": "Fluido Refrigerante",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Refrigeratore Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": "Pot. Frigorifera Anno"
// 		},
// 		"rete_tele_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "ID Contacalorie",
// 			"d5_mo": "Scambiatore Modello",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "Flusso Anno",
// 			"p_ele": "",
// 			"p_term": "kW Pot. Scambiatore",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca Scambiatore",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "Fluido Primario",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Descrizione",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"pomcal_": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "POD/PDR",
// 			"d5_mo": "Fluido Refrigerante",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Elettrica Nominale",
// 			"p_term": "kW Pot. Termica Nominale",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca/Modello",
// 			"p_frig": "kW Pot. Frigorifera Nominale",
// 			"vett": "Sorgente",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Pompa Anno",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Pompa Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"ct_fer_": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "Contatore",
// 			"d5_mo": "Modello",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "kW Potenza Utile",
// 			"p_ele": "",
// 			"p_term": "kW Pot. Focolare",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca",
// 			"p_frig": "",
// 			"vett": "Cippato/Pellet",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "",
// 			"d3_crep": "Vaso Espansione Tipo",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Fluido Operativo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": ""
// 		},
// 		"automez_": {
// 			"presa_al": "Automezzo Tipo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "Targa",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Marca/Modello",
// 			"loc": "Descrizione Uso",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "",
// 			"p_term": "Potenza kW",
// 			"uten": "Combustibile",
// 			"d4_reg": "",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": "Km Anno"
// 		},
// 		"solterm_": {
// 			"presa_al": "",
// 			"n1_inom": "Nr Moduli Captanti",
// 			"num2": "Accumulo Litri",
// 			"pod_pdr_cont": "",
// 			"d5_mo": "Moduli Modello",
// 			"n3_kva": "mq Superficie Tot.",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca Moduli",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "Fluido Operativo",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Tecnologia",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"cogen_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "PDR",
// 			"d5_mo": "Modello",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Elettrica",
// 			"p_term": "kW Pot. Termica Generatore",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca",
// 			"p_frig": "",
// 			"vett": "Combustibile",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno Cogeneratore",
// 			"d2_pom": "",
// 			"d3_crep": "Cogeneratore Tipo",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"ed_ele_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "POD",
// 			"d5_mo": "",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Disponibile",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "",
// 			"p_frig": "",
// 			"vett": "Bassa/Media Tensione",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "Consumo Anno",
// 			"p_anno": ""
// 		},
// 		"idro_": {
// 			"presa_al": "",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "PDR",
// 			"d5_mo": "Inverter Tipo",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Nominale",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca/Modello",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Turbina Anno",
// 			"d2_pom": "",
// 			"d3_crep": "",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Turbina Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"telerisc_": {
// 			"presa_al": "Altro Identificativo",
// 			"n1_inom": "",
// 			"num2": "",
// 			"pod_pdr_cont": "ID Contacalorie",
// 			"d5_mo": "Scambiatore Modello",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "Flusso Anno",
// 			"p_ele": "",
// 			"p_term": "kW Pot. Scambiatore",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca Scambiatore",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno",
// 			"d2_pom": "Descrizione",
// 			"d3_crep": "Fluido Primario",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		},
// 		"eol_": {
// 			"presa_al": "",
// 			"n1_inom": "Nr Inverter",
// 			"num2": "",
// 			"pod_pdr_cont": "Contatore",
// 			"d5_mo": "Modello",
// 			"n3_kva": "",
// 			"indir": "Indirizzo",
// 			"loc": "Località",
// 			"note": "Note",
// 			"p_ut": "",
// 			"p_ele": "kW Pot. Nominale",
// 			"p_term": "",
// 			"uten": "Descrizione Utenza",
// 			"d4_reg": "Marca",
// 			"p_frig": "",
// 			"vett": "",
// 			"cons_prod": "Consumo/Produzione",
// 			"anno": "Anno Generatore",
// 			"d2_pom": "",
// 			"d3_crep": "Inverter Tipo",
// 			"desc6": "",
// 			"appart": "Appartenenza",
// 			"um": "UM",
// 			"d1_flu": "Generatore Tipo",
// 			"imp_tipo": "Impianto Tipo",
// 			"c_anno": "",
// 			"p_anno": "Produzione Anno"
// 		}
// 	},
// },

export const TYPE_SPECIFIC_COLUMN_LABELS = {
  geot_: {
    presa_al: "",
    n1_inom: "Nr Sonde",
    num2: "Accumulo Litri",
    pod_pdr_cont: "POD/PDR",
    d5_mo: "Fluido Operativo",
    n3_kva: "Profondità Sonde",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Elettrica Assorbita",
    p_term: "kW Pot. Termica Generatore",
    uten: "Descrizione Utenza",
    d4_reg: "Pompa Marca/Modello",
    p_frig: "kW Pot. Frigorifera Generatore",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Pompa Anno",
    d2_pom: "Pompa Tipo",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Sonda Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  "**": {
    presa_al: "",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "POD/PDR/Contatore",
    d5_mo: "",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "",
    p_term: "",
    uten: "Utenza",
    d4_reg: "",
    p_frig: "",
    vett: "Materia Prima",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo",
    p_anno: "Produzione",
  },
  ct_tradiz_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "PDR",
    d5_mo: "Modello",
    n3_kva: "Potenza Minima",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "kW Potenza Utile",
    p_ele: "",
    p_term: "kW Pot. Focolare",
    uten: "Descrizione Utenza",
    d4_reg: "Marca",
    p_frig: "",
    vett: "Gas/Olio",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "",
    d3_crep: "Vaso Espansione Tipo",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Fluido Operativo",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "",
  },
  refr_ass_: {
    presa_al: "",
    n1_inom: "",
    num2: "Accumulo Litri",
    pod_pdr_cont: "POD/PDR",
    d5_mo: "",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Elettrica Assorbita",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca/Modello",
    p_frig: "kW Pot. Frigorifera Generata",
    vett: "Combustibile",
    cons_prod: "Consumo/Produzione",
    anno: "Refrigeratore Anno",
    d2_pom: "Fluido Refrigerante",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Refrigeratore Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "Pot. Frigorifera Anno",
  },
  ed_term_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "PDR",
    d5_mo: "",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "",
    p_term: "kW Pot. Disponibile",
    uten: "Descrizione Utenza",
    d4_reg: "",
    p_frig: "",
    vett: "Gas/GPL/Gasolio",
    cons_prod: "Consumo/Produzione",
    anno: "",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "",
  },
  fotov_: {
    presa_al: "Altro Identificativo",
    n1_inom: "Nr Moduli",
    num2: "Nr Inverter",
    pod_pdr_cont: "POD",
    d5_mo: "Tipo",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Nominale Totale",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca/Tipo Moduli",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "",
    d2_pom: "Dimensione Moduli",
    d3_crep: "Inverter Descrizione",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Tetto/Terra",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  refr_tradiz_: {
    presa_al: "",
    n1_inom: "",
    num2: "Accumulo Litri",
    pod_pdr_cont: "POD/PDR",
    d5_mo: "",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Elettrica Assorbita",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca/Modello",
    p_frig: "kW Pot. Frigorifera Generata",
    vett: "Combustibile",
    cons_prod: "Consumo/Produzione",
    anno: "Refrigeratore Anno",
    d2_pom: "Fluido Refrigerante",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Refrigeratore Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "Pot. Frigorifera Anno",
  },
  rete_tele_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "ID Contacalorie",
    d5_mo: "Scambiatore Modello",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "Flusso Anno",
    p_ele: "",
    p_term: "kW Pot. Scambiatore",
    uten: "Descrizione Utenza",
    d4_reg: "Marca Scambiatore",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "Fluido Primario",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Descrizione",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  pomcal_: {
    presa_al: "",
    n1_inom: "",
    num2: "Accumulo Litri",
    pod_pdr_cont: "POD/PDR",
    d5_mo: "Fluido Refrigerante",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Elettrica Nominale",
    p_term: "kW Pot. Termica Nominale",
    uten: "Descrizione Utenza",
    d4_reg: "Marca/Modello",
    p_frig: "kW Pot. Frigorifera Nominale",
    vett: "Sorgente",
    cons_prod: "Consumo/Produzione",
    anno: "Pompa Anno",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Pompa Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "Produzione Anno",
  },
  ct_fer_: {
    presa_al: "",
    n1_inom: "",
    num2: "Accumulo Litri",
    pod_pdr_cont: "Contatore",
    d5_mo: "Modello",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "kW Potenza Utile",
    p_ele: "",
    p_term: "kW Pot. Focolare",
    uten: "Descrizione Utenza",
    d4_reg: "Marca",
    p_frig: "",
    vett: "Cippato/Pellet",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "",
    d3_crep: "Vaso Espansione Tipo",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Fluido Operativo",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "",
  },
  automez_: {
    presa_al: "Automezzo Tipo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "Targa",
    d5_mo: "",
    n3_kva: "",
    indir: "Marca/Modello",
    loc: "Descrizione Uso",
    note: "Note",
    p_ut: "",
    p_ele: "",
    p_term: "Potenza kW",
    uten: "Combustibile",
    d4_reg: "",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "Km Anno",
  },
  solterm_: {
    presa_al: "",
    n1_inom: "Nr Moduli Captanti",
    num2: "Accumulo Litri",
    pod_pdr_cont: "",
    d5_mo: "Moduli Modello",
    n3_kva: "mq Superficie Tot.",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca Moduli",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "Fluido Operativo",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Tecnologia",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  cogen_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "PDR",
    d5_mo: "Modello",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Elettrica",
    p_term: "kW Pot. Termica Generatore",
    uten: "Descrizione Utenza",
    d4_reg: "Marca",
    p_frig: "",
    vett: "Combustibile",
    cons_prod: "Consumo/Produzione",
    anno: "Anno Cogeneratore",
    d2_pom: "",
    d3_crep: "Cogeneratore Tipo",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "Produzione Anno",
  },
  ed_ele_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "POD",
    d5_mo: "",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Disponibile",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "",
    p_frig: "",
    vett: "Bassa/Media Tensione",
    cons_prod: "Consumo/Produzione",
    anno: "",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "Consumo Anno",
    p_anno: "",
  },
  idro_: {
    presa_al: "",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "PDR",
    d5_mo: "Inverter Tipo",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Nominale",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca/Modello",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Turbina Anno",
    d2_pom: "",
    d3_crep: "",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Turbina Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  telerisc_: {
    presa_al: "Altro Identificativo",
    n1_inom: "",
    num2: "",
    pod_pdr_cont: "ID Contacalorie",
    d5_mo: "Scambiatore Modello",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "Flusso Anno",
    p_ele: "",
    p_term: "kW Pot. Scambiatore",
    uten: "Descrizione Utenza",
    d4_reg: "Marca Scambiatore",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Anno",
    d2_pom: "Descrizione",
    d3_crep: "Fluido Primario",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
  eol_: {
    presa_al: "",
    n1_inom: "Nr Inverter",
    num2: "",
    pod_pdr_cont: "Contatore",
    d5_mo: "Modello",
    n3_kva: "",
    indir: "Indirizzo",
    loc: "Località",
    note: "Note",
    p_ut: "",
    p_ele: "kW Pot. Nominale",
    p_term: "",
    uten: "Descrizione Utenza",
    d4_reg: "Marca",
    p_frig: "",
    vett: "",
    cons_prod: "Consumo/Produzione",
    anno: "Anno Generatore",
    d2_pom: "",
    d3_crep: "Inverter Tipo",
    desc6: "",
    appart: "Appartenenza",
    um: "UM",
    d1_flu: "Generatore Tipo",
    imp_tipo: "Impianto Tipo",
    c_anno: "",
    p_anno: "Produzione Anno",
  },
};