import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom'
import 'moment/locale/it'
import {Layout, Spin} from 'antd'
import styled from 'styled-components'

import './App.scss'
import Dashboard from './components/Dashboard'
import Sidebar from './components/Sidebar'
import {Logout, useAuth} from './lib/auth'
import AnalisiConsumi from './pages/analisi-consumi'
import ConfermaManutenzioni from './pages/conferma-manutenzioni'
import Infrastruttura from './pages/infrastruttura'
import InserimentoBollette from './pages/inserimento-bollette'
import Interrogazioni from './pages/interrogazioni'
import Manutenzioni from './pages/manutenzioni'
import Breadcrumb from './components/Breadcrumb'
import {SIDEBAR_WIDTH, HEADER_HEIGHT} from './styles'
import InstitutionalPages from './pages/institutional'
import Box from 'ui-box'
import {NewVersionNotice} from './components/NewVersionNotice'
import {EdificiImpianto, ImpiantiEdificio} from './pages/buildings-plants-link'
import ContabilitaProduzioneEnergetica from './pages/energy-management/production'

const {Header, Content} = Layout

const TopBarLinks = styled.div`
	display: inline-block;

	> a {
		color: black;
		display: inline-block;
		height: 100%;
		padding: 0 15px;
	}
	> a:not(last-child):hover {
		background-color: #bebebe;
	}
`

const TopBar = () => {
	const {user} = useAuth()
	return (
		<Header
			style={{
				height: HEADER_HEIGHT,
				lineHeight: HEADER_HEIGHT + 'px',
				backgroundColor: '#E8E8E8',
				paddingRight: 0,
			}}
		>
			<div>
				<span
					style={{
						fontWeight: 'bold',
						fontSize: '120%',
						display: 'inline-block',
						minWidth: SIDEBAR_WIDTH - 15,
						paddingRight: 20,
					}}
				>
					Comune di {user.comune}
				</span>
				<Breadcrumb />
			</div>
			<TopBarLinks>
				<Link to="/aggiornamenti">Aggiornamenti</Link>
				<Link to="/guida-rapida">Guida Rapida</Link>
				<Link to="/contatti">Contatti</Link>
				<Link to="/credits">Credits</Link>
				<Link to="/logout" style={{backgroundColor: '#FCEE21'}}>
					Logout
				</Link>
			</TopBarLinks>
		</Header>
	)
}

const PrivateApp = () => {
	return (
		<>
			<Layout style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
				<TopBar />
				<Layout style={{display: 'flex', flexDirection: 'row'}}>
					<Sidebar />
					<Content>
						<Switch>
							<Route exact path="/dashboard" component={Dashboard} />
							<Route exact path="/struttura/*" component={Infrastruttura} />
							<Route
								exact
								path="/edifici-e-impianti/edifici/:id/impianti"
								component={ImpiantiEdificio}
							/>
							<Route
								exact
								path="/edifici-e-impianti/edifici/:id/illuminazione-interni"
								component={Infrastruttura}
							/>
							<Route
								exact
								path="/edifici-e-impianti/impianti/:id/edifici"
								component={EdificiImpianto}
							/>
							<Route exact path="/edifici-e-impianti/*" component={Infrastruttura} />
							<Route exact path="/manutenzioni/*" component={Manutenzioni} />
							<Route
								exact
								path="/verifica-manutenzioni"
								component={ConfermaManutenzioni}
							/>
							<Route exact path="/interrogazioni/*" component={Interrogazioni} />
							<Route
								exact
								path="/contabilita-energetica/inserimento-bollette"
								component={InserimentoBollette}
							/>
							<Route
								exact
								path="/contabilita-energetica/analisi-consumi"
								component={AnalisiConsumi}
							/>
							<Route
								exact
								path="/contabilita-energetica/produzione"
								component={ContabilitaProduzioneEnergetica}
							/>
							<Route
								exact
								path="/guida-rapida"
								component={InstitutionalPages.QuickGuide}
							/>
							<Route exact path="/contatti" component={InstitutionalPages.Contacts} />
							<Route
								exact
								path="/aggiornamenti"
								component={InstitutionalPages.Updates}
							/>
							<Route exact path="/credits" component={InstitutionalPages.Credits} />
							<Redirect to="/dashboard" />
						</Switch>
					</Content>
				</Layout>
			</Layout>
			<NewVersionNotice />
		</>
	)
}

const App = () => {
	const {error, bootstrapping} = useAuth()

	return bootstrapping ? (
		<Box height="100%" display="flex" justifyContent="space-around" alignItems="center">
			<Spin />
		</Box>
	) : error ? (
		error.response.status === 403 ? (
			<Router>
				<Switch>
					<Route
						exact
						path="/login"
						render={() => (
							<strong>
								{process.env.NODE_ENV === 'development'
									? `Questa pagina viene renderizzata dal server, quindi probabilmente è necessario caricare la porta del server`
									: `Si è verificato un problema nel routing delle applicazioni`}
							</strong>
						)}
					/>
					<Route component={Logout} />
				</Switch>
			</Router>
		) : (
			error.message
		)
	) : (
		<Router>
			<Switch>
				<Route exact path="/logout" component={Logout} />
				<Route component={PrivateApp} />
			</Switch>
		</Router>
	)
}

export default App
