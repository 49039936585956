import React from 'react';
import { Input } from 'antd';
import { Col, Row } from '../utils';
import { TipoImpiantoSelect, VettoreSelect } from './common';

const getLabelImpiantoAttribute = key => `~ ${key}`;
function nameAndLabel(name) {
	return { name, label: getLabelImpiantoAttribute(name) };
}

export default ({ formItem }) => ({
	impianto: (
		<>
			<Row>
				<Col>
					{formItem({
						name: 'imp_tipo',
						label: 'Tipo Impianto',
						required: true,
						render: input => <TipoImpiantoSelect {...input} />,
					})}
				</Col>
				<Col>{formItem({ ...nameAndLabel('uten') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('indir') })}</Col>
				<Col>{formItem({ ...nameAndLabel('pod_pdr_cont') })}</Col>
			</Row>
			<Row>
				<Col>
					{formItem({
						name: 'vettore_id',
						label: 'Vettore',
						required: true,
						render: input => <VettoreSelect {...input} />,
					})}
				</Col>
				<Col>
					{formItem({
						...nameAndLabel('presa_al'),
						required: true,
					})}
				</Col>
			</Row>
		</>
	),
	dati_produttivi: (
		<>
			<Row>
				<Col>{formItem({ ...nameAndLabel('cons_prod') })}</Col>
				<Col>{formItem({ ...nameAndLabel('vett') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('um') })}</Col>
				<Col>{formItem({ ...nameAndLabel('c_anno') })}</Col>
			</Row>
			<Row>
				<Col push={12}>{formItem({ ...nameAndLabel('p_anno') })}</Col>
			</Row>
		</>
	),
	dati_tecnici: (
		<>
			<Row>
				<Col>{formItem({ ...nameAndLabel('p_ele') })}</Col>
				<Col>{formItem({ ...nameAndLabel('p_term') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('p_ut') })}</Col>
				<Col>{formItem({ ...nameAndLabel('p_frig') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('n1_inom') })}</Col>
				<Col>{formItem({ ...nameAndLabel('num2') })}</Col>
			</Row>
			<Row>
				<Col push={12}>{formItem({ ...nameAndLabel('n3_kva') })}</Col>
			</Row>
		</>
	),
	descrizione: (
		<>
			<Row>
				<Col>{formItem({ ...nameAndLabel('d1_flu') })}</Col>
				<Col>{formItem({ ...nameAndLabel('d2_pom') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('d3_crep') })}</Col>
				<Col>{formItem({ ...nameAndLabel('d4_reg') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('d5_mo') })}</Col>
				<Col>{formItem({ ...nameAndLabel('desc6') })}</Col>
			</Row>
		</>
	),
	altro_e_note: (
		<>
			<Row>
				<Col>{formItem({ ...nameAndLabel('anno') })}</Col>
				<Col>{formItem({ ...nameAndLabel('appart') })}</Col>
			</Row>
			<Row>
				<Col>{formItem({ ...nameAndLabel('loc') })}</Col>
			</Row>
			<Row span={24}>
				<Col>
					{formItem({
						...nameAndLabel('note'),
						Component: Input.TextArea,
					})}
				</Col>
			</Row>
		</>
	),
});
