import React from 'react'
import {Col, Row, CentralinaSelect} from '../utils'

export default ({formItem}) => ({
	linea: (
		<>
			<Row>
				<Col>{formItem({name: 'linea_cod', label: 'Codice', required: true})}</Col>
				<Col>{formItem({name: 'posa_linea', label: 'Posa Linea', required: true})}</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'lunghezza', label: 'Lunghezza', type: 'number'})}</Col>
			</Row>
		</>
	),
	altri_dati: (
		<>
			<Row>
				<Col>{formItem({name: 'linea_cod', label: 'Codice', required: true})}</Col>
				<Col>
					{formItem({
						name: 'id_imp',
						label: 'Centralina',
						required: true,
						Component: CentralinaSelect,
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'mono_bi_trifase', label: 'Formazione'})}</Col>
				<Col>{formItem({name: 'sigla_cavo', label: 'Sigla cavo'})}</Col>
			</Row>
			<Row>
				<Col>
					{formItem({name: 'sez_fase_mmq', label: 'Sezione Fase mmq', type: 'number'})}
				</Col>
				<Col>
					{formItem({
						name: 'sez_compless_mmq',
						label: 'Sezione Complessiva mmq',
						type: 'number',
					})}
				</Col>
			</Row>
			<Row>
				<Col>{formItem({name: 'localita', label: 'Località'})}</Col>
				<Col>{formItem({name: 'toponimo', label: 'Indirizzo'})}</Col>
			</Row>
			<Row>
				<Col span={24}>{formItem({name: 'note', label: 'Note'})}</Col>
			</Row>
		</>
	),
})
