import React from 'react'
import { Wrapper } from "./common";

export default () => (
	<Wrapper>
		<h1>Guida rapida</h1>

		<h2>Dashboard</h2>

		<p>È una pagina di accesso rapido a tutte le altre aree del software.</p>

		<h2>Infrastruttura</h2>

		<p>
			Nell'area Infrastruttura è possibile gestire l'infrastruttura dell'illuminazione pubblica e i
			suoi elementi. <br />
			È possibile inserire, modificare o eliminare "punti luce", "linee di alimentazione",
			"centraline" e "sottoquadri". <br />
			L'interfaccia offre varie metodologie di filtraggio e selezione degli elementi, sia su mappa
			che su tabelle.
		</p>

		<h2>Manutenzioni</h2>

		<p>
			L'area Manutenzioni offre la possibilità di inserire gli interventi di manutenzione effettuati
			sull'infrastruttura, in modo da poterne conservare un resoconto di veloce fruizione. <br />
			Anche in questa sezione l'interazione con la mappa rappresenta un metodo agevole per
			effettuare una rapida selezione degli elementi di interesse.
		</p>

		<h2>Interrogazioni</h2>

		<p>
			Quest'area è suddivisa in 2 sotto-aree: Punti Luce e Manutenzioni. È qui possibile effettuare
			selezioni e filtraggi più accurati ed esportare i dati in formato Excel.
		</p>

		<h2>Bollettazione e Consumi</h2>

		<p>Questa parte del software consente di:</p>

		<ul>
			<li>inserire i dati delle bollette relative agli impianti</li>
			<li>
				visualizzare resoconti dinamici (con grafici) dei consumi e delle spese del Comune,
				aggiornati all'ultima bolletta
			</li>
		</ul>

		<p>
			Anche in quest'area è possibile esportare i dati in formato Excel, in modo da poterli poi
			rielaborare per creare dei report più specifici.
		</p>
	</Wrapper>
)
